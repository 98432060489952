import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const employerAnnouncementSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerAnnouncement,
);

export const employerAnnouncementUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerAnnouncementUpdate,
);

export const employerAnnouncementCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerAnnouncementCreate,
);

export const employerAnnouncementUploadImageSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerAnnouncementUploadImage,
);

export const employerAnnouncementImageSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerAnnouncementImage,
);
