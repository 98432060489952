import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CartProductListActionSuccess,
  CartProductRemoveAction,
  CartProductRemoveActionFail,
  CartProductRemoveActionRequest,
  CartProductRemoveActionReset,
  CartProductRemoveActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export interface RemoveCartProductData {
  product_id: string | number | null;
  variant_id?: string | number | null;
}

export const removeCartProduct =
  (
    key: string,
    removeCartProductData: RemoveCartProductData,
    autoApprove: boolean,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CartProductRemoveAction | CartProductListActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<CartProductRemoveActionRequest>({
      type: ActionTypes.CART_PRODUCT_REMOVE_REQUEST,
    });

    try {
      await axios.post(
        `${apiConfig.url}/product/cart/remove`,
        removeCartProductData,
      );

      const cartProducts = getState().cartProductList.cartProducts;
      const selectedCartProduct = cartProducts?.cart.find(
        cartProduct => cartProduct.key === key,
      );
      const totalBuy =
        selectedCartProduct?.product.type === 'Buy'
          ? Number(selectedCartProduct.product.price) *
            selectedCartProduct.product_quantity
          : 0;
      const totalRent =
        selectedCartProduct?.product.type === 'Rent'
          ? Number(selectedCartProduct.product.price) *
            selectedCartProduct.product_quantity
          : 0;

      if (cartProducts) {
        dispatch<CartProductRemoveActionSuccess>({
          type: ActionTypes.CART_PRODUCT_REMOVE_SUCCESS,
          productId: selectedCartProduct?.product.id,
          payload: {
            ...cartProducts,
            total_buy:
              Number(cartProducts.total_buy) - (autoApprove ? totalBuy : 0),
            total_rent:
              Number(cartProducts.total_rent) - (autoApprove ? totalRent : 0),
            cart: cartProducts.cart.filter(
              cartProduct => cartProduct.key !== key,
            ),
          },
        });
      }
    } catch (e) {
      dispatch<CartProductRemoveActionFail>({
        type: ActionTypes.CART_PRODUCT_REMOVE_FAIL,
        payload: 'Unable to remove a product',
        productId: removeCartProductData.product_id || undefined,
      });
    }
  };

export const removeCartProductReset = (): CartProductRemoveActionReset => ({
  type: ActionTypes.CART_PRODUCT_REMOVE_RESET,
});
