import { SVGProps } from 'react';

const GiftIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 3.14995C3.5 2.63405 3.983 2.09995 4.739 2.09995C5.4726 2.09995 6.3 2.70895 6.3 3.73305V4.19995H5.7078C5.1443 4.19995 4.5486 4.19225 4.0936 4.02495C3.8822 3.94655 3.745 3.84785 3.6589 3.73585C3.5784 3.63085 3.5 3.45795 3.5 3.14995ZM8.2922 4.19995H7.7V3.73305C7.7 2.70895 8.5267 2.09995 9.261 2.09995C10.017 2.09995 10.5 2.63405 10.5 3.14995C10.5 3.45795 10.4216 3.63015 10.3411 3.73585C10.255 3.84785 10.1178 3.94585 9.9064 4.02425C9.4514 4.19225 8.8564 4.19995 8.2922 4.19995ZM11.9 3.14995C11.9 3.54055 11.8279 3.89195 11.6858 4.19995H12.95C13.2285 4.19995 13.4955 4.31058 13.6925 4.50749C13.8894 4.7044 14 4.97147 14 5.24995V6.99995H0V5.24995C0 4.97147 0.110625 4.7044 0.307538 4.50749C0.504451 4.31058 0.771523 4.19995 1.05 4.19995H2.3142C2.16722 3.86972 2.09412 3.51137 2.1 3.14995C2.1 1.72895 3.3481 0.699951 4.739 0.699951C5.5545 0.699951 6.4176 1.08215 7 1.77025C7.5824 1.08215 8.4455 0.699951 9.261 0.699951C10.6526 0.699951 11.9 1.72895 11.9 3.14995ZM6.3 14V8.39995H0.7V12.95C0.7 13.531 1.169 14 1.75 14H6.3ZM7.7 14V8.39995H13.3V12.95C13.3 13.531 12.831 14 12.25 14H7.7Z"
      fill={color}
    />
  </svg>
);

export default GiftIcon;
