import { Error } from 'src/types';

const setArrayErrorsToObject = (
  errors?: Error[],
): Record<string, string[]> | undefined => {
  if (errors) {
    return errors.reduce((prevValue, currentValue) => {
      return {
        ...prevValue,
        [currentValue.field]: currentValue.errors,
      };
    }, {});
  }
};

export default setArrayErrorsToObject;
