import { AxiosResponse } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  TopUpUpdateAction,
  TopUpUpdateFailAction,
  TopUpUpdateRequestAction,
  TopUpUpdateResetAction,
  TopUpUpdateSuccessAction,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { TopUp } from 'src/types/topup';
import axios from 'src/utils/axios';

interface UpdateTopupParams {
  employee_id: string | number;
  rent_top_up: number;
  purchase_top_up: number;
}

interface AxiosResponseData {
  data: TopUp;
}

export const updateTopup =
  ({
    employee_id,
    purchase_top_up = 0.0,
    rent_top_up = 0.0,
  }: UpdateTopupParams): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    TopUpUpdateAction
  > =>
  async dispatch => {
    dispatch<TopUpUpdateRequestAction>({
      type: ActionTypes.TOPUP_UPDATE_REQUEST,
    });

    try {
      const res = await axios.post<
        AxiosResponseData,
        AxiosResponse<AxiosResponseData>,
        UpdateTopupParams
      >(`${apiConfig.url}/topup`, {
        employee_id,
        purchase_top_up,
        rent_top_up,
      });

      const { data } = res.data;

      dispatch<TopUpUpdateSuccessAction>({
        type: ActionTypes.TOPUP_UPDATE_SUCCESS,
        payload: data,
      });
      enqueueSnackbar('Top-up Success', { variant: 'success' });
    } catch (e) {
      dispatch<TopUpUpdateFailAction>({
        type: ActionTypes.TOPUP_UPDATE_FAIL,
        payload: 'Top-up failed',
      });

      enqueueSnackbar('Top-up Failed', { variant: 'error' });
    }
  };

export const updateTopupReset = (): TopUpUpdateResetAction => ({
  type: ActionTypes.TOPUP_UPDATE_RESET,
});
