import { FC, useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'src/redux-hooks';
import { acceptWelcomeMessageSelector } from 'src/selectors';

import DialogModal from './DialogModal';

interface InfoPopupProps {
  text: string;
  afterClose: () => void;
}

const WelcomePopup: FC<InfoPopupProps> = ({ text, afterClose }) => {
  const [open, setOpen] = useState<boolean>(true);
  const { acceptWelcomeMessage } = useDispatch();
  const { loading, success } = useSelector(acceptWelcomeMessageSelector);

  const handleClose = () => {
    setOpen(false);
    afterClose();
  };

  const handleAccept = () => {
    acceptWelcomeMessage();
  };

  useEffect(() => {
    if (success) {
      handleClose();
    }
  }, [success]);

  text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

  return (
    <DialogModal
      open={open}
      onClose={() => {}}
      footer={{
        alignment: 'center',
        buttons: [
          {
            text: 'Accept and go to Workwize',
            onClick: handleAccept,
            color: 'primary',
            variant: 'contained',
            disabled: loading,
          },
        ],
      }}
    >
      <Typography variant="body1" color="textPrimary">
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </DialogModal>
  );
};

export default WelcomePopup;
