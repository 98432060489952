import { z } from 'zod';

import { countryValidator } from './country-validator';

export const addressValidator = z.object({
  id: z.union([z.string(), z.number()]).nullish(),
  address_line_2: z.string().nullish(),
  house_number_addition: z.string().nullish(),
  address_line_1: z.string().nullish(),
  city: z.string(),
  region: z.string().nullish(),
  postal_code: z.string(),
  additional_address_line: z.string().nullish(),
  phone_number: z.string().nullish(),
  email: z.string().nullish(),
  country_id: z.union([z.string(), z.number()]).nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  country: countryValidator.nullish(),
});

export type Address = z.infer<typeof addressValidator>;
