import { FC, useEffect } from 'react';

import { OAuthError, useAuth0 } from '@auth0/auth0-react';

import SplashScreen from 'src/components/SplashScreen';
import { setup } from 'src/utils/axios';

let authSetup = false;

const LoginHOC: FC = ({ children }) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!authSetup) {
      setup(async () => {
        try {
          const token = await getAccessTokenSilently();

          return token;
        } catch (e) {
          console.error(e);
          const tokenError = e as OAuthError | Error;

          const errorMessage =
            tokenError instanceof OAuthError
              ? tokenError.error
              : tokenError.message;

          // SOMETIMES THE ERRORS ARRIVES WIHTOUT THE UNDERSCORE (e.g. login required)
          if (
            [
              'login_required',
              'login required',
              'consent_required',
              'consent required',
              'interaction_required',
              'interaction required',
            ].includes(errorMessage.toLowerCase())
          ) {
            await loginWithRedirect();
            return '';
          }

          throw e;
        }
      });
      authSetup = true;
    }
  }, [getAccessTokenSilently, loginWithRedirect]);

  if (!authSetup) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default LoginHOC;
