export const CATALOG_PRODUCT_INFINITE_REQUEST =
  'CATALOG_PRODUCT_INFINITE_REQUEST';
export const CATALOG_PRODUCT_INFINITE_SUCCESS =
  'CATALOG_PRODUCT_INFINITE_SUCCESS';
export const CATALOG_PRODUCT_INFINITE_FAIL = 'CATALOG_PRODUCT_INFINITE_FAIL';
export const CATALOG_PRODUCT_INFINITE_RESET = 'CATALOG_PRODUCT_INFINITE_RESET';

export const CATALOG_PRODUCT_REMOVE_REQUEST = 'CATALOG_PRODUCT_REMOVE_REQUEST';
export const CATALOG_PRODUCT_REMOVE_SUCCESS = 'CATALOG_PRODUCT_REMOVE_SUCCESS';
export const CATALOG_PRODUCT_REMOVE_FAIL = 'CATALOG_PRODUCT_REMOVE_FAIL';
export const CATALOG_PRODUCT_REMOVE_RESET = 'CATALOG_PRODUCT_REMOVE_RESET';

export const CATALOG_PRODUCT_ADD_REQUEST = 'CATALOG_PRODUCT_ADD_REQUEST';
export const CATALOG_PRODUCT_ADD_SUCCESS = 'CATALOG_PRODUCT_ADD_SUCCESS';
export const CATALOG_PRODUCT_ADD_FAIL = 'CATALOG_PRODUCT_ADD_FAIL';
export const CATALOG_PRODUCT_ADD_RESET = 'CATALOG_PRODUCT_ADD_RESET';

export const CATALOG_PRODUCT_LIST_REQUEST = 'CATALOG_PRODUCT_LIST_REQUEST';
export const CATALOG_PRODUCT_LIST_SUCCESS = 'CATALOG_PRODUCT_LIST_SUCCESS';
export const CATALOG_PRODUCT_LIST_FAIL = 'CATALOG_PRODUCT_LIST_FAIL';
export const CATALOG_PRODUCT_LIST_RESET = 'CATALOG_PRODUCT_LIST_RESET';
