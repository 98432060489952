import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductDeleteAction,
  ProductDeleteActionFail,
  ProductDeleteActionRequest,
  ProductDeleteActionReset,
  ProductDeleteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const deleteProduct =
  (
    id: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, ProductDeleteAction> =>
  async dispatch => {
    dispatch<ProductDeleteActionRequest>({
      type: ActionTypes.PRODUCT_DELETE_REQUEST,
    });

    try {
      await axios.delete<Response>(`${apiConfig.url}/products/${id}`);

      dispatch<ProductDeleteActionSuccess>({
        type: ActionTypes.PRODUCT_DELETE_SUCCESS,
        payload: id,
      });

      enqueueSnackbar('Product successfully deleted', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<ProductDeleteActionFail>({
        type: ActionTypes.PRODUCT_DELETE_FAIL,
        payload: 'Product failed to delete',
      });

      enqueueSnackbar('Product failed to delete', {
        variant: 'error',
      });
    }
  };

export const deleteProductReset = (): ProductDeleteActionReset => ({
  type: ActionTypes.PRODUCT_DELETE_RESET,
});
