import * as ActionTypes from 'src/action-types';
import {
  PlaceOrderSubmitActionFail,
  PlaceOrderSubmitActionSuccess,
} from 'src/actions';

export const setPlaceOrderSubmitStatusSuccess =
  (): PlaceOrderSubmitActionSuccess => ({
    type: ActionTypes.PLACE_ORDER_SUBMIT_SUCCESS,
  });

export const setPlaceOrderSubmitStatusFail =
  (): PlaceOrderSubmitActionFail => ({
    type: ActionTypes.PLACE_ORDER_SUBMIT_FAIL,
  });
