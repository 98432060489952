import { makeStyles } from '@material-ui/styles';

export const useDialogHeaderStyles = makeStyles(() => ({
  root: {
    margin: '17px 7px 5px 7px',
    paddingRight: '50px',
    position: 'relative',
    fontSize: 18,
    fontWeight: 500,
  },
  closeIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

export const useDialogFooterStyles = makeStyles(() => ({
  root: {
    margin: '20px',
  },
  loaderButton: {
    marginLeft: '10px',
  },
}));

export const useDialogContentStyles = makeStyles(() => ({
  contentWrapper: {
    margin: '30px 10px',
  },
}));

export const useAlignmentStyles = makeStyles(() => ({
  left: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'left',
  },
  right: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'right',
  },
  center: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));
