import { FC } from 'react';

import { FormikErrors, FormikValues } from 'formik';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import 'react-phone-input-2/lib/material.css';
import { useStyles } from './PhoneInput.styles';

const PhoneInput: FC<
  PhoneInputProps & {
    errors?:
      | FormikErrors<FormikValues[string][number]>[]
      | string
      | string[]
      | FormikErrors<never>
      | null;
  }
> = ({ errors, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      <ReactPhoneInput
        {...props}
        prefix="+"
        masks={{ ar: '..............' }}
        containerClass={classes.phoneInputContainer}
        inputClass={`${classes.phoneInputFormControl} ${
          errors?.length && 'error'
        }`}
      />
      {errors && <p className={classes.phoneInputError}>{errors}</p>}
    </div>
  );
};

export default PhoneInput;
