import { SVGProps } from 'react';

const DownloadIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_477_5150)">
      <path
        d="M8.25006 0.75C8.25006 0.551088 8.17104 0.360322 8.03039 0.21967C7.88974 0.0790176 7.69897 0 7.50006 0C7.30115 0 7.11038 0.0790176 6.96973 0.21967C6.82908 0.360322 6.75006 0.551088 6.75006 0.75V6.4395L5.78031 5.46975C5.63886 5.33313 5.44941 5.25754 5.25276 5.25924C5.05611 5.26095 4.868 5.33983 4.72895 5.47889C4.58989 5.61794 4.51101 5.80605 4.50931 6.0027C4.5076 6.19935 4.58319 6.3888 4.71981 6.53025L6.96981 8.78025C7.11046 8.92085 7.30119 8.99984 7.50006 8.99984C7.69893 8.99984 7.88967 8.92085 8.03031 8.78025L10.2803 6.53025C10.4169 6.3888 10.4925 6.19935 10.4908 6.0027C10.4891 5.80605 10.4102 5.61794 10.2712 5.47889C10.1321 5.33983 9.94401 5.26095 9.74736 5.25924C9.55071 5.25754 9.36126 5.33313 9.21981 5.46975L8.25006 6.4395V0.75Z"
        fill={color}
      />
      <path
        d="M2.25 10.5V2.25H5.25V0.75H1.875C1.57663 0.75 1.29048 0.868526 1.0795 1.0795C0.868526 1.29048 0.75 1.57663 0.75 1.875V13.125C0.75 13.4234 0.868526 13.7095 1.0795 13.9205C1.29048 14.1315 1.57663 14.25 1.875 14.25H13.125C13.4234 14.25 13.7095 14.1315 13.9205 13.9205C14.1315 13.7095 14.25 13.4234 14.25 13.125V1.875C14.25 1.57663 14.1315 1.29048 13.9205 1.0795C13.7095 0.868526 13.4234 0.75 13.125 0.75H9.75V2.25H12.75V10.5H10.125C9.54375 10.5 9.084 10.9965 8.6805 11.433L8.598 11.523C8.4577 11.6738 8.28776 11.7941 8.09881 11.8762C7.90987 11.9582 7.706 12.0004 7.5 12C7.29407 12.0002 7.09031 11.9579 6.9015 11.8757C6.71269 11.7935 6.54289 11.6731 6.40275 11.5222L6.3195 11.4323C5.916 10.998 5.45625 10.5 4.875 10.5H2.25Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_477_5150">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DownloadIcon;
