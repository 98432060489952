import { useQuery } from '@tanstack/react-query';

import { CountryType } from 'src/modules/countries/validators/country-response-schema';
import axios from 'src/utils/axios';
import { provinceListValidator } from 'src/utils/validators/province-validator';

export const useListProvinces = (countryCode: CountryType['code']) => {
  return useQuery({
    queryKey: ['regions', countryCode],
    queryFn: async () => {
      const response = await axios.get(`/provinces`, {
        params: {
          'filter[country_code]': countryCode,
          sort: 'name',
        },
      });

      const validated = provinceListValidator.parseAsync(response.data);

      return validated;
    },
  });
};
