import { createContext, useReducer, FC, ReactNode } from 'react';

interface DepartmentState {
  department_id: number | null;
}

interface DepartmentContextValue extends DepartmentState {
  // TODO: type any will be included in the ban-types soon. no-explicit-any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setDepartment: (departmentId: any) => void;
}

interface CartProviderProps {
  children: ReactNode;
}

type OpenCartAction = {
  type: 'SET_DEPARTMENT';
  payload: {
    department_id: number;
  };
};

type Action = OpenCartAction;

const initialDepartmentState: DepartmentState = {
  department_id: null,
};

const reducer = (state: DepartmentState, action: Action): DepartmentState => {
  switch (action.type) {
    case 'SET_DEPARTMENT': {
      // TODO: type any will be included in the ban-types soon.
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { department_id } = action.payload;

      return {
        ...state,
        department_id,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const DepartmentContext = createContext<DepartmentContextValue>({
  ...initialDepartmentState,
  setDepartment: () => {},
});

export const DepartmentProvider: FC<CartProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDepartmentState);

  // TODO: type any will be included in the ban-types soon.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setDepartment = (departmentId: any) => {
    dispatch({
      type: 'SET_DEPARTMENT',
      payload: {
        department_id: departmentId,
      },
    });
  };

  return (
    <DepartmentContext.Provider
      value={{
        ...state,
        setDepartment,
      }}
    >
      {children}
    </DepartmentContext.Provider>
  );
};

export default DepartmentContext;
