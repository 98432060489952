import { CURRENCIES, CurrencyType } from '../types';

export const UPPERCASE_CURRENCIES = CURRENCIES.map(currency =>
  currency.toUpperCase(),
) as [Uppercase<(typeof CURRENCIES)[number]>, ...string[]];

type MenuItem = {
  value: string;
  label: string;
  disabled: boolean;
};

const MenuItemsPerCurrency: Record<CurrencyType, MenuItem> = {
  aed: { value: 'aed', label: 'AED د.إ', disabled: false },
  aud: { value: 'aud', label: 'AUD $', disabled: false },
  cad: { value: 'cad', label: 'CAD $', disabled: false },
  chf: { value: 'chf', label: 'Franc ₣', disabled: false },
  cny: { value: 'cny', label: 'CNY ¥', disabled: false },
  credit: { value: 'credit', label: 'Credit', disabled: true },
  egp: { value: 'egp', label: 'EGP E£', disabled: false },
  eur: { value: 'eur', label: 'EUR €', disabled: false },
  gbp: { value: 'gbp', label: 'Pound £', disabled: false },
  hkd: { value: 'hkd', label: 'HKD $', disabled: false },
  idr: { value: 'idr', label: 'IDR Rp', disabled: false },
  ils: { value: 'ils', label: 'ILS ₪', disabled: false },
  inr: { value: 'inr', label: 'INR ₹', disabled: false },
  isk: { value: 'isk', label: 'ISK kr', disabled: false },
  jpy: { value: 'jpy', label: 'JPY ¥', disabled: false },
  krw: { value: 'krw', label: 'KRW ₩', disabled: false },
  myr: { value: 'myr', label: 'MYR RM', disabled: false },
  ngn: { value: 'ngn', label: 'NGN ₦', disabled: false },
  nzd: { value: 'nzd', label: 'NZD NZ$', disabled: false },
  pkr: { value: 'pkr', label: 'PKR ₨', disabled: false },
  pound: { value: 'pound', label: 'Pound £', disabled: true },
  rsd: { value: 'rsd', label: 'RSD Дин', disabled: false },
  sar: { value: 'sar', label: 'SAR ﷼', disabled: false },
  sgd: { value: 'sgd', label: 'SGD $', disabled: false },
  try: { value: 'try', label: 'TRY ₺', disabled: false },
  uah: { value: 'uah', label: 'UAH ₴', disabled: false },
  usd: { value: 'usd', label: 'USD $', disabled: false },
  vnd: { value: 'vnd', label: 'VND ₫', disabled: false },
  xcr: { value: 'xcr', label: 'Credits', disabled: false },
  zar: { value: 'zar', label: 'ZAR R', disabled: false },
};

export const currenciesMenuItems: MenuItem[] =
  Object.values(MenuItemsPerCurrency);

// todo check where this is used
export type Currency = CurrencyType;
