import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  HelpInfoAction,
  HelpInfoActionFail,
  HelpInfoActionRequest,
  HelpInfoActionReset,
  HelpInfoActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { HelpInfo, Response } from 'src/types';
import axios from 'src/utils/axios';

export const getHelpInfo =
  (): ThunkAction<Promise<void>, RootState, undefined, HelpInfoAction> =>
  async dispatch => {
    dispatch<HelpInfoActionRequest>({
      type: ActionTypes.HELP_INFO_REQUEST,
    });

    try {
      const res = await axios.get<Response<HelpInfo>>(
        `${apiConfig.url}/help-info`,
      );
      const { data } = res.data;

      dispatch<HelpInfoActionSuccess>({
        type: ActionTypes.HELP_INFO_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<HelpInfoActionFail>({
        type: ActionTypes.HELP_INFO_FAIL,
        payload: 'Unable to fetch help info',
      });
    }
  };

export const getHelpInfoReset = (): HelpInfoActionReset => ({
  type: ActionTypes.HELP_INFO_RESET,
});
