import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  PerksCreateAction,
  PerksDeleteAction,
  PerksGetAction,
  PerksGetSingleAction,
  PerksUpdateAction,
} from 'src/actions';
import { Perks } from 'src/types';

interface PerksInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface PerksGetState extends PerksInitialState {
  perks: Perks[];
}

export const perksGetReducer: Reducer<PerksGetState, PerksGetAction> = (
  state = { perks: [] },
  action,
): PerksGetState => {
  switch (action.type) {
    case ActionTypes.PERKS_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PERKS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        perks: action.payload,
      };
    case ActionTypes.PERKS_GET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionTypes.PERKS_GET_RESET:
      return { perks: [] };
    default:
      return state;
  }
};

interface PerksCreateState extends PerksInitialState {
  perk?: Perks;
}

export const perksCreateReducer: Reducer<
  PerksCreateState,
  PerksCreateAction
> = (state = {}, action): PerksCreateState => {
  switch (action.type) {
    case ActionTypes.PERKS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PERKS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        perk: action.payload,
        success: true,
      };
    case ActionTypes.PERKS_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PERKS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

interface PerksGetSingleState extends PerksInitialState {
  perk?: Perks;
}

export const perksGetSingleReducer: Reducer<
  PerksGetSingleState,
  PerksGetSingleAction
> = (state = {}, action): PerksGetSingleState => {
  switch (action.type) {
    case ActionTypes.PERKS_GET_SINGLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PERKS_GET_SINGLE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        perk: action.payload,
      };
    case ActionTypes.PERKS_GET_SINGLE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PERKS_GET_SINGLE_RESET:
      return {};
    default:
      return state;
  }
};

type PerksDeleteState = PerksInitialState;

export const perksDeleteReducer: Reducer<
  PerksDeleteState,
  PerksDeleteAction
> = (state = {}, action): PerksDeleteState => {
  switch (action.type) {
    case ActionTypes.PERKS_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PERKS_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.PERKS_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.PERKS_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

interface PerksUpdateState extends PerksInitialState {
  perk?: Perks;
}

export const perksUpdateReducer: Reducer<
  PerksUpdateState,
  PerksUpdateAction
> = (state = {}, action): PerksUpdateState => {
  switch (action.type) {
    case ActionTypes.PERKS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PERKS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        perk: action.payload,
      };
    case ActionTypes.PERKS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.PERKS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
