import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { CategoryListAction } from 'src/actions/category-action';
import { Category } from 'src/types';

interface CategoryInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface CategoryListState extends CategoryInitialState {
  categories: Category[];
}

const categoryListIntialState: CategoryListState = {
  categories: [],
};

export const categoryListReducer: Reducer<
  CategoryListState,
  CategoryListAction
> = (state = categoryListIntialState, action) => {
  switch (action.type) {
    case ActionTypes.CATEGORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        categories: action.payoad,
      };
    case ActionTypes.CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATEGORY_LIST_RESET:
      return categoryListIntialState;
    default:
      return state;
  }
};
