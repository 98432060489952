export const EMPLOYER_ANNOUNCEMENT_REQUEST = 'EMPLOYER_ANNOUNCEMENT_REQUEST';
export const EMPLOYER_ANNOUNCEMENT_SUCCESS = 'EMPLOYER_ANNOUNCEMENT_SUCCESS';
export const EMPLOYER_ANNOUNCEMENT_FAIL = 'EMPLOYER_ANNOUNCEMENT_FAIL';
export const EMPLOYER_ANNOUNCEMENT_RESET = 'EMPLOYER_ANNOUNCEMENT_RESET';

export const EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST =
  'EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST';
export const EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS =
  'EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS';
export const EMPLOYER_ANNOUNCEMENT_CREATE_FAIL =
  'EMPLOYER_ANNOUNCEMENT_CREATE_FAIL';
export const EMPLOYER_ANNOUNCEMENT_CREATE_RESET =
  'EMPLOYER_ANNOUNCEMENT_CREATE_RESET';

export const EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST =
  'EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST';
export const EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS =
  'EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS';
export const EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL =
  'EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL';
export const EMPLOYER_ANNOUNCEMENT_UPDATE_RESET =
  'EMPLOYER_ANNOUNCEMENT_UPDATE_RESET';

export const EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST =
  'EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST';
export const EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS =
  'EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS';
export const EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL =
  'EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL';
export const EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET =
  'EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET';

export const EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST =
  'EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST';
export const EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS =
  'EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS';
export const EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL =
  'EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL';
export const EMPLOYER_ANNOUNCEMENT_IMAGE_RESET =
  'EMPLOYER_ANNOUNCEMENT_IMAGE_RESET';
