import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardEmployeeCountActionFail,
  GetDashboardEmployeeCountActionList,
  GetDashboardEmployeeCountActionRequest,
  GetDashboardEmployeeCountActionSuccess,
  GetDashboardEmployeeCountActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export const getDashboardEmployeeCount =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardEmployeeCountActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardEmployeeCountActionRequest>({
      type: ActionTypes.GET_EMPLOYEES_COUNT_REQUEST,
    });

    try {
      const res = await axios.get<{ data: { count: number } }>(
        `${apiConfig.url}/new-employees`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardEmployeeCountActionSuccess>({
        type: ActionTypes.GET_EMPLOYEES_COUNT_SUCCESS,
        payload: data.count,
      });
    } catch (err) {
      dispatch<GetDashboardEmployeeCountActionFail>({
        type: ActionTypes.GET_EMPLOYEES_COUNT_FAIL,
        payload: 'Something Went Wrong while Fetching New Users Count',
      });
    }
  };

export const getDashboardEmployeeCountResest =
  (): GetDashboardEmployeeCountActionReset => ({
    type: ActionTypes.GET_EMPLOYEES_COUNT_RESET,
  });
