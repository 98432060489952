import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const perksSelector = createSelector(
  [(state: RootState) => state],
  state => state.perksGet,
);

export const perksCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.perksCreate,
);

export const perksGetSingleSelector = createSelector(
  [(state: RootState) => state],
  state => state.perksGetSingle,
);

export const perksDeleteSelector = createSelector(
  [(state: RootState) => state],
  state => state.perksDelete,
);

export const perksUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.perksUpdate,
);
