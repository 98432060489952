import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardTotalProductsOrderedActionFail,
  GetDashboardTotalProductsOrderedActionList,
  GetDashboardTotalProductsOrderedActionRequest,
  GetDashboardTotalProductsOrderedActionSuccess,
  GetDashboardTotalProductsOrderedActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export const getDashboardTotalProductOrdered =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardTotalProductsOrderedActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardTotalProductsOrderedActionRequest>({
      type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_REQUEST,
    });

    try {
      const res = await axios.get<{ data: { total: number } }>(
        `${apiConfig.url}/total-products-ordered`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardTotalProductsOrderedActionSuccess>({
        type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_SUCCESS,
        payload: data.total,
      });
    } catch (err) {
      dispatch<GetDashboardTotalProductsOrderedActionFail>({
        type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_FAIL,
        payload: 'Something Went Wrong while Fetching Total Products Ordered',
      });
    }
  };

export const getDashboardTotalProductOrderedReset =
  (): GetDashboardTotalProductsOrderedActionReset => ({
    type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_RESET,
  });
