import { TFunction } from 'i18next';
import * as Yup from 'yup';

import { AddressFormikValues } from 'src/types/formikValues';

export const initialFormValue: AddressFormikValues = {
  id: '',
  country: null,
  postal_code: '',
  address_line_2: '',
  address_line_1: '',
  additional_address_line: '',
  city: '',
  region: '',
  house_number_addition: '',
  phone_number: '',
};

export const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    region: Yup.mixed().nullable(),
    country: Yup.object()
      .required(t('common:form_validation.country_is_required'))
      .nullable(),
    postal_code: Yup.string().required(
      t('common:form_validation.postcode_is_required'),
    ),
    address_line_2: Yup.string().nullable(),
    address_line_1: Yup.string()
      .nullable()
      .required(t('common:form_validation.address_line_1_is_required')),
    city: Yup.string().required(t('common:form_validation.city_is_required')),
    additional_address_line: Yup.string().nullable(),
  });

export const phoneValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    region: Yup.mixed().nullable(),
    country: Yup.object()
      .required(t('common:form_validation.country_is_required'))
      .nullable(),
    postal_code: Yup.string().required(
      t('common:form_validation.postcode_is_required'),
    ),
    address_line_2: Yup.string().nullable(),
    address_line_1: Yup.string().required(
      t('common:form_validation.address_line_1_is_required'),
    ),
    city: Yup.string().required(t('common:form_validation.city_is_required')),
    phone_number: Yup.string().required(
      t('common:form_validation.phone_number_is_required'),
    ),
    additional_address_line: Yup.string().nullable(),
  });
