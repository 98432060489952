import { SVGProps } from 'react';

const CatalogueIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.15 0C2.87152 0 2.60445 0.110625 2.40754 0.307538C2.21062 0.504451 2.1 0.771523 2.1 1.05V1.4H10.5V1.05C10.5 0.771523 10.3894 0.504451 10.1925 0.307538C9.99555 0.110625 9.72848 0 9.45 0H3.15ZM0.7 3.85C0.7 3.57152 0.810625 3.30445 1.00754 3.10754C1.20445 2.91062 1.47152 2.8 1.75 2.8H10.85C11.1285 2.8 11.3955 2.91062 11.5925 3.10754C11.7894 3.30445 11.9 3.57152 11.9 3.85V4.2H0.7V3.85ZM0 6.65C0 6.37152 0.110625 6.10445 0.307538 5.90754C0.504451 5.71062 0.771523 5.6 1.05 5.6H11.55C11.8285 5.6 12.0955 5.71062 12.2925 5.90754C12.4894 6.10445 12.6 6.37152 12.6 6.65V11.55C12.6 11.8285 12.4894 12.0955 12.2925 12.2925C12.0955 12.4894 11.8285 12.6 11.55 12.6H1.05C0.771523 12.6 0.504451 12.4894 0.307538 12.2925C0.110625 12.0955 0 11.8285 0 11.55V6.65Z"
      fill={color}
    />
  </svg>
);

export default CatalogueIcon;
