import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductVariantUpdateAction,
  ProductVariantUpdateActionFail,
  ProductVariantUpdateActionRequest,
  ProductVariantUpdateActionReset,
  ProductVariantUpdateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios, { ErrorResponse } from 'src/utils/axios';

export const updateProductVariant =
  (
    id: string | number,
    productData: FormData,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    ProductVariantUpdateAction
  > =>
  async dispatch => {
    dispatch<ProductVariantUpdateActionRequest>({
      type: ActionTypes.PRODUCT_VARIANT_UPDATE_REQUEST,
    });

    try {
      const res = await axios.post<Response<Product>>(
        `${apiConfig.url}/products/${id}`,
        productData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const { data } = res.data;

      dispatch<ProductVariantUpdateActionSuccess>({
        type: ActionTypes.PRODUCT_VARIANT_UPDATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Variant successfully updated', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const err = e.response?.data;
        const errors: Record<string, unknown> = {};

        if (err?.errors) {
          err.errors.forEach(error => {
            errors[error.field] = error.errors[0];
          });
        }

        dispatch<ProductVariantUpdateActionFail>({
          type: ActionTypes.PRODUCT_VARIANT_UPDATE_FAIL,
          payload: errors,
        });
      }

      enqueueSnackbar('Unable to update variant', {
        variant: 'error',
      });
    }
  };

export const updateProductVariantReset =
  (): ProductVariantUpdateActionReset => ({
    type: ActionTypes.PRODUCT_VARIANT_UPDATE_RESET,
  });
