import { ThunkAction } from 'redux-thunk';

import * as Actiontypes from 'src/action-types';
import {
  AuthUserUploadPhotoAction,
  AuthUserUploadPhotoActionFail,
  AuthUserUploadPhotoActionRequest,
  AuthUserUploadPhotoActionReset,
  AuthUserUploadPhotoActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Response, User } from 'src/types';
import axios, { ErrorResponse } from 'src/utils/axios';
import setArrayErrorsToObject from 'src/utils/setArrayErrorsToObject';

export const uploadAuthUserPhoto =
  (
    file: File,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    AuthUserUploadPhotoAction
  > =>
  async dispatch => {
    dispatch<AuthUserUploadPhotoActionRequest>({
      type: Actiontypes.AUTH_USER_UPLOAD_PHOTO_REQUEST,
    });

    try {
      const data = new FormData();

      data.append('avatar', file);
      data.append('_method', 'PATCH');

      const res = await axios.post<Response<User>>(
        `${apiConfig.url}/user?include=employer,employee,files`,
        data,
      );

      const { data: user } = res.data;

      dispatch<AuthUserUploadPhotoActionSuccess>({
        type: Actiontypes.AUTH_USER_UPLOAD_PHOTO_SUCCESS,
        payload: user,
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const errors = setArrayErrorsToObject(e.response?.data.errors);

        if (errors) {
          dispatch<AuthUserUploadPhotoActionFail>({
            type: Actiontypes.AUTH_USER_UPLOAD_PHOTO_FAIL,
            payload: errors,
          });
        }
      }
    }
  };

export const uploadAuthUserPhotoReset = (): AuthUserUploadPhotoActionReset => ({
  type: Actiontypes.AUTH_USER_UPLOAD_PHOTO_RESET,
});
