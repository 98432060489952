import { Selector, createSelector } from 'reselect';

import {
  cartOpenDrawerReducer,
  cartProductAddReducer,
  cartProductListReducer,
  cartProductRemoveReducer,
} from 'src/reducers/cart-reducer';
import { RootState } from 'src/store';

export const cartOpenDrawerSelector: Selector<
  RootState,
  ReturnType<typeof cartOpenDrawerReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.cartOpenDrawer,
);

export const cartProductListSelector: Selector<
  RootState,
  ReturnType<typeof cartProductListReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.cartProductList,
);

export const cartProductCountSelector = createSelector(
  cartProductListSelector,
  state => {
    return state.cartProducts?.cart.reduce((prev, curr) => {
      return prev + curr.product_quantity;
    }, 0);
  },
);

export const cartProductRemoveSelector: Selector<
  RootState,
  ReturnType<typeof cartProductRemoveReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.cartProductRemove,
);

export const cartProductAddSelector: Selector<
  RootState,
  ReturnType<typeof cartProductAddReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.cartProductAdd,
);
