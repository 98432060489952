export const PERKS_GET_REQUEST = 'PERKS_GET_REQUEST';
export const PERKS_GET_SUCCESS = 'PERKS_GET_SUCCESS';
export const PERKS_GET_FAIL = 'PERKS_GET_FAIL';
export const PERKS_GET_RESET = 'PERKS_GET_RESET';

export const PERKS_CREATE_REQUEST = 'PERKS_CREATE_REQUEST';
export const PERKS_CREATE_SUCCESS = 'PERKS_CREATE_SUCCESS';
export const PERKS_CREATE_FAIL = 'PERKS_CREATE_FAIL';
export const PERKS_CREATE_RESET = 'PERKS_CREATE_RESET';

export const PERKS_GET_SINGLE_REQUEST = 'PERKS_GET_SINGLE_REQUEST';
export const PERKS_GET_SINGLE_SUCCESS = 'PERKS_GET_SINGLE_SUCCESS';
export const PERKS_GET_SINGLE_FAIL = 'PERKS_GET_SINGLE_FAIL';
export const PERKS_GET_SINGLE_RESET = 'PERKS_GET_SINGLE_RESET';

export const PERKS_UPDATE_REQUEST = 'PERKS_UPDATE_REQUEST';
export const PERKS_UPDATE_SUCCESS = 'PERKS_UPDATE_SUCCESS';
export const PERKS_UPDATE_FAIL = 'PERKS_UPDATE_FAIL';
export const PERKS_UPDATE_RESET = 'PERKS_UPDATE_RESET';

export const PERKS_DELETE_REQUEST = 'PERKS_DELETE_REQUEST';
export const PERKS_DELETE_SUCCESS = 'PERKS_DELETE_SUCCESS';
export const PERKS_DELETE_FAIL = 'PERKS_DELETE_FAIL';
export const PERKS_DELETE_RESET = 'PERKS_DELETE_RESET';
