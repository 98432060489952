import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as _ from 'lodash';

import axios from 'src/utils/axios';
import { Province } from 'src/utils/validators/province-validator';

import * as keys from '../constants/keys';
import { validateAddress } from '../utils/validateAddress';

interface UpsertEmployeeAddress {
  employeeId: string | number | undefined;
  id?: string | number | null | undefined;
  skipValidation?: boolean;
  address: {
    address_line_2: string | null | undefined;
    region: string | null | undefined | Province;
    additional_address_line: string | null | undefined;
    city: string;
    address_line_1: string;
    postal_code: string;
    country_id: number | string;
    country_code: string;
    phone_number?: string | null | undefined;
  };
}

export const useUpsertEmployeeAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({
      employeeId,
      id,
      address,
      skipValidation,
    }: UpsertEmployeeAddress) => {
      if (!skipValidation) {
        await validateAddress({
          ...address,
          region:
            typeof address.region === 'string'
              ? address.region
              : address.region?.name,
        });
      }

      const payload = {
        ..._.omit(address, ['country_code']),
        region:
          typeof address.region === 'string'
            ? address.region
            : address.region?.name,
      };

      if (id) {
        await axios.patch(`/employees/${employeeId}/addresses/${id}`, payload);
      } else {
        await axios.post(`/employees/${employeeId}/addresses`, payload);
      }

      return { employeeId };
    },
    {
      onSuccess: ({ employeeId }) => {
        queryClient.invalidateQueries([keys.GET_EMPLOYEE_ADDRESS, employeeId]);
        queryClient.invalidateQueries([keys.GET_EMPLOYEE]);
      },
    },
  );
};
