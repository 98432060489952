import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployerUpdateAction,
  EmployerUpdateActionFail,
  EmployerUpdateActionRequest,
  EmployerUpdateActionReset,
  EmployerUpdateActionSuccess,
  EmployerUpdateType,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { EmployerUpdateFormikValues } from 'src/types/formikValues';
import axios, { ErrorResponse } from 'src/utils/axios';

import { updateEmployerAnnouncement } from '../employer-announcement-action-creators';

export const updateEmployer =
  (
    employerId: string | number,
    employerData: EmployerUpdateFormikValues,
    formikHelpers?: FormikHelpers<EmployerUpdateFormikValues>,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployerUpdateAction> =>
  async dispatch => {
    dispatch<EmployerUpdateActionRequest>({
      type: ActionTypes.EMPLOYER_UPDATE_REQUEST,
    });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { announcement_message, message_type } = employerData;

    try {
      const res = await axios.put<{ data: EmployerUpdateType }>(
        apiConfig.url + `/employer/${employerId}/theme`,
        { ...employerData },
      );

      const { data } = res.data;

      if (res.status && data.employer_id) {
        dispatch(
          updateEmployerAnnouncement(data.employer_id.toString(), {
            message: announcement_message,
            message_type,
          }),
        );
      }

      dispatch<EmployerUpdateActionSuccess>({
        type: ActionTypes.EMPLOYER_UPDATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Employer successfully updated', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (formikHelpers && error) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);
        }

        if (e.response?.status === 422) {
          enqueueSnackbar('Invalid input values', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Server Error', {
            variant: 'error',
          });
        }
      }

      dispatch<EmployerUpdateActionFail>({
        type: ActionTypes.EMPLOYER_UPDATE_FAIL,
        payload: 'Unable to update employer',
      });
    }
  };

export const updateEmployerReset = (): EmployerUpdateActionReset => ({
  type: ActionTypes.EMPLOYER_UPDATE_RESET,
});
