import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeDetailsAction,
  EmployeeDetailsActionFail,
  EmployeeDetailsActionRequest,
  EmployeeDetailsActionReset,
  EmployeeDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee } from 'src/types';
import axios from 'src/utils/axios';

export const getEmployeeDetails =
  (
    employeeId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployeeDetailsAction> =>
  async dispatch => {
    dispatch<EmployeeDetailsActionRequest>({
      type: ActionTypes.EMPLOYEE_DETAILS_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Employee }>(
        `${apiConfig.url}/employees/${employeeId}?include=user.invite,user.files,department,offboards,offboards.country`,
      );

      const { data } = res.data;

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<EmployeeDetailsActionFail>({
        type: ActionTypes.EMPLOYEE_DETAILS_FAIL,
        payload: 'Unable to fetch employee',
      });
    }
  };

export const getEmployeeDetailsReset = (): EmployeeDetailsActionReset => ({
  type: ActionTypes.EMPLOYEE_DETAILS_RESET,
});
