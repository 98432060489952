import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  AddressCreateAction,
  AddressCreateActionFail,
  AddressCreateActionRequest,
  AddressCreateActionSuccess,
  AddressCreateActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Address } from 'src/types';
import { AddressFormikValues } from 'src/types/formikValues';
import axiosInstance from 'src/utils/axios';

export const createAddress =
  (
    value: AddressFormikValues,
    employeeId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, AddressCreateAction> =>
  async dispatch => {
    dispatch<AddressCreateActionRequest>({
      type: ActionTypes.ADDRESS_CREATE_REQUEST,
    });

    try {
      const address = {
        address_line_2: value.address_line_2,
        address_line_1: value.address_line_1,
        city: value.city,
        region: value.region,
        postal_code: value.postal_code,
        country_id: value.country?.id,
        additional_address_line: value.additional_address_line,
      };

      // the endpoint is called '/addresses', but for now it will return 1 active address
      const res = await axiosInstance.post<{ data: Address }>(
        `${apiConfig.url}/employees/${employeeId}/addresses`,
        address,
      );

      const { data } = res.data;

      dispatch<AddressCreateActionSuccess>({
        type: ActionTypes.ADDRESS_CREATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Address Successfully Created', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<AddressCreateActionFail>({
        type: ActionTypes.ADDRESS_CREATE_FAIL,
        payload: 'No Address Found',
      });
    }
  };

export const createAddressReset = (): AddressCreateActionReset => ({
  type: ActionTypes.ADDRESS_CREATE_RESET,
});
