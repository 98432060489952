import { SVGProps } from 'react';

const ClipboardIcon = ({
  height = '18',
  width = '18',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 20"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2.49998H12.1667C12.1667 1.58331 11.4167 0.833313 10.5 0.833313H5.5C4.58333 0.833313 3.83333 1.58331 3.83333 2.49998H3C1.58333 2.49998 0.5 3.58331 0.5 4.99998V16.6666C0.5 18.0833 1.58333 19.1666 3 19.1666H13C14.4167 19.1666 15.5 18.0833 15.5 16.6666V4.99998C15.5 3.58331 14.4167 2.49998 13 2.49998ZM5.5 2.49998H10.5V4.16665H5.5V3.33331V2.49998ZM13 17.5C13.5 17.5 13.8333 17.1666 13.8333 16.6666V4.99998C13.8333 4.49998 13.5 4.16665 13 4.16665H12.1667C12.1667 5.08331 11.4167 5.83331 10.5 5.83331H5.5C4.58333 5.83331 3.83333 5.08331 3.83333 4.16665H3C2.5 4.16665 2.16667 4.49998 2.16667 4.99998V16.6666C2.16667 17.1666 2.5 17.5 3 17.5H13Z"
      fill={color}
    />
  </svg>
);

export default ClipboardIcon;
