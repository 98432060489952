import { Reducer } from 'redux';

import {
  CURRENCY_FAIL,
  CURRENCY_REQUEST,
  CURRENCY_RESET,
  CURRENCY_SUCCESS,
} from 'src/action-types';
import { CurrencyAction, CurrencyType } from 'src/actions';

interface CurrencyInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface CurrencyState extends CurrencyInitialState {
  currencies: CurrencyType[];
}

export const currencyReducer: Reducer<CurrencyState, CurrencyAction> = (
  state = { currencies: [] },
  action,
): CurrencyState => {
  switch (action.type) {
    case CURRENCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        currencies: action.payload,
      };
    case CURRENCY_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case CURRENCY_RESET:
      return state;
    default:
      return state;
  }
};
