import React, { FC } from 'react';

import { Button, makeStyles, ButtonProps } from '@material-ui/core';
import clsx from 'clsx';
import { LocationDescriptor } from 'history';

import { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '10px 20px',
    borderRadius: '5px',
    fontSize: 16,
    lineHeight: '18.75px',
    [theme.breakpoints.down(1747)]: {
      whiteSpace: 'nowrap',
      fontSize: '14px',
    },
    '&.MuiButton-outlined': {
      borderWidth: '1.2px',
      borderColor: '#26262A',
      color: '#26262A',
    },
  },
}));

const NoTransformButton: FC<
  ButtonProps & {
    component?: React.ElementType;
    to?: LocationDescriptor<unknown>;
    isBesideAnotherButton?: boolean;
    isOverAnotherButton?: boolean;
  }
> = ({ isBesideAnotherButton, isOverAnotherButton, children, ...props }) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={clsx(props.className, classes.root)}
      style={{
        borderWidth: '1.2px',
        textTransform: 'unset',
        marginRight: isBesideAnotherButton ? 10 : 0,
        marginBottom: isOverAnotherButton ? 5 : 0,
        ...props.style,
      }}
    >
      {children}
    </Button>
  );
};

export default NoTransformButton;
