import mixpanel from 'mixpanel-browser';

import { Cart } from 'src/types';
import { analytic } from 'src/utils/analytic';

interface SegmentCart {
  product_id: string | number;
  product_quantity: string | number;
}

export const getCartProductListAnalytics = (cart: Cart) => {
  const segmentCart: SegmentCart[] = [];

  cart.cart.forEach(cartProduct => {
    segmentCart.push({
      product_id: cartProduct.product.id,
      product_quantity: cartProduct.product_quantity,
    });
  });

  analytic.track('Mini Cart Viewed', {
    cart: segmentCart,
  });

  try {
    mixpanel.track('view mini-cart', {
      products: cart,
      total_buy: cart.total_buy,
      total_rent: cart.total_rent,
      event_type: 'page view',
    });
  } catch (mixpanel_err) {
    console.error('mixpanel error', mixpanel_err);
  }
};
