import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetAssetSettingActionFail,
  GetAssetSettingActionRequest,
  GetAssetSettingActionReset,
  GetAssetSettingActionSuccess,
  GetAssetSettingsActionList,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { AssetSetting } from 'src/types';
import axios from 'src/utils/axios';

export const getAssetSettings =
  (
    employerId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetAssetSettingsActionList
  > =>
  async dispatch => {
    dispatch<GetAssetSettingActionRequest>({
      type: ActionTypes.GET_ASSET_SETTING_REQUEST,
    });

    try {
      const res = await axios.get<{ data: AssetSetting }>(
        `${apiConfig.url}/employers/${employerId}/asset-settings`,
      );

      const { data } = res.data;

      dispatch<GetAssetSettingActionSuccess>({
        type: ActionTypes.GET_ASSET_SETTING_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetAssetSettingActionFail>({
        type: ActionTypes.GET_ASSET_SETTING_FAIL,
        payload: 'Something Went Wrong. Error in fetching asset settings',
      });
    }
  };

export const getAssetSettingsReset = (): GetAssetSettingActionReset => ({
  type: ActionTypes.GET_ASSET_SETTING_RESET,
});
