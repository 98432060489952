import moment from 'moment';
import { createSelector, Selector } from 'reselect';

import { RootState } from 'src/store';
import { DashboardSalesPerDepartment, LatestAccountInfo } from 'src/types';

export const dashboardStateSelector = createSelector(
  [(state: RootState) => state],
  state => state.dashboardState,
);

export const dashboardTotalSpentSelector: Selector<
  RootState,
  {
    loading?: boolean;
    success?: boolean;
    error?: string;
    data: { currency: string; total: number };
  }
> = createSelector([(state: RootState) => state], state => {
  let curIcon = state.dashboardTotalSpent.dashboard?.currency
    ? state.dashboardTotalSpent.dashboard.currency.icon
    : '€';

  if (state.dashboardTotalSpent.dashboard?.currency?.name === 'CREDIT') {
    curIcon = '€';
  }
  return {
    loading: state.dashboardTotalSpent.loading,
    success: state.dashboardTotalSpent.success,
    error: state.dashboardTotalSpent.error,
    data: {
      currency: curIcon,
      total: state.dashboardTotalSpent.dashboard?.total ?? 0,
    },
  };
});

export const dashboardEmployeeCountSelector = createSelector(
  [(state: RootState) => state],
  state => state.dashboardEmployeeCount,
);

export const dashboardAvgOrderSelector: Selector<
  RootState,
  {
    loading?: boolean;
    success?: boolean;
    error?: string;
    data: { currency: string; average: number };
  }
> = createSelector([(state: RootState) => state], state => {
  let curIcon = state.dashboardAvgOrder.average.currency?.icon ?? '€';

  if (state.dashboardAvgOrder.average.currency?.name === 'CREDIT') {
    curIcon = '€';
  }
  return {
    loading: state.dashboardAvgOrder.loading,
    success: state.dashboardAvgOrder.success,
    error: state.dashboardAvgOrder.error,
    data: {
      currency: curIcon,
      average: state.dashboardAvgOrder.average.average,
    },
  };
});

export const dashboardTotalProductsOrderedSelector = createSelector(
  [(state: RootState) => state],
  state => state.dashboardTotalProductsOrdered,
);

export const dashboardOrderByMonthSelector = createSelector(
  [(state: RootState) => state],
  state => {
    const chartData = Object.values(
      state.dashboardOrderByMonth.monthly.grouped_orders,
    )
      .map(order => ({
        date: moment()
          .year(parseInt(order.year))
          .month(parseInt(order.month) - 1),
        total: order.buy_subtotal + order.rent_subtotal,
      }))
      .sort((a, b) => a.date.diff(b.date))
      .map(order => ({ ...order, date: order.date.format('MMM YYYY') }))
      .reduce(
        (acc, order) => ({
          labels: [...acc.labels, order.date],
          stats: { thisYear: [...acc.stats.thisYear, order.total] },
        }),
        { labels: Array<string>(), stats: { thisYear: Array<number>() } },
      );

    return {
      loading: state.dashboardOrderByMonth.loading,
      success: state.dashboardOrderByMonth.success,
      error: state.dashboardOrderByMonth.error,
      chartData,
    };
  },
);

export const dashboardOrderByDepartmentSelector = createSelector(
  [(state: RootState) => state],
  state => {
    const pieShortData: number[] = [];
    const pieShortLabel: string[] = [];
    const pieHiddenData: number[] = [];
    const pieHiddenLabel: string[] = [];
    let pieTotal = 0;
    let others = false;

    let totalOthers = 0;
    let count = 0;

    for (const k in state.dashboardOrderByDepartment.departments.departments) {
      const department =
        state.dashboardOrderByDepartment.departments.departments[k];
      let totalOrdersPerRole = 0;

      totalOrdersPerRole +=
        Number(department.rent_subtotal) + Number(department.buy_subtotal);

      if (totalOrdersPerRole > 0) {
        count++;

        if (count > 3) {
          others = true;
          totalOthers += totalOrdersPerRole;
          pieHiddenData.push(totalOrdersPerRole);
          pieHiddenLabel.push(department.name);

          if (!pieShortLabel.includes('others')) {
            pieShortLabel.push('others');
          }
        } else {
          pieShortData.push(totalOrdersPerRole);
          pieShortLabel.push(department.name);
        }
      }
      pieTotal += totalOrdersPerRole;
    }

    if (count >= 3) {
      pieShortData.push(totalOthers);
    }

    const chartData: DashboardSalesPerDepartment = {
      datasets: pieShortData,
      labels: pieShortLabel,
      hidden_data: pieHiddenData,
      hidden_label: pieHiddenLabel,
      total: pieTotal,
      is_others: others,
    };

    return {
      loading: state.dashboardOrderByDepartment.loading,
      success: state.dashboardOrderByDepartment.success,
      error: state.dashboardOrderByDepartment.error,
      chartData,
    };
  },
);

export const dashboardLatestRegistrantsSelector = createSelector(
  [(state: RootState) => state],
  state => {
    const latest: LatestAccountInfo[] = [];

    state.dashboardLatestRegistrants.accounts.latest_registrants.forEach(
      reg => {
        let userInfo: LatestAccountInfo = {};

        userInfo = {
          Name: reg.user?.name + ' ' + reg.user?.last_name,
          Department: reg.department?.name,
          Created_at: reg.created_at,
          id: reg.id,
        };

        latest.push(userInfo);
      },
    );

    return {
      loading: state.dashboardLatestRegistrants.loading,
      success: state.dashboardLatestRegistrants.success,
      error: state.dashboardLatestRegistrants.error,
      accounts: {
        registeredCount: state.dashboardLatestRegistrants.accounts.accepted,
        pendingCount: state.dashboardLatestRegistrants.accounts.pending,
        latestRegistrant: latest,
      },
    };
  },
);
