import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardStateActionFail,
  GetDashboardStateActionList,
  GetDashboardStateActionRequest,
  GetDashboardStateActionReset,
  GetDashboardStateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { DashboardStateType } from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardState =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardStateActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardStateActionRequest>({
      type: ActionTypes.DASHBOARD_STATE_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardStateType }>(
        `${apiConfig.url}/dashboard/employees/count`,
      );

      const { data } = res.data;

      dispatch<GetDashboardStateActionSuccess>({
        type: ActionTypes.DASHBOARD_STATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardStateActionFail>({
        type: ActionTypes.DASHBOARD_STATE_FAIL,
        payload: 'Something Went Wrong',
      });
    }
  };

export const getDashboardStateReset = (): GetDashboardStateActionReset => ({
  type: ActionTypes.DASHBOARD_STATE_RESET,
});
