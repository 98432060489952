export const PRODUCT_VARIANT_SELECT_REQUEST = 'PRODUCT_VARIANT_SELECT_REQUEST';
export const PRODUCT_VARIANT_SELECT_SUCCESS = 'PRODUCT_VARIANT_SELECT_SUCCESS';
export const PRODUCT_VARIANT_SELECT_FAIL = 'PRODUCT_VARIANT_SELECT_FAIL';
export const PRODUCT_VARIANT_SELECT_RESET = 'PRODUCT_VARIANT_SELECT_RESET';

export const PRODUCT_VARIANT_REMOVE_REQUEST = 'PRODUCT_VARIANT_REMOVE_REQUEST';
export const PRODUCT_VARIANT_REMOVE_SUCCESS = 'PRODUCT_VARIANT_REMOVE_SUCCESS';
export const PRODUCT_VARIANT_REMOVE_FAIL = 'PRODUCT_VARIANT_REMOVE_FAIL';
export const PRODUCT_VARIANT_REMOVE_RESET = 'PRODUCT_VARIANT_REMOVE_RESET';

export const PRODUCT_VARIANT_DETAILS_REQUEST =
  'PRODUCT_VARIANT_DETAILS_REQUEST';
export const PRODUCT_VARIANT_DETAILS_SUCCESS =
  'PRODUCT_VARIANT_DETAILS_SUCCESS';
export const PRODUCT_VARIANT_DETAILS_FAIL = 'PRODUCT_VARIANT_DETAILS_FAIL';
export const PRODUCT_VARIANT_DETAILS_RESET = 'PRODUCT_VARIANT_DETAILS_RESET';

export const PRODUCT_VARIANT_UPDATE_REQUEST = 'PRODUCT_VARIANT_UPDATE_REQUEST';
export const PRODUCT_VARIANT_UPDATE_SUCCESS = 'PRODUCT_VARIANT_UPDATE_SUCCESS';
export const PRODUCT_VARIANT_UPDATE_FAIL = 'PRODUCT_VARIANT_UPDATE_FAIL';
export const PRODUCT_VARIANT_UPDATE_RESET = 'PRODUCT_VARIANT_UPDATE_RESET';
