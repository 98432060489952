const OfficeIcon = ({
  height = '20',
  width = '20',
  color = 'currentColor',
  style = {},
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8785 0.341203C14.7995 0.219568 14.687 0.123438 14.5545 0.0644531H0.4455C0.312886 0.123369 0.200211 0.219491 0.121136 0.341166C0.0420608 0.46284 -1.84116e-05 0.604841 6.04359e-09 0.749953L6.04268e-09 16.25H1.5V1.65892H13.5V16.25H15V0.749953C14.9999 0.604817 14.9577 0.462827 14.8785 0.341203Z"
        fill={color}
      />
      <path
        d="M3 3.49976C3 3.22361 3.22386 2.99976 3.5 2.99976H6.5C6.77614 2.99976 7 3.22361 7 3.49976V4.49976C7 4.7759 6.77614 4.99976 6.5 4.99976H3.5C3.22386 4.99976 3 4.7759 3 4.49976V3.49976Z"
        fill={color}
      />
      <path
        d="M3 7.49976C3 7.22361 3.22386 6.99976 3.5 6.99976H6.5C6.77614 6.99976 7 7.22361 7 7.49976V8.49976C7 8.7759 6.77614 8.99976 6.5 8.99976H3.5C3.22386 8.99976 3 8.7759 3 8.49976V7.49976Z"
        fill={color}
      />
      <path
        d="M3 11.4998C3 11.2236 3.22386 10.9998 3.5 10.9998H6.5C6.77614 10.9998 7 11.2236 7 11.4998V12.4998C7 12.7759 6.77614 12.9998 6.5 12.9998H3.5C3.22386 12.9998 3 12.7759 3 12.4998V11.4998Z"
        fill={color}
      />
      <path
        d="M8 3.49976C8 3.22361 8.22386 2.99976 8.5 2.99976H11.5C11.7761 2.99976 12 3.22361 12 3.49976V4.49976C12 4.7759 11.7761 4.99976 11.5 4.99976H8.5C8.22386 4.99976 8 4.7759 8 4.49976V3.49976Z"
        fill={color}
      />
      <path
        d="M8 7.49976C8 7.22361 8.22386 6.99976 8.5 6.99976H11.5C11.7761 6.99976 12 7.22361 12 7.49976V8.49976C12 8.7759 11.7761 8.99976 11.5 8.99976H8.5C8.22386 8.99976 8 8.7759 8 8.49976V7.49976Z"
        fill={color}
      />
      <path
        d="M8 11.4998C8 11.2236 8.22386 10.9998 8.5 10.9998H11.5C11.7761 10.9998 12 11.2236 12 11.4998V12.4998C12 12.7759 11.7761 12.9998 11.5 12.9998H8.5C8.22386 12.9998 8 12.7759 8 12.4998V11.4998Z"
        fill={color}
      />
    </svg>
  );
};

export default OfficeIcon;
