import { Reducer } from 'redux';

import {
  GET_ORDER_LIST_FAIL,
  GET_ORDER_LIST_REQUEST,
  GET_ORDER_LIST_RESET,
  GET_ORDER_LIST_SUCCESS,
} from 'src/action-types';
import { GetOrderListActionList } from 'src/actions';
import { Order } from 'src/types';

interface OrderInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface OrderListState extends OrderInitialState {
  orders: Order[];
}

export const orderListReducer: Reducer<
  OrderListState,
  GetOrderListActionList
> = (state = { orders: [] }, action) => {
  switch (action.type) {
    case GET_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        orders: action.payload,
      };
    case GET_ORDER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_ORDER_LIST_RESET:
      return state;
    default:
      return state;
  }
};
