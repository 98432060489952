import { createStyles, makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FAFAFA',
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
    contentWrapperWithSidebar: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 275,
      },
    },
    contentWrapperFullscreen: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    contentContainer: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    content: {
      flex: '1 1 auto',
      height: '100%',
      overflow: 'auto',
    },
  }),
);
