import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  SettingsListAction,
  SettingsListActionFail,
  SettingsListActionRequest,
  SettingsListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Settings } from 'src/types';
import axios from 'src/utils/axios';

export const getSettingsList =
  (): ThunkAction<Promise<void>, RootState, undefined, SettingsListAction> =>
  async dispatch => {
    dispatch<SettingsListActionRequest>({
      type: ActionTypes.SETTINGS_LIST_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Settings[] }>(
        `${apiConfig.url}/settings`,
      );

      const { data } = res.data;

      dispatch<SettingsListActionSuccess>({
        type: ActionTypes.SETTINGS_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<SettingsListActionFail>({
        type: ActionTypes.SETTINGS_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };
