import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployerListAction,
  EmployerListActionFail,
  EmployerListActionRequest,
  EmployerListActionReset,
  EmployerListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employer } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const getEmployerList =
  (options?: {
    limit: number;
    page: number;
    search?: string;
  }): ThunkAction<Promise<void>, RootState, undefined, EmployerListAction> =>
  async dispatch => {
    const page = options?.page ? options.page + 1 : 1;
    const limit = options?.limit ? options.limit : 1;

    dispatch<EmployerListActionRequest>({
      type: ActionTypes.EMPLOYER_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<Employer[]>>(
        `${apiConfig.url}/employers`,
        {
          params: {
            include: 'settings',
            limit,
            page,
            sort: '-id',
            'filter[company_name]': options?.search || undefined,
          },
        },
      );

      const { data, meta } = res.data;

      dispatch<EmployerListActionSuccess>({
        type: ActionTypes.EMPLOYER_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (err) {
      dispatch<EmployerListActionFail>({
        type: ActionTypes.EMPLOYER_LIST_FAIL,
        payload: 'Server error',
      });
    }
  };

export const getEmployerListReset = (): EmployerListActionReset => ({
  type: ActionTypes.EMPLOYER_LIST_RESET,
});
