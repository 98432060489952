import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { AddressValidatorListAction } from 'src/actions';
import { AddressValidator, ErrorResponseType } from 'src/types';

interface AddressValidatorInitialState {
  loading?: boolean;
  success?: boolean;
  error?: ErrorResponseType;
}

interface AddressValidatorState extends AddressValidatorInitialState {
  addressResult?: AddressValidator[];
}

export const addressValidatorReducer: Reducer<
  AddressValidatorState,
  AddressValidatorListAction
> = (state = { addressResult: [] }, action): AddressValidatorState => {
  switch (action.type) {
    case ActionTypes.ADDRESS_VALIDATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ADDRESS_VALIDATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        addressResult: action.payload,
      };
    case ActionTypes.ADDRESS_VALIDATOR_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.ADDRESS_VALIDATOR_RESET:
      return { addressResult: [] };
    default:
      return state;
  }
};
