import { Reducer } from 'redux';

import {
  REQUEST_RETURN_PRODUCT_SUBMIT_SUCCESS,
  REQUEST_RETURN_PRODUCT_SUBMIT_FAIL,
} from 'src/action-types';
import { RequestReturnProductSubmitAction } from 'src/actions';

interface RequestReturnProductSubmitState {
  loading: boolean;
  success: boolean;
}

export const requestReturnProductSubmitReducer: Reducer<
  RequestReturnProductSubmitState,
  RequestReturnProductSubmitAction
> = (
  state = {
    loading: false,
    success: false,
  },
  action,
) => {
  switch (action.type) {
    case REQUEST_RETURN_PRODUCT_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case REQUEST_RETURN_PRODUCT_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};
