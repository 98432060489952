import { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import {
  isEmployerAdmin,
  isEmployerTenant,
} from 'src/modules/user/utils/roles';

interface EmployerGuardProps {
  children?: ReactNode;
}

const EmployerGuard: FC<EmployerGuardProps> = ({ children }) => {
  const { data: user } = useUserProfile();

  // All employer is authorized
  if (
    user?.role.name &&
    !isEmployerAdmin(user.role.name) &&
    !isEmployerTenant(user.role.name)
  ) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

EmployerGuard.propTypes = {
  children: PropTypes.node,
};

export default EmployerGuard;
