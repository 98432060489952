import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogListAction,
  CatalogListActionFail,
  CatalogListActionRequest,
  CatalogListActionReset,
  CatalogListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface GetCatalogListParams {
  limit?: number;
  page?: number;
  search?: string;
}

export const getCatalogList =
  (
    params?: GetCatalogListParams,
  ): ThunkAction<Promise<void>, RootState, undefined, CatalogListAction> =>
  async dispatch => {
    dispatch<CatalogListActionRequest>({
      type: ActionTypes.CATALOG_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<Catalog[]>>(
        `${apiConfig.url}/catalogs`,
        {
          params: {
            limit: params?.limit ?? 10,
            page: params?.page ?? 1,
            sort: '-created_at',
            'filter[name]': params?.search ?? undefined,
          },
        },
      );

      const { data, meta } = res.data;

      dispatch<CatalogListActionSuccess>({
        type: ActionTypes.CATALOG_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (e) {
      dispatch<CatalogListActionFail>({
        type: ActionTypes.CATALOG_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCatalogListReset = (): CatalogListActionReset => ({
  type: ActionTypes.CATALOG_LIST_RESET,
});
