import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  ProductVariantDetailsAction,
  ProductVariantRemoveAction,
  ProductVariantSelectAction,
  ProductVariantUpdateAction,
  ProductVariantUpdateActionSuccess,
} from 'src/actions';
import { InitialState, Product } from 'src/types';

interface ProductVariantSelectState extends InitialState {
  product?: Product;
}

export const productVariantSelectReducer: Reducer<
  ProductVariantSelectState,
  ProductVariantSelectAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_VARIANT_SELECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_VARIANT_SELECT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        product: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_SELECT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_SELECT_RESET:
      return {};
    default:
      return state;
  }
};

interface ProductVariantRemoveState extends InitialState {
  product?: Product;
}

export const productVariantRemoveReducer: Reducer<
  ProductVariantRemoveState,
  ProductVariantRemoveAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_VARIANT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_VARIANT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        product: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

interface ProductVariantDetailsState extends InitialState {
  product?: Product;
}

export const productVariantDetailsReducer: Reducer<
  ProductVariantDetailsState,
  ProductVariantDetailsAction | ProductVariantUpdateActionSuccess
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_VARIANT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_VARIANT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        product: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_UPDATE_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case ActionTypes.PRODUCT_VARIANT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

interface ProductVariantUpdateState extends Omit<InitialState, 'error'> {
  product?: Product;
  error?: Record<string, unknown>;
}

export const productVariantUpdateReducer: Reducer<
  ProductVariantUpdateState,
  ProductVariantUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_VARIANT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_VARIANT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
        success: true,
      };
    case ActionTypes.PRODUCT_VARIANT_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_VARIANT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
