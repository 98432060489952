import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { AcceptWelcomeMessageAction } from 'src/actions';
import { InitialState } from 'src/types';

export const acceptWelcomeMessageReducer: Reducer<
  InitialState,
  AcceptWelcomeMessageAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.ACCEPT_WELCOME_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ACCEPT_WELCOME_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.ACCEPT_WELCOME_MESSAGE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.ACCEPT_WELCOME_MESSAGE_RESET:
      return state;
    default:
      return state;
  }
};
