import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { AccountTypeAction } from 'src/actions/account-types-action';
import { InitialState } from 'src/types';
import { AccountTypes } from 'src/types/accountTypes';

interface AccountTypeState extends InitialState {
  accountTypes: typeof AccountTypes | [];
}

export const accountTypeListReducer: Reducer<
  AccountTypeState,
  AccountTypeAction
> = (state = { accountTypes: [] }, action) => {
  switch (action.type) {
    case ActionTypes.ACCOUNT_TYPE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ACCOUNT_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        accountTypes: action.payload,
      };
    case ActionTypes.ACCOUNT_TYPE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.ACCOUNT_TYPE_LIST_RESET:
      return { accountTypes: [] };
    default:
      return state;
  }
};
