import { z } from 'zod';

import { entityValidator } from 'src/utils/validators/entity-validator';

export const accountTypeEnum = z.enum([
  'live',
  'onboarding',
  'client_trial',
  'sales_demo',
  'to_be_deleted',
]);

export const employerValidator = z.object({
  account_type: z.string().nullable(),
  announcement: z.number().nullable(),
  catalog_id: z.number(),
  city: z.string().nullish(),
  company_name: z.string(),
  created_at: z.string(),
  currency_id: z.number(),
  id: z.number(),
  message: z.string().nullable(),
  months_per_installment: z.number().nullish(),
  org_id: z.string().nullable(),
  postcode: z.string().nullish(),
  address_line_1: z.string().nullish(),
  synced_asset: z.number().nullable(),
  total_employees: z.number(),
  total_orders: z.number(),
  total_pending_requests: z.number().nullish(),
  updated_at: z.string(),
  entities: entityValidator.array().optional(),
});

export type Employer = z.infer<typeof employerValidator>;
