import { FC } from 'react';

import { Redirect } from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { isEmployerTenant } from 'src/modules/user/utils/roles';

const HomeView: FC = () => {
  const { data: user, isLoading } = useUserProfile();
  const redirectToMonday = JSON.parse(
    localStorage.getItem('monday-post-login-redirect') as string,
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (redirectToMonday) {
    const currentTime = new Date().getTime();
    const timeElapsed = currentTime - redirectToMonday.timestamp;

    if (timeElapsed < 300000) {
      localStorage.removeItem('monday-post-login-redirect');
      window.location.href = redirectToMonday.url;
    }
    localStorage.removeItem('monday-post-login-redirect');
  }

  if (user?.role) {
    if (user.role.name === 'admin') {
      return <Redirect to="/app/management/catalogs" />;
    } else if (
      user.role.name === 'employer' ||
      isEmployerTenant(user.role.name)
    ) {
      return <Redirect to="/app/management/dashboard" />;
    }
    if (user.employer?.announcement) {
      return <Redirect to="/app/management/announcement" />;
    }
  }

  return <Redirect to="/app/management/products" />;
};

export default HomeView;
