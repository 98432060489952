import { createSelector, Selector } from 'reselect';

import {
  catalogCreateReducer,
  catalogDetailsReducer,
  catalogListInfiniteReducer,
  catalogListReducer,
  catalogUpdateReducer,
  catalogDuplicateReducer,
} from 'src/reducers/catalog-reducer';
import { RootState } from 'src/store';

export const catalogListSelector: Selector<
  RootState,
  ReturnType<typeof catalogListReducer>
> = createSelector([(state: RootState) => state], state => state.catalogList);

export const catalogDetailsSelector: Selector<
  RootState,
  ReturnType<typeof catalogDetailsReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogDetails,
);

export const catalogUpdateSelector: Selector<
  RootState,
  ReturnType<typeof catalogUpdateReducer>
> = createSelector([(state: RootState) => state], state => state.catalogUpdate);

export const catalogCreateSelector: Selector<
  RootState,
  ReturnType<typeof catalogCreateReducer>
> = createSelector([(state: RootState) => state], state => state.catalogCreate);

export const catalogListInfiniteSelector: Selector<
  RootState,
  ReturnType<typeof catalogListInfiniteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogListInfinite,
);

export const catalogDuplicateSelector: Selector<
  RootState,
  ReturnType<typeof catalogDuplicateReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogDuplicate,
);
