import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  UpdateDepreciationActionFail,
  UpdateDepreciationActionList,
  UpdateDepreciationActionRequest,
  UpdateDepreciationActionReset,
  UpdateDepreciationActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { AssetSetting } from 'src/types';
import axios, { ErrorResponse } from 'src/utils/axios';

export const updateAssetDepreciation =
  (
    employerId: number | string,
    depreciation: number,
    formikHelpers?: FormikHelpers<AssetSetting>,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    UpdateDepreciationActionList
  > =>
  async dispatch => {
    dispatch<UpdateDepreciationActionRequest>({
      type: ActionTypes.UPDATE_DEPRECIATION_REQUEST,
    });

    try {
      const res = await axios.put<{ data: AssetSetting }>(
        `${apiConfig.url}/employers/${employerId}/asset-settings`,
        {
          depreciation,
        },
      );

      const { data } = res.data;

      dispatch<UpdateDepreciationActionSuccess>({
        type: ActionTypes.UPDATE_DEPRECIATION_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Asset Depreciation Updated', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (formikHelpers && error) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);

          if (error.code === 422) {
            enqueueSnackbar('Invalid input values', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Server Error', {
              variant: 'error',
            });
          }

          dispatch<UpdateDepreciationActionFail>({
            type: ActionTypes.UPDATE_DEPRECIATION_FAIL,
            payload: error,
          });
        }
      }
    }
  };

export const updateAssetDepreciationReset =
  (): UpdateDepreciationActionReset => ({
    type: ActionTypes.UPDATE_DEPRECIATION_RESET,
  });
