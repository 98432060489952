import { createSelector, Selector } from 'reselect';

import {
  catalogProductAddReducer,
  catalogProductInfiniteReducer,
  catalogProductListReducer,
  catalogProductRemoveReducer,
} from 'src/reducers/catalog-product-reducer';
import { RootState } from 'src/store';

export const catalogProductInfiniteSelector: Selector<
  RootState,
  ReturnType<typeof catalogProductInfiniteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogProductInfinite,
);

export const catalogProductRemoveSelector: Selector<
  RootState,
  ReturnType<typeof catalogProductRemoveReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogProductRemove,
);

export const catalogProductAddSelector: Selector<
  RootState,
  ReturnType<typeof catalogProductAddReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogProductAdd,
);

export const catalogProductListSelector: Selector<
  RootState,
  ReturnType<typeof catalogProductListReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.catalogProductList,
);
