import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CategoryListAction,
  CategoryListActionFail,
  CategoryListActionRequest,
  CategoryListActionReset,
  CategoryListActionSuccess,
} from 'src/actions/category-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Category } from 'src/types';
import axios from 'src/utils/axios';

interface getCategoryListQueryOptions {
  catalog_id?: string | number;
}

export const getCategoryList =
  (
    queryOptions?: getCategoryListQueryOptions,
  ): ThunkAction<Promise<void>, RootState, undefined, CategoryListAction> =>
  async dispatch => {
    dispatch<CategoryListActionRequest>({
      type: ActionTypes.CATEGORY_LIST_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Category[] }>(
        `${apiConfig.url}/categories`,
        {
          params: {
            'filter[catalog_id]': queryOptions?.catalog_id ?? undefined,
            sort: 'name',
          },
        },
      );

      const { data } = res.data;

      dispatch<CategoryListActionSuccess>({
        type: ActionTypes.CATEGORY_LIST_SUCCESS,
        payoad: data,
      });
    } catch (err) {
      dispatch<CategoryListActionFail>({
        type: ActionTypes.CATEGORY_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCategoryListReset = (): CategoryListActionReset => ({
  type: ActionTypes.CATEGORY_LIST_RESET,
});
