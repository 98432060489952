import { FC, useEffect, useState } from 'react';

import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';

import DialogModal from 'src/components/DialogModal';
import { DialogModalFooterProps } from 'src/components/DialogModal/interface';
import AddressInfoForm from 'src/components/Forms/AddressInfoForm';
import Loader from 'src/components/Loader';
// import useOrderForEmployee from 'src/hooks/useOrderForEmployee';
import { useUpsertEmployeeAddress } from 'src/modules/employee/hooks/useUpsertEmployeeAddress';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { Receiver } from 'src/types';
import { Address } from 'src/utils/validators/address-validator';

export const useStyles = makeStyles(() =>
  createStyles({
    subheader: {
      marginBottom: 20,
    },
    subheaderText: {
      fontWeight: 600,
      lineHeight: '28px',
      letterSpacing: 1,
    },
  }),
);

interface ShippingInfoDialogProps {
  open: boolean;
  address?: Address;
  onClose: () => void;
  isUpdateForm?: boolean;
  useUserAuthId?: boolean;
  receiver?: Receiver;
  withPhone?: boolean;
  setShippingInfoVerified?: (value: boolean) => void;
}

const ShippingInfoDialog: FC<ShippingInfoDialogProps> = ({
  open,
  address,
  onClose,
  isUpdateForm = true,
  useUserAuthId = false,
  withPhone = false,
  receiver,
  setShippingInfoVerified,
}) => {
  const classes = useStyles();
  const { data: user } = useUserProfile();

  const { isLoading, isSuccess: isUpsertSuccess } = useUpsertEmployeeAddress();
  const [errorValidationError, setErrorValidationError] = useState(false);

  useEffect(() => {
    if (isUpsertSuccess) {
      onClose();
    }
  }, [isUpsertSuccess]);

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      size={'md'}
      divider={true}
      enclosedInDialogContent={false}
      header={{
        title: isUpdateForm
          ? 'Edit shipping info'
          : 'Add shipping info (required)',
        closeIcon: isUpdateForm,
      }}
      footer={{
        buttons: [
          {
            text: errorValidationError ? 'Save Anyway' : 'Save',
            type: 'submit',
            isLoading,
            color: 'primary',
            variant: 'contained',
            form: 'shipping-info-form',
          },
          ...(isUpdateForm
            ? [
                {
                  text: 'Cancel',
                  onClick: onClose,
                  color: 'default',
                  variant: 'outlined',
                } as DialogModalFooterProps['buttons'][0],
              ]
            : []),
        ],
      }}
    >
      <Box
        style={{
          padding: '30px 20px',
        }}
      >
        <Loader isLoading={isLoading} />
        <Box className={classes.subheader}>
          <Typography
            variant="h6"
            className={classes.subheaderText}
            gutterBottom
          >
            SHIPPING INFORMATION
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Please provide your address to ensure we can offer you the best
            selection of products available in your area. By knowing your
            location, we can filter our catalog and display only those items
            that are currently available for delivery to your home.
          </Typography>
        </Box>
        <Box
          style={{
            pointerEvents: isLoading ? 'none' : 'all',
          }}
        >
          <AddressInfoForm
            setHasValidationError={setErrorValidationError}
            prefillAddress={address}
            userId={useUserAuthId ? user?.employee?.id : receiver?.id}
            formId="shipping-info-form"
            variant={withPhone ? 'phone' : 'primary'}
            setShippingInfoVerified={setShippingInfoVerified}
            receiver={receiver}
            onSuccess={onClose}
          />
        </Box>
      </Box>
    </DialogModal>
  );
};

export default ShippingInfoDialog;
