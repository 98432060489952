import axiosInstance, { Canceler } from 'axios';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogListInfiniteAction,
  CatalogListInfiniteActionFail,
  CatalogListInfiniteActionRequest,
  CatalogListInfiniteActionReset,
  CatalogListInfiniteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface GetCatalogListInfiniteParams {
  params?: {
    limit?: number;
    page?: number;
    search?: string;
  };
  callback?: (cancel: Canceler) => void;
}

export const getCatalogListInfinite =
  (
    params?: GetCatalogListInfiniteParams['params'],
    callback?: GetCatalogListInfiniteParams['callback'],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogListInfiniteAction
  > =>
  async (dispatch, getState) => {
    dispatch<CatalogListInfiniteActionRequest>({
      type: ActionTypes.CATALOG_LIST_INFINITE_REQUEST,
    });

    try {
      const res = await axios.get<Response<Catalog[]>>(
        `${apiConfig.url}/catalogs`,
        {
          params: {
            limit: params?.limit,
            page: params?.page,
            search: {
              query: params?.search,
              fields: 'name',
            },
          },
          cancelToken: new axiosInstance.CancelToken(c => {
            callback?.(c);
          }),
        },
      );

      const { data, meta } = res.data;

      dispatch<CatalogListInfiniteActionSuccess>({
        type: ActionTypes.CATALOG_LIST_INFINITE_SUCCESS,
        payload: [...getState().catalogListInfinite.catalogs, ...data],
        hasMore: Boolean(meta && params?.page && meta.last_page > params.page),
        meta,
      });
    } catch (e) {
      if (axiosInstance.isCancel(e)) return;

      dispatch<CatalogListInfiniteActionFail>({
        type: ActionTypes.CATALOG_LIST_INFINITE_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCatalogListInfiniteReset =
  (): CatalogListInfiniteActionReset => ({
    type: ActionTypes.CATALOG_LIST_INFINITE_RESET,
  });
