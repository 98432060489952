import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  TopUpListAction,
  TopUpUpdateAction,
  TopUpUpdateSuccessAction,
} from 'src/actions';
import { TopUp } from 'src/types/topup';

interface TopUpUpdateInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface TopUpUpdateState extends TopUpUpdateInitialState {
  topUp?: TopUp;
}

export const topUpUpdateReducer: Reducer<
  TopUpUpdateState,
  TopUpUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.TOPUP_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.TOPUP_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        topUp: action.payload,
        success: true,
      };
    case ActionTypes.TOPUP_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.TOPUP_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface TopUpListState extends TopUpUpdateInitialState {
  topUps: TopUp[];
}

export const topUpListReducer: Reducer<
  TopUpListState,
  TopUpListAction | TopUpUpdateSuccessAction
> = (state = { topUps: [] }, action) => {
  switch (action.type) {
    case ActionTypes.TOPUP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.TOPUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        topUps: action.payload,
      };
    case ActionTypes.TOPUP_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.TOPUP_UPDATE_SUCCESS:
      return {
        ...state,
        topUps: [...state.topUps, action.payload],
      };
    case ActionTypes.TOPUP_LIST_RESET:
      return { topUps: [] };
    default:
      return state;
  }
};
