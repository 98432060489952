import { z } from 'zod';

import { countryValidator } from 'src/utils/validators/country-validator';

export const entityVatNumberValidator = z.object({
  id: z.number(),
  entity_id: z.number(),
  country_id: z.number(),
  number: z.string(),
  country: countryValidator,
});
