export const EMPLOYER_CREATE_REQUEST = 'EMPLOYER_CREATE_REQUEST';
export const EMPLOYER_CREATE_SUCCESS = 'EMPLOYER_CREATE_SUCCESS';
export const EMPLOYER_CREATE_FAIL = 'EMPLOYER_CREATE_FAIL';
export const EMPLOYER_CREATE_RESET = 'EMPLOYER_CREATE_RESET';

export const EMPLOYER_UPDATE_REQUEST = 'EMPLOYER_UPDATE_REQUEST';
export const EMPLOYER_UPDATE_SUCCESS = 'EMPLOYER_UPDATE_SUCCESS';
export const EMPLOYER_UPDATE_FAIL = 'EMPLOYER_UPDATE_FAIL';
export const EMPLOYER_UPDATE_RESET = 'EMPLOYER_UPDATE_RESET';

export const EMPLOYER_SETTINGS_UPDATE_REQUEST =
  'EMPLOYER_SETTINGS_UPDATE_REQUEST';
export const EMPLOYER_SETTINGS_UPDATE_SUCCESS =
  'EMPLOYER_SETTINGS_UPDATE_SUCCESS';
export const EMPLOYER_SETTINGS_UPDATE_FAIL = 'EMPLOYER_SETTINGS_UPDATE_FAIL';
export const EMPLOYER_SETTINGS_UPDATE_RESET = 'EMPLOYER_SETTINGS_UPDATE_RESET';

export const EMPLOYER_LIST_REQUEST = 'EMPLOYER_LIST_REQUEST';
export const EMPLOYER_LIST_SUCCESS = 'EMPLOYER_LIST_SUCCESS';
export const EMPLOYER_LIST_FAIL = 'EMPLOYER_LIST_FAIL';
export const EMPLOYER_LIST_RESET = 'EMPLOYER_LIST_RESET';

export const EMPLOYER_DETAILS_REQUEST = 'EMPLOYER_DETAILS_REQUEST';
export const EMPLOYER_DETAILS_SUCCESS = 'EMPLOYER_DETAILS_SUCCESS';
export const EMPLOYER_DETAILS_FAIL = 'EMPLOYER_DETAILS_FAIL';
export const EMPLOYER_DETAILS_RESET = 'EMPLOYER_DETAILS_RESET';
