import axiosInstance, { Canceler } from 'axios';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ColorListInfiniteAction,
  ColorListInfiniteActionFail,
  ColorListInfiniteActionRequest,
  ColorListInfiniteActionReset,
  ColorListInfiniteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Color } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface GetColorListInfiniteParams {
  params?: {
    limit?: number;
    page?: number;
    search?: string;
  };
  callback?: (cancel: Canceler) => void;
}

export const getColorListInfinite =
  (
    params?: GetColorListInfiniteParams['params'],
    callback?: GetColorListInfiniteParams['callback'],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    ColorListInfiniteAction
  > =>
  async (dispatch, getState) => {
    dispatch<ColorListInfiniteActionRequest>({
      type: ActionTypes.COLOR_LIST_INFINITE_REQUEST,
    });

    try {
      const res = await axios.get<Response<Color[]>>(
        `${apiConfig.url}/colors`,
        {
          params: {
            limit: params?.limit,
            page: params?.page,
            'filter[name]': params?.search ? params.search : undefined,
          },
          cancelToken: new axiosInstance.CancelToken(c => {
            callback?.(c);
          }),
        },
      );

      const { data, meta } = res.data;

      dispatch<ColorListInfiniteActionSuccess>({
        type: ActionTypes.COLOR_LIST_INFINITE_SUCCESS,
        payload: [...getState().colorListInfinite.colors, ...data],
        hasMore: Boolean(meta && params?.page && meta.last_page > params.page),
        meta,
      });
    } catch (e) {
      if (axiosInstance.isCancel(e)) return;

      dispatch<ColorListInfiniteActionFail>({
        type: ActionTypes.COLOR_LIST_INFINITE_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getColorListInfiniteReset = (): ColorListInfiniteActionReset => ({
  type: ActionTypes.COLOR_LIST_INFINITE_RESET,
});
