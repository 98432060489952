import { createSelector, Selector } from 'reselect';

import { IAutoCompleteExtendedOptions } from 'src/components/InputFields/Autocomplete/IAutoCompleteExtendedOptions';
import {
  employeeAssetListReducer,
  employeeBulkUploadReducer,
  employeeDeleteReducer,
  employeeListInfiniteReducer,
  employeeOffboardAssetListReducer,
  employeeCreateReducer,
  employeeListReducer,
} from 'src/reducers/employee-reducer';
import { RootState } from 'src/store';
import { Order, Product, ProductVariant } from 'src/types';
import { Meta } from 'src/types/response';

export const employeeDetailsSelector = createSelector(
  [(state: RootState) => state],
  state => state.employeeDetails,
);

export const employeeOrderListSelector = createSelector(
  [(state: RootState) => state],
  state => state.employeeOrderList,
);

export const employeeOrderProductListSelector: Selector<
  RootState,
  {
    loading?: boolean;
    employeeOrderProductList: {
      [key: string]: {
        order_number: string;
        id: string;
        products: {
          id?: Product['id'];
          image: string;
          order_id: Order['id'];
          name: string;
          quantity: number | string;
          variant_display: Product['variant_display'];
          variant?: null | ProductVariant;
          error?: string | null;
        }[];
      };
    };
  }
> = createSelector([(state: RootState) => state], state => {
  return {
    loading: state.employeeOrderList.loading,
    employeeOrderProductList: state.employeeOrderList.orders.reduce(
      (previousOrderValue, currentOrderValue) => {
        return {
          ...previousOrderValue,
          [currentOrderValue.id]: {
            order_number: currentOrderValue.number,
            id: currentOrderValue.id,
            products: currentOrderValue.products
              ?.map(product => ({
                id: product.id,
                image: product.images?.[0],
                name: product.name,
                order_id: currentOrderValue.id,
                quantity: product.order?.product_quantity,
                variant_display: product.variant_display,
                variant: product.order?.variant,
                can_be_offboarded: product.order?.variant
                  ? product.order.variant.can_be_offboarded
                  : product.can_be_offboarded,
              }))
              .filter(offboardProduct => offboardProduct.can_be_offboarded),
          },
        };
      },
      {},
    ),
  };
});

export const employeeAssetListSelector: Selector<
  RootState,
  ReturnType<typeof employeeAssetListReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeAssetList,
);

export const employeeOffboardAssetListSelector: Selector<
  RootState,
  ReturnType<typeof employeeOffboardAssetListReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeOffboardAssetList,
);

export const employeeDeleteSelector: Selector<
  RootState,
  ReturnType<typeof employeeDeleteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeDelete,
);

export const employeeBulkUploadSelector: Selector<
  RootState,
  ReturnType<typeof employeeBulkUploadReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeBulkUpload,
);

export const employeeCreateSelector: Selector<
  RootState,
  ReturnType<typeof employeeCreateReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeCreate,
);

export const employeeListInfiniteSelector: Selector<
  RootState,
  ReturnType<typeof employeeListInfiniteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employeeListInfinite,
);

export const employeeListForAutocompleteSelector: Selector<
  RootState,
  {
    loading?: boolean;
    success?: boolean;
    error?: string;
    meta?: Meta;
    hasMore?: boolean;
    employees: IAutoCompleteExtendedOptions[] | [];
  }
> = createSelector([(state: RootState) => state], state => {
  const employeeNewFormat: IAutoCompleteExtendedOptions[] = [];
  const data = state.employeeListInfinite.employees;

  for (let i = 0, len = data.length; i < len; i++) {
    const temp: IAutoCompleteExtendedOptions = {
      id: 0,
      name: '',
      others: { id: 0, name: '', phone: '' },
    };

    temp.id = data[i].id;
    temp.name = data[i].user?.display_name || '';
    temp.secondary = data[i].user?.email || '';
    temp.label = data[i].department?.name;

    if (temp.others) {
      temp.others.id = data[i].department_id || 0;
      temp.others.name = data[i].department?.name || '';
      temp.others.phone = data[i].user?.phone_number || '';
    }

    if (!employeeNewFormat.find(old => old.id === temp.id)) {
      employeeNewFormat.push(temp);
    }
  }

  return {
    loading: state.employeeListInfinite.loading,
    success: state.employeeListInfinite.success,
    error: state.employeeListInfinite.error,
    hasMore: state.employeeListInfinite.hasMore,
    meta: state.employeeListInfinite.meta,
    employees: employeeNewFormat,
  };
});

export const employeeUpdateelector = createSelector(
  [(state: RootState) => state],
  state => state.employeeUpdate,
);

export const employeeListSelector: Selector<
  RootState,
  ReturnType<typeof employeeListReducer>
> = createSelector([(state: RootState) => state], state => state.employeeList);
