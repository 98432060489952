import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  CatalogProductAddAction,
  CatalogProductInfiniteAction,
  CatalogProductListAction,
  CatalogProductRemoveAction,
} from 'src/actions';
import { InitialState, Product } from 'src/types';
import { Meta, Response } from 'src/types/response';

interface CatalogProductInfiniteState extends InitialState {
  products: Product[];
  meta?: Meta;
  hasMore?: boolean;
}

const catalogProductInfiniteInitialState: CatalogProductInfiniteState = {
  products: [],
  loading: true,
};

export const catalogProductInfiniteReducer: Reducer<
  CatalogProductInfiniteState,
  CatalogProductInfiniteAction
> = (state = catalogProductInfiniteInitialState, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_PRODUCT_INFINITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_PRODUCT_INFINITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        products: action.payload,
        meta: action.meta,
        hasMore: action.hasMore,
      };
    case ActionTypes.CATALOG_PRODUCT_INFINITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_PRODUCT_INFINITE_RESET:
      return catalogProductInfiniteInitialState;
    default:
      return state;
  }
};

type CatalogProductRemoveState = InitialState;

export const catalogProductRemoveReducer: Reducer<
  CatalogProductRemoveState,
  CatalogProductRemoveAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_PRODUCT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_PRODUCT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.CATALOG_PRODUCT_REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_PRODUCT_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

type CatalogProductAddState = InitialState;

export const catalogProductAddReducer: Reducer<
  CatalogProductAddState,
  CatalogProductAddAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_PRODUCT_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.CATALOG_PRODUCT_ADD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_PRODUCT_ADD_RESET:
      return {};
    default:
      return state;
  }
};

interface CatalogProductListState extends InitialState {
  products: Product[];
  meta?: Response['meta'];
}

const catalogProductListInitialState: CatalogProductListState = {
  products: [],
};

export const catalogProductListReducer: Reducer<
  CatalogProductListState,
  CatalogProductListAction
> = (state = catalogProductListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        products: action.payload,
        meta: action.meta,
      };
    case ActionTypes.CATALOG_PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_PRODUCT_LIST_RESET:
      return catalogProductListInitialState;
    default:
      return state;
  }
};
