import axiosClass, { Canceler } from 'axios';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogProductInfiniteAction,
  CatalogProductInfiniteActionFail,
  CatalogProductInfiniteActionRequest,
  CatalogProductInfiniteActionReset,
  CatalogProductInfiniteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface GetCatalogProductInfiniteParams {
  catalogId: string | number;
  params: {
    limit?: number;
    page?: number;
    search?: string;
    variants?: number;
    expand?: string;
    without_variants?: boolean;
  };
  callback?: (c: Canceler) => void;
}

export const getCatalogProductInfinite =
  (
    catalogId: GetCatalogProductInfiniteParams['catalogId'],
    params?: GetCatalogProductInfiniteParams['params'],
    callback?: GetCatalogProductInfiniteParams['callback'],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogProductInfiniteAction
  > =>
  async (dispatch, getState) => {
    dispatch<CatalogProductInfiniteActionRequest>({
      type: ActionTypes.CATALOG_PRODUCT_INFINITE_REQUEST,
    });

    try {
      const res = await axios.get<Response<Product[]>>(
        `${apiConfig.url}/catalogs/${catalogId}/products`,
        {
          cancelToken: new axiosClass.CancelToken(c => {
            callback?.(c);
          }),
          params: {
            expand: params?.expand,
            limit: params?.limit,
            page: params?.page,
            variants: params?.variants,
            without_variants: params?.without_variants,
            search: {
              query: params?.search,
              fields: 'name',
            },
          },
        },
      );

      const { data, meta } = res.data;

      dispatch<CatalogProductInfiniteActionSuccess>({
        type: ActionTypes.CATALOG_PRODUCT_INFINITE_SUCCESS,
        payload: [...getState().catalogProductInfinite.products, ...data],
        hasMore: Boolean(meta && params?.page && meta.last_page > params.page),
        meta,
      });
    } catch (e) {
      if (axiosClass.isCancel(e)) return;

      dispatch<CatalogProductInfiniteActionFail>({
        type: ActionTypes.CATALOG_PRODUCT_INFINITE_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCatalogProductInfiniteReset =
  (): CatalogProductInfiniteActionReset => ({
    type: ActionTypes.CATALOG_PRODUCT_INFINITE_RESET,
  });
