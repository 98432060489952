import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  OffboardDetailsAction,
  OffboardUpdateStatusAction,
  OffboardCreateAction,
  OffboardDeleteAction,
  OffboardListAction,
  OffboardListTypes,
} from 'src/actions';
import { Offboard } from 'src/types';

interface OffboardInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface OffboardListState extends OffboardInitialState {
  offboards: Offboard[];
  meta: Partial<OffboardListTypes['meta']>;
}

const offboardListInitialState: OffboardListState = {
  offboards: [],
  meta: {},
};

export const offboardsListReducer: Reducer<
  OffboardListState,
  OffboardListAction
> = (state = offboardListInitialState, action): OffboardListState => {
  switch (action.type) {
    case ActionTypes.OFFBOARD_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFBOARD_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        offboards: action.payload,
        meta: action.meta,
      };
    case ActionTypes.OFFBOARD_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.OFFBOARD_LIST_RESET:
      return offboardListInitialState;
    default:
      return state;
  }
};

interface OffboardDetailsState extends OffboardInitialState {
  offboard?: Offboard;
}

export const offboardDetailsReducer: Reducer<
  OffboardDetailsState,
  OffboardDetailsAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.OFFBOARD_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFBOARD_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        offboard: action.payload,
      };
    case ActionTypes.OFFBOARD_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.OFFBOARD_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};
interface OffboardCreateState extends OffboardInitialState {
  offboard?: Offboard;
}

export const offboardCreateReducer: Reducer<
  OffboardCreateState,
  OffboardCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.OFFBOARD_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFBOARD_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        offboard: action.payload,
      };
    case ActionTypes.OFFBOARD_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.OFFBOARD_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const offboardUpdateStatusReducer: Reducer<
  OffboardDetailsState,
  OffboardUpdateStatusAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.OFFBOARD_UPDATE_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFBOARD_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        offboard: action.payload,
      };
    case ActionTypes.OFFBOARD_UPDATE_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.OFFBOARD_UPDATE_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const offboardDeleteReducer: Reducer<
  OffboardInitialState,
  OffboardDeleteAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.OFFBOARD_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.OFFBOARD_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.OFFBOARD_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.OFFBOARD_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
