export const CART_OPEN_DRAWER = 'CART_OPEN_DRAWER';

export const CART_PRODUCT_LIST_REQUEST = 'CART_PRODUCT_LIST_REQUEST';
export const CART_PRODUCT_LIST_SUCCESS = 'CART_PRODUCT_LIST_SUCCESS';
export const CART_PRODUCT_LIST_FAIL = 'CART_PRODUCT_LIST_FAIL';
export const CART_PRODUCT_LIST_RESET = 'CART_PRODUCT_LIST_RESET';

export const CART_PRODUCT_REMOVE_REQUEST = 'CART_PRODUCT_REMOVE_REQUEST';
export const CART_PRODUCT_REMOVE_SUCCESS = 'CART_PRODUCT_REMOVE_SUCCESS';
export const CART_PRODUCT_REMOVE_FAIL = 'CART_PRODUCT_REMOVE_FAIL';
export const CART_PRODUCT_REMOVE_RESET = 'CART_PRODUCT_REMOVE_RESET';

export const CART_PRODUCT_ADD_REQUEST = 'CART_PRODUCT_ADD_REQUEST';
export const CART_PRODUCT_ADD_SUCCESS = 'CART_PRODUCT_ADD_SUCCESS';
export const CART_PRODUCT_ADD_FAIL = 'CART_PRODUCT_ADD_FAIL';
export const CART_PRODUCT_ADD_RESET = 'CART_PRODUCT_ADD_RESET';
