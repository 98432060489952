import { FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useUserProfile from 'src/modules/user/hooks/useUserProfile';

interface EmployeeGuardProps {
  children?: ReactNode;
}

const EmployeeGuard: FC<EmployeeGuardProps> = ({ children }) => {
  const { data: user, isLoading: loading } = useUserProfile();

  if (user?.role.name === 'admin' && !loading) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

EmployeeGuard.propTypes = {
  children: PropTypes.node,
};

export default EmployeeGuard;
