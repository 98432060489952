import { Reducer } from 'redux';

import {
  ADDRESS_UPDATE_FAIL,
  ADDRESS_UPDATE_REQUEST,
  ADDRESS_UPDATE_RESET,
  ADDRESS_UPDATE_SUCCESS,
  ADDRESS_CREATE_FAIL,
  ADDRESS_CREATE_REQUEST,
  ADDRESS_CREATE_RESET,
  ADDRESS_CREATE_SUCCESS,
} from 'src/action-types';
import { AddressUpdateAction, AddressCreateAction } from 'src/actions';
import { Address } from 'src/types';

interface AddressInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface AddressUpdateState extends AddressInitialState {
  address?: Address;
}

export const addressUpdateReducer: Reducer<
  AddressUpdateState,
  AddressUpdateAction
> = (state = {}, action): AddressUpdateState => {
  switch (action.type) {
    case ADDRESS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDRESS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        address: action.payload,
      };
    case ADDRESS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ADDRESS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface AddressCreateState extends AddressInitialState {
  address?: Address;
}

export const addressCreateReducer: Reducer<
  AddressCreateState,
  AddressCreateAction
> = (state = {}, action): AddressCreateState => {
  switch (action.type) {
    case ADDRESS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADDRESS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        address: action.payload,
      };
    case ADDRESS_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ADDRESS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
