import { createContext, FC, useCallback, useEffect, useState } from 'react';

import { apiConfig } from 'src/config';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { Response } from 'src/types/response';
import axiosInstance from 'src/utils/axios';

interface FeatureToggleState {
  [key: string]: boolean;
}

export const FeatureToggleContext = createContext<Partial<FeatureToggleState>>(
  {},
);

export const FeatureToggleProvider: FC = ({ children }) => {
  const [features, setFeatures] = useState<FeatureToggleState>({});
  const { data: user } = useUserProfile();

  const getAllFeatures = useCallback(async () => {
    try {
      const res = await axiosInstance.get<Response<FeatureToggleState>>(
        `${apiConfig.url}/features`,
      );

      const { data } = res.data;

      setFeatures(data);
    } catch (e) {
      setFeatures({});
    }
  }, []);

  useEffect(() => {
    if (user) {
      getAllFeatures();
    }
  }, [user]);

  return (
    <FeatureToggleContext.Provider value={features}>
      {children}
    </FeatureToggleContext.Provider>
  );
};
