export const AUTH_USER_WELCOME_MESSAGE = 'AUTH_USER_WELCOME_MESSAGE';

export const AUTH_CHANGE_PASSWORD_REQUEST = 'AUTH_CHANGE_PASSWORD_REQUEST';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAIL = 'AUTH_CHANGE_PASSWORD_FAIL';
export const AUTH_CHANGE_PASSWORD_RESET = 'AUTH_CHANGE_PASSWORD_RESET';

export const AUTH_USER_UPLOAD_PHOTO_REQUEST = 'AUTH_USER_UPLOAD_PHOTO_REQUEST';
export const AUTH_USER_UPLOAD_PHOTO_SUCCESS = 'AUTH_USER_UPLOAD_PHOTO_SUCCESS';
export const AUTH_USER_UPLOAD_PHOTO_FAIL = 'AUTH_USER_UPLOAD_PHOTO_FAIL';
export const AUTH_USER_UPLOAD_PHOTO_RESET = 'AUTH_USER_UPLOAD_PHOTO_RESET';
