// TODO: type any will be included in the ban-types soon. no-explicit-any
/* eslint-disable @typescript-eslint/no-explicit-any */
import { colors, responsiveFontSizes } from '@material-ui/core';
import {
  createTheme as createMuiTheme,
  Theme as MuiTheme,
} from '@material-ui/core/styles';
import {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground,
} from '@material-ui/core/styles/createPalette';
import { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import _ from 'lodash';

import { THEMES } from 'src/constants';
import { WorkwizeThemeV1 } from 'src/types';

import { softShadows, strongShadows } from './shadows';
import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
  workwizeTheme: WorkwizeThemeV1;
}

type Direction = 'ltr' | 'rtl';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiFormLabel: {
      root: {
        textWrap: 'nowrap',
        width: '80%',
        overflowX: 'clip',
        textOverflow: 'ellipsis',
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: '#227AF7',
        white: '#fff',
      },
      secondary: {
        main: '#223B76',
      },
      green: {
        main: '#20D599',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: '#7057ff',
      },
      secondary: {
        main: '#8a85ff',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d',
      },
      primary: {
        main: '#a67dff',
      },
      secondary: {
        main: '#a67dff',
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4',
      },
    },
    shadows: strongShadows,
  },
];

const workwizeOptions = {
  workwizeTheme: {
    background: {
      background: '#f4f6f8',
      paper: '#ffffff',
    },
    topBar: {
      background: '#7057ff',
      nameText: '#ffffff',
    },
    navBar: {
      background: '#ffffff',
      mainText: '#227AF7',
      nameText: '#7057ff',
      menu: '#546e7a',
      activeMenu: '#7057ff',
      budgetContainerFull: '#4caf5014',
      budgetContainerEmpty: '#af4c4c14',
      budgetTextFull: '#4caf50',
      budgetTextEmpty: '#af4c4c',
      helpContainerButton: '#7057ff',
      helpTextButton: '#ffffff',
    },
    miniCart: {
      background: '#FFFFFF',
      mainText: '#000000',
      secondary: '#546e7a',
      mainButton: '#7057ff',
      mainButtonText: '#FFFFFF',
      secondaryButton: '#e0e0e0',
      secondaryButtonText: '#000000de',
    },
    icons: {
      cart: '#FFFFFF',
      delete: '#227AF7',
      arrow: '#546e7a',
      edit: '#546e7a',
    },

    forms: {
      inputText: '#000000',
      inputLabel: '#546e7a',
      inputLabelFocused: '#7057ff',
      inputBorder: '#0000003b',
      inputBorderFocused: '#7057ff',
      inputBorderError: '#f44336',
      inputErrorText: '#f44336',
      inputBorderDisabled: '#00000042',
      inputTextDisabled: '#00000061',
      inputLabelDisabled: '#00000061',
      inputBorderHover: '#00000042',
    },

    defaults: {
      headerText: '#000000',
      breadCrumbs: '#546e7a',
      breadCrumbHighlight: '#227AF7',
      mainText: '#000000',
      secondaryText: '#546E7A',
      checkoutSuccessMainText: '#227AF7',
      checkoutSuccessSecondaryText: '#227AF7',
      greenLabelContainer: '#43a04714',
      greenLabelText: '#43a047',
      orangeLabelContainer: '#ffa00014',
      orangeLabelText: '#ffa000',
      redLabelContainer: '#d32f2f14',
      redLabelText: '#d32f2f',
      blueTextButton: '#ffffff',
      blueButton: '#7057ff',
      redTextButton: '#ffffff',
      redButton: '#E53935',
      greenButtonText: '#ffffff',
      greenButton: '#4caf50',
      grayButton: '#e0e0e0',
      grayButtonText: '#227AF7',
      whiteButton: '#ffffff',
      whiteButtonText: '#227AF7',
      textButton: '#227AF7',
      tickCheckout: '#ffffff',
      divider: '#0000001f',
    },
    logo: '/static/workwize.svg',
    profile_image: '/static/workwize.svg',
  },
};

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge({}, baseOptions, themeOptions, workwizeOptions, {
      direction: config.direction,
    }),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
};
