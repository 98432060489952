import { z } from 'zod';

import { CurrencyCodeEnum } from 'src/utils/validators/currency-validator';

export const BudgetTypeEnum = z.enum(['purchase', 'rental']);

export const budgetValidator = z.object({
  id: z.number(),
  amount: z.number(),
  formatted_amount: z.string(),
  currency: CurrencyCodeEnum,
  icon: z.string().nullable(),
  name: z.string().nullable(),
  type: BudgetTypeEnum,
});
