export const INVITATION_RESEND_UNACCEPTED_REQUEST =
  'INVITATION_RESEND_UNACCEPTED_REQUEST';
export const INVITATION_RESEND_UNACCEPTED_SUCCESS =
  'INVITATION_RESEND_UNACCEPTED_SUCCESS';
export const INVITATION_RESEND_UNACCEPTED_FAIL =
  'INVITATION_RESEND_UNACCEPTED_FAIL';
export const INVITATION_RESEND_UNACCEPTED_RESET =
  'INVITATION_RESEND_UNACCEPTED_RESET';
export const INVITATION_RESEND_SINGLE_EMPLOYEE_REQUEST =
  'INVITATION_RESEND_SINGLE_EMPLOYEE_REQUEST';
export const INVITATION_RESEND_SINGLE_EMPLOYEE_SUCCESS =
  'INVITATION_RESEND_SINGLE_EMPLOYEE_SUCCESS';
export const INVITATION_RESEND_SINGLE_EMPLOYEE_FAIL =
  'INVITATION_RESEND_SINGLE_EMPLOYEE_FAIL';
export const INVITATION_RESEND_SINGLE_EMPLOYEE_RESET =
  'INVITATION_RESEND_SINGLE_EMPLOYEE_RESET';
