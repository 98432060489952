import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployerListActionSuccess,
  EmployerSettingsUpdateAction,
  EmployerSettingsUpdateActionFail,
  EmployerSettingsUpdateActionRequest,
  EmployerSettingsUpdateActionReset,
  EmployerSettingsUpdateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employer } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const updateEmployerSettings =
  (
    employerId: string | number,
    settingsData: { setting_id: number | string; order_by?: string },
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerSettingsUpdateAction | EmployerListActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<EmployerSettingsUpdateActionRequest>({
      type: ActionTypes.EMPLOYER_SETTINGS_UPDATE_REQUEST,
    });

    try {
      const res = await axios.put<Response<Employer>>(
        `${apiConfig.url}/employer/${employerId}/settings`,
        settingsData,
      );

      const { data } = res.data;

      dispatch<EmployerSettingsUpdateActionSuccess>({
        type: ActionTypes.EMPLOYER_SETTINGS_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch<EmployerListActionSuccess>({
        type: ActionTypes.EMPLOYER_LIST_SUCCESS,
        payload: getState().employerList.employers.map(employer =>
          employer.id === data.id
            ? { ...employer, settings: data.settings }
            : employer,
        ),
        meta: getState().employerList.meta,
      });

      enqueueSnackbar("Employer's settings successfully updated", {
        variant: 'success',
      });
    } catch (err) {
      dispatch<EmployerSettingsUpdateActionFail>({
        type: ActionTypes.EMPLOYER_SETTINGS_UPDATE_FAIL,
        payload: 'Unable to update settings',
      });

      enqueueSnackbar('Unable to update settings', {
        variant: 'error',
      });
    }
  };

export const updateEmployerSettingsReset =
  (): EmployerSettingsUpdateActionReset => ({
    type: ActionTypes.EMPLOYER_SETTINGS_UPDATE_RESET,
  });
