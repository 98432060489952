import { Reducer } from 'redux';

import {
  CATALOG_COUNTRY_GET_FAIL,
  CATALOG_COUNTRY_GET_REQUEST,
  CATALOG_COUNTRY_GET_SUCCESS,
} from 'src/action-types/catalog-country-types';
import { CatalogCountryGetAction } from 'src/actions/catalog-country-action';
import { Country } from 'src/types';

interface CatalogCountryInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface CatalogCountryGetState extends CatalogCountryInitialState {
  countries: Country[];
}

export const catalogCountryGetReducer: Reducer<
  CatalogCountryGetState,
  CatalogCountryGetAction
> = (state = { countries: [] }, action): CatalogCountryGetState => {
  switch (action.type) {
    case CATALOG_COUNTRY_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CATALOG_COUNTRY_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        countries: action.payload,
      };
    case CATALOG_COUNTRY_GET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
