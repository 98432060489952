import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardAvgOrderActionFail,
  GetDashboardAvgOrderActionList,
  GetDashboardAvgOrderActionRequest,
  GetDashboardAvgOrderActionSuccess,
  GetDashboardEmployeeCountActionFail,
  GetDashboardEmployeeCountActionList,
  GetDashboardEmployeeCountActionRequest,
  GetDashboardEmployeeCountActionSuccess,
  GetDashboardOrderByDepartmentActionFail,
  GetDashboardOrderByDepartmentActionList,
  GetDashboardOrderByDepartmentActionRequest,
  GetDashboardOrderByDepartmentActionSuccess,
  GetDashboardOrderByMonthActionFail,
  GetDashboardOrderByMonthActionList,
  GetDashboardOrderByMonthActionRequest,
  GetDashboardOrderByMonthActionSuccess,
  GetDashboardTotalProductsOrderedActionFail,
  GetDashboardTotalProductsOrderedActionList,
  GetDashboardTotalProductsOrderedActionRequest,
  GetDashboardTotalProductsOrderedActionSuccess,
  GetDashboardTotalSpentActionList,
  GetDashboardTotalSpentActionFail,
  GetDashboardTotalSpentActionRequest,
  GetDashboardTotalSpentActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import {
  DashboardAvgOrder,
  DashboardOrderByDepartment,
  DashboardOrderByMonth,
  DashboardTotalSpent,
} from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardData =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    | GetDashboardAvgOrderActionList
    | GetDashboardEmployeeCountActionList
    | GetDashboardOrderByDepartmentActionList
    | GetDashboardOrderByMonthActionList
    | GetDashboardTotalProductsOrderedActionList
    | GetDashboardTotalSpentActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardAvgOrderActionRequest>({
      type: ActionTypes.GET_AVG_ORDER_REQUEST,
    });

    dispatch<GetDashboardEmployeeCountActionRequest>({
      type: ActionTypes.GET_EMPLOYEES_COUNT_REQUEST,
    });

    dispatch<GetDashboardOrderByMonthActionRequest>({
      type: ActionTypes.GET_ORDERS_BY_MONTH_REQUEST,
    });

    dispatch<GetDashboardOrderByDepartmentActionRequest>({
      type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_REQUEST,
    });

    dispatch<GetDashboardTotalSpentActionRequest>({
      type: ActionTypes.GET_TOTAL_SPENT_REQUEST,
    });

    dispatch<GetDashboardTotalProductsOrderedActionRequest>({
      type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardTotalSpent }>(
        `${apiConfig.url}/total-spent`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardTotalSpentActionSuccess>({
        type: ActionTypes.GET_TOTAL_SPENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardTotalSpentActionFail>({
        type: ActionTypes.GET_TOTAL_SPENT_FAIL,
        payload: 'Something Went Wrong while Fetching Total Spent',
      });
    }

    try {
      const res = await axios.get<{ data: number }>(
        `${apiConfig.url}/new-employees`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardEmployeeCountActionSuccess>({
        type: ActionTypes.GET_EMPLOYEES_COUNT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardEmployeeCountActionFail>({
        type: ActionTypes.GET_EMPLOYEES_COUNT_FAIL,
        payload: 'Something Went Wrong while Fetching New Users Count',
      });
    }

    try {
      const res = await axios.get<{ data: number }>(
        `${apiConfig.url}/total-products-ordered`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardTotalProductsOrderedActionSuccess>({
        type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardTotalProductsOrderedActionFail>({
        type: ActionTypes.GET_TOTAL_PRODUCTS_ORDERED_FAIL,
        payload: 'Something Went Wrong while Fetching Total Products Ordered',
      });
    }

    try {
      const res = await axios.get<{ data: DashboardAvgOrder }>(
        `${apiConfig.url}/avg-order`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardAvgOrderActionSuccess>({
        type: ActionTypes.GET_AVG_ORDER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardAvgOrderActionFail>({
        type: ActionTypes.GET_AVG_ORDER_FAIL,
        payload: 'Something Went Wrong While Fetching Average Orders',
      });
    }

    try {
      const res = await axios.get<{ data: DashboardOrderByMonth }>(
        `${apiConfig.url}/orders-by-month`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardOrderByMonthActionSuccess>({
        type: ActionTypes.GET_ORDERS_BY_MONTH_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardOrderByMonthActionFail>({
        type: ActionTypes.GET_ORDERS_BY_MONTH_FAIL,
        payload: 'Something Went Wrong while Fetching Monthly Orders',
      });
    }

    try {
      const res = await axios.get<{ data: DashboardOrderByDepartment }>(
        `${apiConfig.url}/orders-by-department`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardOrderByDepartmentActionSuccess>({
        type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardOrderByDepartmentActionFail>({
        type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_FAIL,
        payload: 'Something Went Wrong while Fetching Orders by Department',
      });
    }
  };
