import { FC } from 'react';

import { Box, LinearProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { Theme } from 'src/theme';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      alignItems: 'center',
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      padding: theme.spacing(3),
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 2000,
    },
  }),
);

const SlashScreen: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box width={400}>
        <LinearProgress />
      </Box>
    </div>
  );
};

export default SlashScreen;
