import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const addressUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.addressUpdate,
);

export const addressCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.addressCreate,
);
