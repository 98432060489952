export const CURRENCIES = [
  'aed',
  'aud',
  'cad',
  'chf',
  'cny',
  'credit',
  'egp',
  'eur',
  'gbp',
  'hkd',
  'idr',
  'ils',
  'inr',
  'isk',
  'jpy',
  'krw',
  'myr',
  'ngn',
  'nzd',
  'pkr',
  'pound',
  'rsd',
  'sar',
  'sgd',
  'try',
  'uah',
  'usd',
  'vnd',
  'xcr',
  'zar',
] as const;

// TODO pound should be gbp according to iso standards (https://en.wikipedia.org/wiki/ISO_4217)
export type CurrencyType = (typeof CURRENCIES)[number];

export type PaymentPeriodType = 'one-time' | 'monthly' | 'quarterly' | 'yearly';

export interface Price {
  cost_currency: CurrencyType;
  cost_payment_end_date: string | null;
  cost_payment_period: PaymentPeriodType;
  cost_price: string;
  cost_vat_level: string;
  id?: string | number;
  invoice_currency: CurrencyType;
  invoice_payment_end_date: string | null;
  invoice_payment_period: PaymentPeriodType;
  invoice_price: string;
  sell_currency: CurrencyType;
  sell_payment_end_date: string | null;
  sell_payment_period: PaymentPeriodType;
  sell_price: string;
  sell_vat_level: string;
  sell_price_raw: number;
}
