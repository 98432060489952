import { FC, useState } from 'react';

import {
  Avatar,
  Box,
  BoxProps,
  Button,
  Collapse,
  Divider,
} from '@material-ui/core';
import { ChevronDown, ChevronUp } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { SettingsIcon } from 'src/components/Icons/navBar';
import LogoutIcon from 'src/components/Icons/navBar/LogoutIcon';
import SwitchIcon from 'src/components/Icons/navBar/SwitchIcon';
import { isEmployer } from 'src/modules/user/utils/roles';
import { User, colorSchemeV2 } from 'src/types';
import { RoleName } from 'src/types/role-name';

interface SettingsBlockProps {
  user: User;
  nextRole: RoleName;
  handleSwitchRole: () => void;
  handleLogout: () => void;
  colorScheme?: {
    expandedBackground: string;
    collapsedBackground: string;
    hover: string;
    textPrimary: string;
  };
}

const SettingsBlock: FC<SettingsBlockProps> = ({
  user,
  nextRole,
  handleSwitchRole,
  handleLogout,
  colorScheme,
}) => {
  const history = useHistory();
  const { t } = useTranslation(['navItems']);

  const [isOpen, setOpen] = useState<boolean>(false);

  return (
    <Collapse in={isOpen} collapsedSize={80}>
      <Box
        style={{
          margin: 'auto 15px 15px 15px',
          borderRadius: '10px',
          background:
            colorScheme?.expandedBackground ?? colorSchemeV2.primaryDarker,
        }}
      >
        <Box
          data-testid="settings-block"
          onClick={() => {
            setOpen(!isOpen);
          }}
          sx={
            {
              display: 'flex',
              flexDirection: 'row',
              padding: '15px 15px 15px 15px',
              alignItems: 'center',
              borderRadius: '10px',
              background: isOpen
                ? colorScheme?.expandedBackground
                : colorScheme?.collapsedBackground ??
                  colorSchemeV2.primaryDarker,
              transition: '0.2s',
              cursor: 'pointer',
              '&:hover': {
                background: colorScheme?.hover ?? colorSchemeV2.primary,
              },
            } as BoxProps
          }
        >
          <Avatar alt="User profile picture" src={user.profilePictureUrl} />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
            }}
          >
            <Box
              style={{
                fontWeight: 500,
                fontFamily: "'Roboto', 'Helvetice', 'Arial', 'sans-serif'",
                fontSize: '0.9rem',
              }}
            >
              {user.name}
            </Box>
            <Box
              style={{
                fontSize: '0.7rem',
                fontFamily: "'Roboto', 'Helvetice', 'Arial', 'sans-serif'",
              }}
            >
              as {user.role.name}
            </Box>
          </Box>
          <Box
            style={{ cursor: 'pointer', marginLeft: 'auto' }}
            onClick={() => setOpen(!isOpen)}
          >
            {!isOpen ? (
              <ChevronUp id="open-settings" size={22} />
            ) : (
              <ChevronDown size={22} />
            )}
          </Box>
        </Box>
        {isOpen && <Divider />}
        {isOpen && (
          <Box
            style={{
              padding: '15px 15px 15px 28px',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              alignItems: 'flex-start',
              color: colorScheme?.textPrimary ?? colorSchemeV2.textLight,
            }}
          >
            {isEmployer(user.original_role.name) && (
              <Button
                variant="text"
                onClick={handleSwitchRole}
                startIcon={<SwitchIcon />}
                style={{
                  fontFamily: "'Roboto', 'Helvetice', 'Arial', 'sans-serif'",
                  textTransform: 'none',
                  color: 'inherit',
                }}
              >
                Switch to {nextRole}
              </Button>
            )}
            <Button
              variant="text"
              onClick={() =>
                isEmployer(user.role.name)
                  ? history.push('/app/account/settings')
                  : history.push('/app/account')
              }
              startIcon={<SettingsIcon />}
              style={{
                fontFamily: "'Roboto', 'Helvetice', 'Arial', 'sans-serif'",
                textTransform: 'none',
                color: 'inherit',
              }}
            >
              {t('navItems:settings')}
            </Button>
            <Button
              variant="text"
              onClick={handleLogout}
              startIcon={<LogoutIcon />}
              style={{
                fontFamily: "'Roboto', 'Helvetice', 'Arial', 'sans-serif'",
                textTransform: 'none',
                color: 'inherit',
              }}
            >
              Logout
            </Button>
          </Box>
        )}
      </Box>
    </Collapse>
  );
};

export default SettingsBlock;
