import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployerCreateAction,
  EmployerCreateActionFail,
  EmployerCreateActionRequest,
  EmployerCreateActionSuccess,
  EmployerCreateType,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { EmployerCreateFormikValues } from 'src/types/formikValues';
import axios, { ErrorResponse } from 'src/utils/axios';

import { createEmployerAnnouncement } from '../employer-announcement-action-creators';

export const createEmployer =
  (
    employerData: EmployerCreateFormikValues,
    formikHelpers?: FormikHelpers<EmployerCreateFormikValues>,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployerCreateAction> =>
  async dispatch => {
    dispatch<EmployerCreateActionRequest>({
      type: ActionTypes.EMPLOYER_CREATE_REQUEST,
    });

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { announcement_message, message_type, ...restEmployerData } =
      employerData;

    try {
      const res = await axios.post<{ data: EmployerCreateType }>(
        `${apiConfig.url}/user/invite`,
        { ...restEmployerData },
      );

      const { data } = res.data;

      if (data.created_user && res.status && employerData.announcement) {
        dispatch(
          createEmployerAnnouncement(data.created_user.toString(), {
            message: announcement_message,
            message_type,
          }),
        );
      }

      dispatch<EmployerCreateActionSuccess>({
        type: ActionTypes.EMPLOYER_CREATE_SUCCESS,
        payload: data.success,
      });

      enqueueSnackbar(data.success, {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (formikHelpers && error) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);
        }

        if (e.response?.status === 422) {
          enqueueSnackbar('Invalid input values', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Server Error', {
            variant: 'error',
          });
        }
        dispatch<EmployerCreateActionFail>({
          type: ActionTypes.EMPLOYER_CREATE_FAIL,
          payload: 'Unable to create an employer',
        });
      }
    }
  };
