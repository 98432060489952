import { analytic } from 'src/utils/analytic';

const getEmployeeListAnalytics = (search?: string) => {
  analytic.track('Employee Management Viewed');

  if (search !== '') {
    analytic.track('Employee Searched', {
      search,
    });
  }
};

export default getEmployeeListAnalytics;
