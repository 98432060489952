import { SVGProps } from 'react';

const CheckVerifiedIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  fill = 'none',
  ...rest
}: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    {...rest}
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m5.625 7.25 1.25 1.25 2.813-2.813M5.75 12.627a.776.776 0 0 1 .575.154l.752.577c.249.19.595.19.842 0l.78-.6a.69.69 0 0 1 .513-.136l.976.128c.31.041.61-.132.73-.421l.375-.909a.69.69 0 0 1 .375-.375l.909-.375c.29-.12.462-.42.421-.73l-.123-.941a.776.776 0 0 1 .154-.575l.577-.752a.691.691 0 0 0 0-.843l-.6-.78a.69.69 0 0 1-.136-.512l.128-.976a.692.692 0 0 0-.421-.73l-.909-.376a.69.69 0 0 1-.375-.375l-.375-.908a.692.692 0 0 0-.73-.422l-.976.129a.687.687 0 0 1-.511-.135l-.78-.6a.69.69 0 0 0-.843 0l-.78.6a.697.697 0 0 1-.512.136l-.977-.128a.692.692 0 0 0-.73.421l-.374.909a.698.698 0 0 1-.375.375l-.909.375a.692.692 0 0 0-.421.73l.128.976a.694.694 0 0 1-.137.511l-.599.78a.69.69 0 0 0 0 .843l.6.78a.69.69 0 0 1 .136.512l-.128.977c-.041.31.132.61.421.73l.909.375a.69.69 0 0 1 .375.375l.375.908c.12.29.42.463.73.422l.94-.124Z"
    />
  </svg>
);

export default CheckVerifiedIcon;
