import { createStyles, makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export default makeStyles((theme: Theme) =>
  createStyles({
    badge: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.workwizeTheme.navBar.menu,
      width: 20,
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: theme.shape.borderRadius,
    },
  }),
);
