import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductCreateAction,
  ProductCreateActionFail,
  ProductCreateActionRequest,
  ProductCreateActionReset,
  ProductCreateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios, { ErrorResponse } from 'src/utils/axios';

export const createProduct =
  (
    productData: FormData,
  ): ThunkAction<Promise<void>, RootState, undefined, ProductCreateAction> =>
  async dispatch => {
    dispatch<ProductCreateActionRequest>({
      type: ActionTypes.PRODUCT_CREATE_REQUEST,
    });

    try {
      const res = await axios.post<Response<Product>>(
        `${apiConfig.url}/products`,
        productData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );

      const { data } = res.data;

      dispatch<ProductCreateActionSuccess>({
        type: ActionTypes.PRODUCT_CREATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Product successfully created', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const err = e.response?.data;
        const errors: Record<string, unknown> = {};

        if (err?.errors) {
          err.errors.forEach(error => {
            errors[error.field] = error.errors[0];
          });
        }

        dispatch<ProductCreateActionFail>({
          type: ActionTypes.PRODUCT_CREATE_FAIL,
          payload: errors,
        });
      }
      enqueueSnackbar('Validation errors', {
        variant: 'error',
      });
    }
  };

export const createProductReset = (): ProductCreateActionReset => ({
  type: ActionTypes.PRODUCT_CREATE_RESET,
});
