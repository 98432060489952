import { SVGProps } from 'react';

const InfoIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14ZM7.7 9.8C7.7 9.98565 7.62625 10.1637 7.49497 10.295C7.3637 10.4262 7.18565 10.5 7 10.5C6.81435 10.5 6.6363 10.4262 6.50503 10.295C6.37375 10.1637 6.3 9.98565 6.3 9.8V7C6.3 6.81435 6.37375 6.6363 6.50503 6.50503C6.6363 6.37375 6.81435 6.3 7 6.3C7.18565 6.3 7.3637 6.37375 7.49497 6.50503C7.62625 6.6363 7.7 6.81435 7.7 7V9.8ZM7 3.5C6.81435 3.5 6.6363 3.57375 6.50503 3.70503C6.37375 3.8363 6.3 4.01435 6.3 4.2C6.3 4.38565 6.37375 4.5637 6.50503 4.69497C6.6363 4.82625 6.81435 4.9 7 4.9C7.18565 4.9 7.3637 4.82625 7.49497 4.69497C7.62625 4.5637 7.7 4.38565 7.7 4.2C7.7 4.01435 7.62625 3.8363 7.49497 3.70503C7.3637 3.57375 7.18565 3.5 7 3.5Z"
      fill={color}
    />
  </svg>
);

export default InfoIcon;
