import { createStyles, makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneInputContainer: {
      width: '100% !important',
      '& .special-label': {
        left: '8px !important',
      },
    },
    phoneInputFormControl: {
      width: '100% !important',
      borderRadius: '4px !important',
      border:
        '1px solid ' + theme.workwizeTheme.forms.inputBorder + ' !important',
      '&:hover': {
        borderColor: theme.workwizeTheme.forms.inputBorderHover + ' !important',
        borderWidth: '1px !important',
      },
      '&:focus': {
        borderColor:
          theme.workwizeTheme.forms.inputBorderFocused + ' !important',
        borderWidth: '2px !important',
        boxShadow: 'none !important',
      },
      '&.error': {
        borderColor: '#d32f2f !important',
      },
    },
    phoneInputError: {
      color: '#d32f2f',
      fontFamily:
        '"Hanken Grotesk", "Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      textAlign: 'left',
      margin: '3px 14px 0',
    },
  }),
);
