import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeAssetListAction,
  EmployeeAssetListActionFail,
  EmployeeAssetListActionRequest,
  EmployeeAssetListActionReset,
  EmployeeAssetListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Asset } from 'src/types';
import axios from 'src/utils/axios';

export const getEmployeeAssetList =
  (
    employeeId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeAssetListAction
  > =>
  async dispatch => {
    dispatch<EmployeeAssetListActionRequest>({
      type: ActionTypes.EMPLOYEE_ASSET_LIST_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Asset[] }>(
        `${apiConfig.url}/employees/${employeeId}/assets`,
        {
          params: {
            limit: 1000,
          },
        },
      );

      const { data } = res.data;

      dispatch<EmployeeAssetListActionSuccess>({
        type: ActionTypes.EMPLOYEE_ASSET_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<EmployeeAssetListActionFail>({
        type: ActionTypes.EMPLOYEE_ASSET_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getEmployeeAssetListReset = (): EmployeeAssetListActionReset => ({
  type: ActionTypes.EMPLOYEE_ASSET_LIST_RESET,
});
