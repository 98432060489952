import { createSelector, Selector } from 'reselect';

import {
  productCreateReducer,
  productDeleteReducer,
  productListReducer,
  productUpdateReducer,
} from 'src/reducers/product-reducer';
import { RootState } from 'src/store';
import { ProductDetails } from 'src/types';

export const productSelector = createSelector(
  [(state: RootState) => state],
  state => state.productList,
);

export const productDetailsSelector = createSelector(
  [(state: RootState) => state],
  state => state.productDetails,
);

export const productWithVariantsDetailsSelector = createSelector(
  [productDetailsSelector],
  state => {
    const productFromState = state.product;
    let productVariants: ProductDetails[] = [];
    let product: ProductDetails | undefined;
    let productWithVariantsDetails: ProductDetails[] = [];

    if (productFromState) {
      productVariants =
        productFromState.oldVariants?.map(variant => ({
          name: productFromState.name,
          images: variant.images,
          type: productFromState.type,
          prices: productFromState.prices,
          min_delivery_days: productFromState.min_delivery_days,
          max_delivery_days: productFromState.max_delivery_days,
          delivery_time: productFromState.delivery_time,
          description: variant.description,
          short_description: variant.short_description,
        })) ?? [];

      product = {
        name: productFromState.name,
        images: productFromState.images,
        type: productFromState.type,
        prices: productFromState.prices,
        min_delivery_days: productFromState.min_delivery_days,
        max_delivery_days: productFromState.max_delivery_days,
        delivery_time: productFromState.delivery_time,
        description: productFromState.description,
        short_description: productFromState.short_description,
      };
    }

    if (product) {
      productWithVariantsDetails = [product, ...productVariants];
    }

    return {
      loading: state.loading,
      success: state.success,
      error: state.error,
      productWithVariantsDetails,
    };
  },
);

export const productListAdminSelector = createSelector(
  [(state: RootState) => state],
  state => state.productListAdmin,
);

export const productsToCatalogSelectors = createSelector(
  [(state: RootState) => state],
  state => state.productsToCatalog,
);

export const productCreateSelector: Selector<
  RootState,
  ReturnType<typeof productCreateReducer>
> = createSelector([(state: RootState) => state], state => state.productCreate);

export const productDeleteSelector: Selector<
  RootState,
  ReturnType<typeof productDeleteReducer>
> = createSelector([(state: RootState) => state], state => state.productDelete);

export const productUpdateSelector: Selector<
  RootState,
  ReturnType<typeof productUpdateReducer>
> = createSelector([(state: RootState) => state], state => state.productUpdate);

export const productListSelector: Selector<
  RootState,
  ReturnType<typeof productListReducer>
> = createSelector([(state: RootState) => state], state => state.productList);
