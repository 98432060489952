import { z } from 'zod';

export const countryValidator = z.object({
  code: z.string(),
  id: z.number(),
  name: z.string(),
  requires_tin: z.boolean(),
});

export const countryListValidator = z.object({
  data: z.array(countryValidator),
});

export type CountryType = z.infer<typeof countryValidator>;
