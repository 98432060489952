export const apiConfig = {
  domain: process.env.REACT_APP_API_DOMAIN,
  url:
    process.env.REACT_APP_API_DOMAIN &&
    process.env.REACT_APP_API_PREFIX &&
    `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_PREFIX}`,
  client_id: process.env.REACT_APP_API_CLIENT_ID,
  client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  grant_type: process.env.REACT_APP_API_GRANT_TYPE,
  auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0_audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  auth0_connection:
    process.env.REACT_APP_AUTH0_CONNECTION ??
    'Username-Password-Authentication',
  app_env: process.env.REACT_APP_ENV,
  rollbar_token: process.env.REACT_APP_ROLLBAR_TOKEN,
};
