import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const topUpUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.topUpUpdate,
);

export const topUpListSelector = createSelector(
  [(state: RootState) => state],
  state => state.topUpList,
);
