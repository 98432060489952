import {
  PaletteMode,
  ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material';

declare module '@mui/material/styles' {
  // extend palette options to have the workwize values
  interface PaletteOptions {
    workwize: {
      dark: string;
      darkest: string;
    };
    neutral: {
      main: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const theme: ThemeOptions = {
  palette: {
    mode: 'light' as PaletteMode,
    primary: {
      main: '#122750',
      dark: '#091836',
    },
    secondary: {
      main: '#f50057',
    },
    workwize: {
      dark: '#122750',
      darkest: '#2B2784',
    },
    neutral: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '"Hanken Grotesk"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 500,
      fontSize: '2.188rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.813rem',
    },
    h3: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    h5: {
      fontWeight: 500,
      fontSize: '1rem',
    },
    h6: {
      fontWeight: 500,
      fontSize: '0.875rem',
    },
    overline: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
};

export const MuiV5Theme = createTheme(theme);
export const MuiV5ThemeResponsiveFont = responsiveFontSizes(MuiV5Theme);
