import { FC } from 'react';

import useDesign from '../hooks/useDesign';

interface LogoProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const Logo: FC<LogoProps> = props => {
  const { logo } = useDesign();

  let path;

  if (logo?.url) {
    path = logo.url;
  } else if (logo) {
    path = logo;
  } else {
    path = '/static/workwize.svg';
  }

  return (
    <img
      alt="Logo"
      src={path}
      style={{ height: '100%', maxWidth: '100%' }}
      {...props}
    />
  );
};

export default Logo;
