// TODO: type any will be included in the ban-types soon.
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense, Fragment, lazy, FC } from 'react';

import { Switch, Redirect, Route } from 'react-router-dom';

import AuthGuard from 'src/components/AuthGuard';
import EmployerTenantAdminGuard from 'src/components/EmployerTenantAdminGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

import AdminGuard from './components/AdminGuard';
import EmployeeGuard from './components/EmployeeGuard';
import EmployerGuard from './components/EmployerGuard';
import HomeView from './views/home/HomeView';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];
export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const lazyWithRetry = (
  componentImport: Promise<{ default: React.ComponentType<any> }>,
) => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
  );

  try {
    window.localStorage.setItem('page-has-been-force-refreshed', 'false');

    return lazy(() => componentImport);
  } catch (error) {
    if (!pageHasAlreadyBeenForceRefreshed) {
      //Refresh the page immediately! if the user is not yet on the latest version of the application
      // window.localStorage.setItem('page-has-been-force-refreshed', 'true');
      // return window.location.reload();
    }

    // The page has already been reloaded and user is already on the latest version of the application
    // Let's redirect user to error boundary page.
    // window.location.replace('/400');
  }
};

export const routes = (): Routes => {
  return [
    {
      exact: true,
      path: '/400',
      component: lazyWithRetry(import('src/views/errors/ErrorBoundaryView')),
    },
    {
      exact: true,
      path: '/404',
      component: lazyWithRetry(import('src/views/errors/NotFoundView')),
    },
    {
      exact: true,
      path: '/410',
      component: lazyWithRetry(import('src/views/errors/OldLinkView')),
    },
    {
      exact: true,
      path: '/403',
      component: lazyWithRetry(import('src/views/errors/AccessDeniedView')),
    },
    {
      exact: true,
      path: '/access-denied',
      component: lazyWithRetry(import('src/views/AccessDenied')),
    },
    {
      exact: true,
      path: '/register',
      component: lazyWithRetry(import('src/views/auth/RegisterView')),
    },
    {
      exact: true,
      layout: MainLayout,
      path: '/terms-and-conditions',
      component: lazyWithRetry(
        import('src/views/static/TermsAndConditionsView'),
      ),
    },
    {
      exact: true,
      layout: MainLayout,
      path: '/privacy-policy',
      component: lazyWithRetry(import('src/views/static/PrivacyPolicyView')),
    },
    {
      exact: true,
      guard: AuthGuard,
      layout: MainLayout,
      path: '/monday/oauth-callback',
      component: lazyWithRetry(
        import('src/views/monday/MondayOAuthCallbackView'),
      ),
    },
    {
      exact: true,
      layout: MainLayout,
      path: '/monday/authorization',
      component: lazyWithRetry(
        import('src/views/monday/MondayAuthorizationView'),
      ),
    },
    {
      path: '/app',
      guard: AuthGuard,
      layout: DashboardLayout,
      routes: [
        {
          exact: true,
          path: '/app/account',
          component: lazyWithRetry(import('src/views/account/Account')),
        },
        {
          guard: EmployerTenantAdminGuard,
          exact: true,
          path: '/app/account/settings',
          component: lazyWithRetry(import('src/views/account')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/employers',
          component: lazyWithRetry(import('src/views/employer/ListView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/employers/:employerId/edit',
          component: lazyWithRetry(import('src/views/employer/EditView')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/employees',
          component: lazyWithRetry(
            import('src/pages/app/management/employees'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/offices',
          component: lazyWithRetry(import('src/pages/app/management/offices')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/offices/:officeId',
          component: lazyWithRetry(
            import('src/pages/app/management/offices/officeId'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/offices',
          component: lazyWithRetry(import('src/pages/app/management/offices')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/offices/:officeId',
          component: lazyWithRetry(
            import('src/pages/app/management/offices/officeId'),
          ),
        },
        {
          guard: EmployeeGuard,
          exact: true,
          path: '/app/management/announcement',
          component: lazyWithRetry(import('src/views/announcement')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/employers/create',
          component: lazyWithRetry(import('src/views/employer/CreateView')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/employees/:employeeId/edit',
          component: lazyWithRetry(
            import('src/pages/app/management/employees/employeeId/edit'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/decommissioning',
          component: lazyWithRetry(
            import('src/pages/app/management/decommissioning'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/employees/create',
          component: lazyWithRetry(
            import('src/pages/app/management/employees/create'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/departments/:departmentId/edit',
          component: lazyWithRetry(
            import('src/pages/app/management/departments/edit'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/departments/create',
          component: lazyWithRetry(
            import('src/pages/app/management/departments/create'),
          ),
        },
        {
          guard: EmployeeGuard,
          exact: true,
          path: '/app/cart',
          component: lazyWithRetry(import('src/views/ReviewCartView')),
        },
        {
          guard: EmployeeGuard,
          exact: true,
          path: '/app/checkout',
          component: lazyWithRetry(import('src/views/cart/Checkout')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/orders/create',
          component: lazyWithRetry(
            import('src/pages/app/management/orders/create'),
          ),
        },
        {
          exact: true,
          path: '/app/management/orders/create/finish',
          component: lazyWithRetry(
            import('src/pages/app/management/orders/create/finish'),
          ),
        },
        {
          exact: true,
          path: ['/app/management/orders', '/app/management/orders/repairs'],
          component: lazyWithRetry(
            import('src/pages/app/management/orders/index'),
          ),
        },
        {
          exact: true,
          path: '/app/management/orders/repairs/:id',
          component: lazyWithRetry(
            import('src/pages/app/management/orders/repairs/single-repair'),
          ),
        },
        {
          exact: true,
          path: [
            '/app/management/orders',
            '/app/management/orders/offboardings',
          ],
          component: lazyWithRetry(
            import('src/pages/app/management/orders/index'),
          ),
        },
        {
          exact: true,
          path: '/app/management/orders/offboardings/:id',
          component: lazyWithRetry(
            import(
              'src/pages/app/management/orders/offboardings/single-offboarding'
            ),
          ),
        },
        {
          exact: true,
          path: '/app/management/orders/:id',
          component: lazyWithRetry(
            import('src/pages/app/management/orders/details'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/catalogs',
          component: lazyWithRetry(import('src/views/catalog/CatalogListView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/request-cancellations',
          component: lazyWithRetry(import('src/views/cancellation/ListView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/request-cancellations/:cancellationId',
          component: lazyWithRetry(import('src/views/cancellation/ShowView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/catalogs/:catalogId/edit',
          component: lazyWithRetry(import('src/views/catalog/CatalogEditView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/request-returns',
          component: lazyWithRetry(import('src/views/return/ListView')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/request-returns/:returnID',
          component: lazyWithRetry(import('src/views/return/ShowView')),
        },
        {
          exact: true,
          path: '/app/management/catalogs/:catalogId/products/edit',
          component: lazyWithRetry(
            import('src/views/catalog/CatalogProductEditView'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/catalogs/:catalogId/products/:productId/edit',
          component: lazyWithRetry(
            import('src/views/catalog/CatalogProductFormView'),
          ),
        },
        {
          exact: true,
          path: '/app/management/catalogs/:catalogId/products/add',
          component: lazyWithRetry(
            import('src/views/catalog/CatalogAddProductView'),
          ),
        },
        {
          exact: true,
          path: '/app/management/catalogs/:catalogId/products',
          component: lazyWithRetry(
            import('src/views/catalog/CatalogProductListView'),
          ),
        },
        {
          exact: true,
          path: '/app/management/products/create',
          component: lazyWithRetry(import('src/views/product/ProductFormView')),
        },
        {
          exact: true,
          path: '/app/management/products/:productId/edit',
          component: lazyWithRetry(import('src/views/product/ProductFormView')),
        },
        {
          exact: true,
          path: '/app/management/products/:productId',
          component: lazyWithRetry(import('src/views/product/ProductShowView')),
        },
        {
          exact: true,
          path: [
            '/app/management/products',
            '/app/management/products/budgets/rental/:rentalBudgetId/purchase/:purchaseBudgetId',
          ],
          component: lazyWithRetry(import('src/views/product/ProductListView')),
        },
        {
          exact: true,
          path: '/app/management/perks',
          component: lazyWithRetry(import('src/views/perks/PerksView')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/dashboard',
          component: lazyWithRetry(import('src/views/reports/DashboardView')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/assets',
          component: lazyWithRetry(
            import('src/views/asset_management/ListView'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/warehouse/assets',
          component: lazyWithRetry(import('src/views/warehouse/Assets')),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/assets/employees/:employeeId/assets',
          component: lazyWithRetry(
            import('src/views/asset_management/ShowEmployeeAssetsView'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/assets/employees/:employeeId/assets/:assetId',
          component: lazyWithRetry(
            import('src/views/asset_management/ShowAssetView'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/employers/:employerId/assets/create',
          component: lazyWithRetry(
            import('src/views/asset_management/CreateView'),
          ),
        },
        {
          guard: EmployeeGuard,
          exact: true,
          path: '/app/management/employee/assets/',
          component: lazyWithRetry(
            import('src/views/asset_management/EmployeeListView'),
          ),
        },

        {
          guard: EmployeeGuard,
          exact: true,
          path: '/app/management/employee/assets/:assetId',
          component: lazyWithRetry(
            import('src/views/asset_management/ShowAssetView'),
          ),
        },
        {
          guard: EmployerGuard,
          exact: true,
          path: '/app/management/requests',
          component: lazyWithRetry(import('src/pages/app/management/requests')),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/requests-offboards',
          component: lazyWithRetry(
            import('src/views/offboards/OffboardAdminView/ListView'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/request-offboard/:offboardId',
          component: lazyWithRetry(
            import('src/views/offboards/OffboardAdminView/ShowView'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/pim/products',
          component: lazyWithRetry(
            import('src/views/product/ProductListView/ProductListAdmin'),
          ),
        },
        {
          guard: AdminGuard,
          exact: true,
          path: '/app/management/catalogs/create',
          component: lazyWithRetry(
            import('src/views/catalog/CatalogCreateView'),
          ),
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
    {
      path: '*',
      layout: MainLayout,
      routes: [
        {
          exact: true,
          guard: AuthGuard,
          path: '/',
          component: HomeView,
        },
        {
          component: () => <Redirect to="/404" />,
        },
      ],
    },
  ];
};

export const RenderRoutes: FC = () => {
  return renderRoutes(routes());
};

export default RenderRoutes;
