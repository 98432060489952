import React from 'react';

import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SideMenuBarSkeleton = () => {
  return (
    <>
      <Skeleton variant="rect" width="100%" height={50} animation="pulse" />
      <Skeleton variant="text" width="100%" animation="pulse" />
      <Skeleton variant="text" width="50%" animation="pulse" />
      {Array(5)
        .fill(1)
        .map((_, index) => (
          <Box display="flex" gridGap={5} key={index}>
            <Skeleton
              variant="circle"
              width={30}
              height={30}
              animation="pulse"
            />
            <Skeleton variant="text" width="50%" animation="pulse" />
          </Box>
        ))}
      <Skeleton variant="text" width="50%" animation="pulse" />
      {Array(5)
        .fill(1)
        .map((_, index) => (
          <Box display="flex" gridGap={5} key={index}>
            <Skeleton
              variant="circle"
              width={30}
              height={30}
              animation="pulse"
            />
            <Skeleton variant="text" width="50%" animation="pulse" />
          </Box>
        ))}
    </>
  );
};

export default SideMenuBarSkeleton;
