import { Selector, createSelector } from 'reselect';

import { authUserUploadPhotoReducer } from 'src/reducers/auth-reducer';
import { RootState } from 'src/store';

export const authUserUploadPhotoSelector: Selector<
  RootState,
  ReturnType<typeof authUserUploadPhotoReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.authUserUploadPhoto,
);
