import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  GenerateTokenCreateAction,
  GenerateTokenCreateActionSuccess,
  GenerateTokenDeleteAction,
  GenerateTokenDeleteActionSuccess,
  GenerateTokenListAction,
} from 'src/actions/generate-token-action';
import { GenerateToken, GenerateTokenSuccessResponse } from 'src/types';

interface GenerateTokenInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface GenerateTokenListState extends GenerateTokenInitialState {
  tokens: GenerateToken[];
}

const initialGenerateTokenList = {
  tokens: [],
};

export const generateTokenListReducer: Reducer<
  GenerateTokenListState,
  | GenerateTokenListAction
  | GenerateTokenDeleteActionSuccess
  | GenerateTokenCreateActionSuccess
> = (state = initialGenerateTokenList, action) => {
  switch (action.type) {
    case ActionTypes.GENERATE_TOKEN_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GENERATE_TOKEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        tokens: action.payload,
      };
    case ActionTypes.GENERATE_TOKEN_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.GENERATE_TOKEN_DELETE_SUCCESS:
      return {
        ...state,
        tokens: state.tokens.filter(token => token.id !== action.payload),
      };
    case ActionTypes.GENERATE_TOKEN_CREATE_SUCCESS:
      return {
        ...state,
        tokens: [...state.tokens, action.payload.token],
      };
    case ActionTypes.GENERATE_TOKEN_LIST_RESET:
      return initialGenerateTokenList;
    default:
      return state;
  }
};

interface GenerateTokenCreateState extends GenerateTokenInitialState {
  token?: GenerateTokenSuccessResponse;
}

export const generateTokenCreateReducer: Reducer<
  GenerateTokenCreateState,
  GenerateTokenCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.GENERATE_TOKEN_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GENERATE_TOKEN_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        token: action.payload,
      };
    case ActionTypes.GENERATE_TOKEN_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.GENERATE_TOKEN_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const generateTokenDeleteReducer: Reducer<
  GenerateTokenInitialState,
  GenerateTokenDeleteAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.GENERATE_TOKEN_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GENERATE_TOKEN_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.GENERATE_TOKEN_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.GENERATE_TOKEN_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
