import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardOrderByDepartmentActionFail,
  GetDashboardOrderByDepartmentActionList,
  GetDashboardOrderByDepartmentActionRequest,
  GetDashboardOrderByDepartmentActionSuccess,
  GetDashboardOrderByDepartmentActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { DashboardOrderByDepartment } from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardOrderByDepartment =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardOrderByDepartmentActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardOrderByDepartmentActionRequest>({
      type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardOrderByDepartment }>(
        `${apiConfig.url}/orders-by-department`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardOrderByDepartmentActionSuccess>({
        type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardOrderByDepartmentActionFail>({
        type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_FAIL,
        payload: 'Something Went Wrong while Fetching Orders by Department',
      });
    }
  };

export const getDashboardOrderByDepartmentReset =
  (): GetDashboardOrderByDepartmentActionReset => ({
    type: ActionTypes.GET_ORDERS_BY_DEPARTMENT_RESET,
  });
