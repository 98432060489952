import { createSelector, Selector } from 'reselect';

import { acceptWelcomeMessageReducer } from 'src/reducers/accept-welcome-message-reducer';
import { RootState } from 'src/store';

export const acceptWelcomeMessageSelector: Selector<
  RootState,
  ReturnType<typeof acceptWelcomeMessageReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.acceptWelcomeMessage,
);
