import { FC } from 'react';

interface StatusActiveIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const StatusActiveIcon: FC<StatusActiveIconProps> = ({
  width,
  height,
  color,
}) => (
  <svg
    width={width ?? '15'}
    height={height ?? '15'}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.792 13.156a5.225 5.225 0 0 1-.038-7.39.713.713 0 0 0-1.013-1.002 6.65 6.65 0 1 0 9.406-.048.713.713 0 0 0-1.003 1.013 5.225 5.225 0 1 1-7.352 7.427Z"
      fill={color ?? '#fff'}
    />
    <path
      d="M9.5 2.85V9.5"
      stroke={color ?? '#fff'}
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);

export default StatusActiveIcon;
