import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

import { Country, Supplier } from 'src/types';

export const AdminProductListFilterContext = createContext<{
  suppliers: Supplier[] | null;
  country: Country | null;
  setCountry: Dispatch<SetStateAction<Country | null>>;
  setSuppliers: Dispatch<SetStateAction<Supplier[] | null>>;
}>({
  suppliers: null,
  country: null,
  setCountry: () => {},
  setSuppliers: () => {},
});

export const AdminProductListFilterProvider: FC = ({ children }) => {
  const [country, setCountry] = useState<Country | null>(null);
  const [suppliers, setSuppliers] = useState<Supplier[] | null>(null);

  return (
    <AdminProductListFilterContext.Provider
      value={{
        country,
        suppliers,
        setCountry,
        setSuppliers,
      }}
    >
      {children}
    </AdminProductListFilterContext.Provider>
  );
};
