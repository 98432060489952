import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogProductAddAction,
  CatalogProductAddActionFail,
  CatalogProductAddActionRequest,
  CatalogProductAddActionReset,
  CatalogProductAddActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import axios from 'src/utils/axios';

export const addCatalogProduct =
  (
    catalogId: string | number,
    productIds: Product['id'][],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogProductAddAction
  > =>
  async dispatch => {
    dispatch<CatalogProductAddActionRequest>({
      type: ActionTypes.CATALOG_PRODUCT_ADD_REQUEST,
    });

    try {
      await axios.post(`${apiConfig.url}/catalogs/${catalogId}/products`, {
        products: productIds,
      });

      dispatch<CatalogProductAddActionSuccess>({
        type: ActionTypes.CATALOG_PRODUCT_ADD_SUCCESS,
      });

      enqueueSnackbar('Successfully added to catalog', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<CatalogProductAddActionFail>({
        type: ActionTypes.CATALOG_PRODUCT_ADD_FAIL,
        payload: 'Unable to add products',
      });

      enqueueSnackbar('Unable to add products', {
        variant: 'error',
      });
    }
  };

export const addCatalogProductReset = (): CatalogProductAddActionReset => ({
  type: ActionTypes.CATALOG_PRODUCT_ADD_RESET,
});
