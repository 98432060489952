import { createSelector, Selector } from 'reselect';

import { supplierListInfiniteReducer } from 'src/reducers/supplier-reducer';
import { RootState } from 'src/store';

export const supplierListInfiniteSelector: Selector<
  RootState,
  ReturnType<typeof supplierListInfiniteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.supplierListInfinite,
);
