import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductImageDeleteAction,
  ProductImageDeleteActionFail,
  ProductImageDeleteActionRequest,
  ProductImageDeleteActionReset,
  ProductImageDeleteActionSuccess,
} from 'src/actions/product-image-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export const deleteProductImage =
  (
    productId: string | number,
    productImageId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    ProductImageDeleteAction
  > =>
  async dispatch => {
    dispatch<ProductImageDeleteActionRequest>({
      type: ActionTypes.PRODUCT_IMAGE_DELETE_REQUEST,
    });

    try {
      await axios.delete(
        `${apiConfig.url}/products/${productId}/product-image/${productImageId}`,
      );

      dispatch<ProductImageDeleteActionSuccess>({
        type: ActionTypes.PRODUCT_IMAGE_DELETE_SUCCESS,
        payload: productImageId,
      });

      enqueueSnackbar('Product Image Successfully deleted', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<ProductImageDeleteActionFail>({
        type: ActionTypes.PRODUCT_IMAGE_DELETE_FAIL,
        payload: 'Product Image failed to deleted',
      });

      enqueueSnackbar('Product Image failed to deleted', {
        variant: 'error',
      });
    }
  };

export const deleteProductImageReset = (): ProductImageDeleteActionReset => ({
  type: ActionTypes.PRODUCT_IMAGE_DELETE_RESET,
});
