import { createStyles, makeStyles } from '@material-ui/core';

import { Theme } from 'src/theme';

export const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
  }),
);
