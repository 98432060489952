import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  DepartmentListAction,
  DepartmentListActionFail,
  DepartmentListActionRequest,
  DepartmentListActionReset,
  DepartmentListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Department } from 'src/types/department';
import axios from 'src/utils/axios';

export const getDepartmentList =
  (): ThunkAction<Promise<void>, RootState, undefined, DepartmentListAction> =>
  async dispatch => {
    dispatch<DepartmentListActionRequest>({
      type: ActionTypes.DEPARTMENT_LIST_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Department[] }>(
        `${apiConfig.url}/departments`,
        {
          params: {
            limit: 999,
            sort: 'name',
          },
        },
      );

      const { data } = res.data;

      dispatch<DepartmentListActionSuccess>({
        type: ActionTypes.DEPARTMENT_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<DepartmentListActionFail>({
        type: ActionTypes.DEPARTMENT_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getDepartmentListReset = (): DepartmentListActionReset => ({
  type: ActionTypes.DEPARTMENT_LIST_RESET,
});
