import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployerDetailsAction,
  EmployerDetailsActionFail,
  EmployerDetailsActionRequest,
  EmployerDetailsActionReset,
  EmployerDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employer } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const getEmployerDetails =
  (
    employerId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployerDetailsAction> =>
  async dispatch => {
    dispatch<EmployerDetailsActionRequest>({
      type: ActionTypes.EMPLOYER_DETAILS_REQUEST,
    });

    try {
      const res = await axios.get<Response<Employer>>(
        `${apiConfig.url}/employers/${employerId}`,
      );

      const { data } = res.data;

      dispatch<EmployerDetailsActionSuccess>({
        type: ActionTypes.EMPLOYER_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<EmployerDetailsActionFail>({
        type: ActionTypes.EMPLOYER_DETAILS_FAIL,
        payload: 'unable to fetch employer',
      });
    }
  };

export const getEmployerDetailsReset = (): EmployerDetailsActionReset => ({
  type: ActionTypes.EMPLOYER_DETAILS_RESET,
});
