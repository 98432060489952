import { useQuery } from '@tanstack/react-query';

import { indexDepartmentValidator } from 'src/modules/department/validators/departmentValidator';
import axios from 'src/utils/axios';

import * as KEYS from '../constants/keys';

export const useIndexDepartments = () => {
  return useQuery({
    queryKey: [KEYS.departments],
    queryFn: async () => {
      const response = await axios.get(`departments`, {
        params: { limit: 100 },
      });

      return indexDepartmentValidator.parseAsync(response.data.data);
    },
    retry: false,
    staleTime: Infinity,
  });
};
