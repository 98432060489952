import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  OrderCheckoutListAction,
  BudgetTypes,
} from 'src/actions/order-checkout-action';

interface OrderCheckoutInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface OrderCheckoutListState extends OrderCheckoutInitialState {
  budget?: BudgetTypes;
}

export const orderCheckoutReducer: Reducer<
  OrderCheckoutListState,
  OrderCheckoutListAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.ORDER_CHECKOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.ORDER_CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        budget: action.payload,
      };
    case ActionTypes.ORDER_CHECKOUT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.ORDER_CHECKOUT_RESET:
      return {};
    default:
      return state;
  }
};
