import { z } from 'zod';

export const categoryValidator = z.object({
  id: z.number(),
  name: z.string(),
});

export type Category = z.infer<typeof categoryValidator>;

export const categoryListValidator = z.object({
  data: z.array(categoryValidator),
});
