import axiosInstance, { Canceler } from 'axios';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeListInfiniteAction,
  EmployeeListInfiniteActionFail,
  EmployeeListInfiniteActionRequest,
  EmployeeListInfiniteActionReset,
  EmployeeListInfiniteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee } from 'src/types';
import { Meta } from 'src/types/response';
import axios from 'src/utils/axios';

export const getEmployeeListInfinite =
  (
    queryfilters: {
      expand?: string;
      limit?: number;
      page?: number;
      sort?: string;
      search?: string;
    },
    callback?: (cancel: Canceler) => void,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeListInfiniteAction
  > =>
  async (dispatch, getState) => {
    dispatch<EmployeeListInfiniteActionRequest>({
      type: ActionTypes.EMPLOYEE_LIST_INFINITE_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Employee[]; meta: Meta }>(
        `${apiConfig.url}/employees/list`,
        {
          params: {
            expand: queryfilters.expand,
            limit: queryfilters.limit ?? 10,
            page: queryfilters.page ?? 1,
            sort: queryfilters.sort,
            search: {
              query: queryfilters.search ?? '',
              fields: 'user.name',
            },
          },
          cancelToken: new axiosInstance.CancelToken(c => {
            callback?.(c);
          }),
        },
      );

      const { data, meta } = res.data;

      dispatch<EmployeeListInfiniteActionSuccess>({
        type: ActionTypes.EMPLOYEE_LIST_INFINITE_SUCCESS,
        payload: [...getState().employeeListInfinite.employees, ...data],
        hasMore: Boolean(
          queryfilters.page && meta.last_page > queryfilters.page,
        ),
        meta,
      });
    } catch (e) {
      if (axiosInstance.isCancel(e)) return;

      dispatch<EmployeeListInfiniteActionFail>({
        type: ActionTypes.EMPLOYEE_LIST_INFINITE_FAIL,
        payload: 'Something Went Wrong',
      });
    }
  };

export const getEmployeeListInfiniteReset =
  (): EmployeeListInfiniteActionReset => ({
    type: ActionTypes.EMPLOYEE_LIST_INFINITE_RESET,
  });
