export const colorSchemeV2 = {
  primary: '#227AF7',
  primaryDark: '#223B76',
  primaryDarker: '#122750',
  primartDarkest: '#223B76',
  textLight: '#ffffff',
  textSecondary: '#A2A6D2',
};

export const themeV1 = {
  background: {
    background: '#f4f6f8',
    paper: '#ffffff',
  },
  topBar: {
    background: '#7057ff',
    nameText: '#ffffff',
  },
  navBar: {
    background: '#ffffff',
    mainText: colorSchemeV2.textLight,
    textSecondary: colorSchemeV2.textSecondary,
    nameText: '#7057ff',
    menu: '#546e7a',
    activeMenu: '#7057ff',
    budgetContainerFull: '#4caf5014',
    budgetContainerEmpty: '#af4c4c14',
    budgetTextFull: '#4caf50',
    budgetTextEmpty: '#af4c4c',
    helpContainerButton: '#7057ff',
    helpTextButton: '#ffffff',
  },
  miniCart: {
    background: '#FFFFFF',
    mainText: '#000000',
    secondary: '#546e7a',
    mainButton: '#7057ff',
    mainButtonText: '#FFFFFF',
    secondaryButton: '#e0e0e0',
    secondaryButtonText: '#000000de',
  },
  icons: {
    cart: '#FFFFFF',
    delete: '#227AF7',
    arrow: '#546e7a',
    edit: '#546e7a',
  },

  forms: {
    inputText: '#000000',
    inputLabel: '#546e7a',
    inputLabelFocused: '#7057ff',
    inputBorder: '#0000003b',
    inputBorderFocused: '#7057ff',
    inputBorderError: '#f44336',
    inputErrorText: '#f44336',
    inputBorderDisabled: '#00000042',
    inputTextDisabled: '#00000061',
    inputLabelDisabled: '#00000061',
    inputBorderHover: '#00000042',
  },

  defaults: {
    headerText: '#000000',
    breadCrumbs: '#546e7a',
    breadCrumbHighlight: '#227AF7',
    mainText: '#000000',
    secondaryText: '#546E7A',
    checkoutSuccessMainText: '#227AF7',
    checkoutSuccessSecondaryText: '#227AF7',
    greenLabelContainer: '#43a04714',
    greenLabelText: '#43a047',
    orangeLabelContainer: '#ffa00014',
    orangeLabelText: '#ffa000',
    redLabelContainer: '#d32f2f14',
    redLabelText: '#d32f2f',
    blueTextButton: '#ffffff',
    blueButton: '#7057ff',
    redTextButton: '#ffffff',
    redButton: '#F95F68',
    greenButtonText: '#ffffff',
    greenButton: '#4caf50',
    grayButton: '#e0e0e0',
    grayButtonText: '#227AF7',
    whiteButton: '#ffffff',
    whiteButtonText: '#227AF7',
    textButton: '#227AF7',
    tickCheckout: '#ffffff',
    divider: '#0000001f',
  },
  logo: '/static/workwize.svg',
  profile_image: '/static/workwize.svg',
};

export interface WorkwizeThemeV1 {
  background: backgroundV1;
  topBar: topBarV1;
  navBar: navBarV1;
  miniCart: miniCartV1;
  icons: iconsv1;
  forms: formsV1;
  defaults: defaultsV1;
  logo: string;
  profile_image: string;
}

interface backgroundV1 {
  background: string;
  paper: string;
}

interface topBarV1 {
  background: string;
  nameText: string;
}

interface navBarV1 {
  background: string;
  mainText: string;
  textSecondary: string;
  nameText: string;
  menu: string;
  activeMenu: string;
  budgetContainerFull: string;
  budgetContainerEmpty: string;
  budgetTextFull: string;
  budgetTextEmpty: string;
  helpContainerButton: string;
  helpTextButton: string;
}

interface miniCartV1 {
  background: string;
  mainText: string;
  secondary: string;
  mainButton: string;
  mainButtonText: string;
  secondaryButton: string;
  secondaryButtonText: string;
}

interface iconsv1 {
  cart: string;
  delete: string;
  arrow: string;
  edit: string;
}

interface formsV1 {
  inputText: string;
  inputLabel: string;
  inputLabelFocused: string;
  inputBorder: string;
  inputBorderFocused: string;
  inputBorderError: string;
  inputErrorText: string;
  inputBorderDisabled: string;
  inputTextDisabled: string;
  inputLabelDisabled: string;
  inputBorderHover: string;
}

interface defaultsV1 {
  headerText: string;
  breadCrumbs: string;
  breadCrumbHighlight: string;
  mainText: string;
  secondaryText: string;
  checkoutSuccessMainText: string;
  checkoutSuccessSecondaryText: string;
  greenLabelContainer: string;
  greenLabelText: string;
  orangeLabelContainer: string;
  orangeLabelText: string;
  redLabelContainer: string;
  redLabelText: string;
  blueTextButton: string;
  blueButton: string;
  redTextButton: string;
  redButton: string;
  greenButtonText: string;
  greenButton: string;
  grayButton: string;
  grayButtonText: string;
  whiteButton: string;
  whiteButtonText: string;
  textButton: string;
  tickCheckout: string;
  divider: string;
}

export interface EmployerTheme {
  id?: string | number;
  employer_id?: string | number;
  name?: string;
  created_at?: string;
  updated_at?: string;
  theme: Omit<WorkwizeThemeV1, 'profile_image' | 'logo'> & {
    profile_image: {
      url: string;
    };
    logo: {
      url: string;
    };
  };
}
