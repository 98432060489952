import { SVGProps } from 'react';

const StoreIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.25379 1.57705L0.835894 4.08515C0.819229 4.18539 0.824587 4.28805 0.851595 4.38601C0.878602 4.48396 0.926612 4.57487 0.992289 4.6524C1.05797 4.72993 1.13974 4.79224 1.23192 4.83499C1.3241 4.87774 1.42448 4.8999 1.52609 4.89995H2.15119C2.34629 4.89994 2.53753 4.84558 2.70346 4.74296C2.86939 4.64033 3.00345 4.49351 3.09059 4.31895L3.50009 3.49995L3.90959 4.31895C3.99674 4.49351 4.13079 4.64033 4.29672 4.74296C4.46265 4.84558 4.65389 4.89994 4.84899 4.89995H5.65119C5.84629 4.89994 6.03753 4.84558 6.20346 4.74296C6.36939 4.64033 6.50345 4.49351 6.59059 4.31895L7.00009 3.49995L7.40959 4.31895C7.49674 4.49351 7.63079 4.64033 7.79672 4.74296C7.96265 4.84558 8.15389 4.89994 8.34899 4.89995H9.15119C9.34629 4.89994 9.53753 4.84558 9.70346 4.74296C9.86939 4.64033 10.0034 4.49351 10.0906 4.31895L10.5001 3.49995L10.9096 4.31895C10.9967 4.49351 11.1308 4.64033 11.2967 4.74296C11.4627 4.84558 11.6539 4.89994 11.849 4.89995H12.4741C12.5757 4.8999 12.6761 4.87774 12.7683 4.83499C12.8605 4.79224 12.9422 4.72993 13.0079 4.6524C13.0736 4.57487 13.1216 4.48396 13.1486 4.38601C13.1756 4.28805 13.181 4.18539 13.1643 4.08515L12.7464 1.57705C12.7055 1.33188 12.5789 1.10919 12.3892 0.94858C12.1995 0.787973 11.959 0.699872 11.7104 0.699951H2.28979C2.04123 0.699872 1.80071 0.787973 1.611 0.94858C1.4213 1.10919 1.29472 1.33188 1.25379 1.57705Z"
      fill={color}
    />
    <path
      d="M12.5999 6.30005H1.3999V12.25C1.3999 12.5285 1.51053 12.7956 1.70744 12.9925C1.90435 13.1894 2.17143 13.3 2.4499 13.3H4.8999V8.40005H9.0999V13.3H11.5499C11.8284 13.3 12.0955 13.1894 12.2924 12.9925C12.4893 12.7956 12.5999 12.5285 12.5999 12.25V6.30005Z"
      fill={color}
    />
  </svg>
);

export default StoreIcon;
