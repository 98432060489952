import { SVGProps } from 'react';

const Home2Icon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 15 15"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8785 4.09123C14.7995 3.9696 14.687 3.87347 14.5545 3.81448L7.8045 0.814483C7.70865 0.771848 7.60491 0.749817 7.5 0.749817C7.39509 0.749817 7.29135 0.771848 7.1955 0.814483L0.4455 3.81448C0.312886 3.8734 0.200211 3.96952 0.121136 4.09119C0.0420608 4.21287 -1.84116e-05 4.35487 6.04331e-09 4.49998V15H1.5V4.98748L7.5 2.32048L13.5 4.98748V15H15V4.49998C14.9999 4.35485 14.9577 4.21286 14.8785 4.09123ZM9 5.99998H12V8.99998H9V5.99998ZM7.5 7.49998H3V15H7.5V7.49998ZM12 10.5H9V15H12V10.5Z"
      fill={color}
    />
  </svg>
);

export default Home2Icon;
