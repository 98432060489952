import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  InvitationResendUnacceptedAction,
  InvitationResendUnacceptedActionFail,
  InvitationResendUnacceptedActionRequest,
  InvitationResendUnacceptedActionReset,
  InvitationResendUnacceptedActionSuccess,
  InvitationResendSingleEmployeeAction,
  InvitationResendSingleEmployeeActionFail,
  InvitationResendSingleEmployeeActionRequest,
  InvitationResendSingleEmployeeActionReset,
  InvitationResendSingleEmployeeActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export const invitationResendForUnaccepted =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    InvitationResendUnacceptedAction
  > =>
  async dispatch => {
    dispatch<InvitationResendUnacceptedActionRequest>({
      type: ActionTypes.INVITATION_RESEND_UNACCEPTED_REQUEST,
    });

    try {
      const res = await axios.post<{ data: { message: string } }>(
        `${apiConfig.url}/invitations`,
      );

      const { data } = res.data;

      dispatch<InvitationResendUnacceptedActionSuccess>({
        type: ActionTypes.INVITATION_RESEND_UNACCEPTED_SUCCESS,
      });

      enqueueSnackbar(data.message, {
        variant: 'success',
      });
    } catch (err) {
      dispatch<InvitationResendUnacceptedActionFail>({
        type: ActionTypes.INVITATION_RESEND_UNACCEPTED_FAIL,
      });

      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

export const invitationResendForUnacceptedReset =
  (): InvitationResendUnacceptedActionReset => ({
    type: ActionTypes.INVITATION_RESEND_UNACCEPTED_RESET,
  });

export const invitationResendSingleEmployee =
  (
    employeeId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    InvitationResendSingleEmployeeAction
  > =>
  async dispatch => {
    dispatch<InvitationResendSingleEmployeeActionRequest>({
      type: ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: { message: string } }>(
        `${apiConfig.url}/user/invite/resend`,
        {
          employee_id: employeeId,
        },
      );

      const { data } = res.data;

      dispatch<InvitationResendSingleEmployeeActionSuccess>({
        type: ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_SUCCESS,
      });

      enqueueSnackbar(data.message, {
        variant: 'success',
      });
    } catch (err) {
      dispatch<InvitationResendSingleEmployeeActionFail>({
        type: ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_FAIL,
      });

      enqueueSnackbar('Unable to send invitation', {
        variant: 'error',
      });
    }
  };

export const invitationResendSingleEmployeeReset =
  (): InvitationResendSingleEmployeeActionReset => ({
    type: ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_RESET,
  });
