import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeUpdateListAction,
  EmployeeUpdateActionFail,
  EmployeeUpdateActionRequest,
  EmployeeUpdateActionReset,
  EmployeeUpdateActionSucess,
  EmployeeDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee } from 'src/types';
import { EmployeeUpdateFormikValues } from 'src/types/formikValues';
import axios, { ErrorResponse } from 'src/utils/axios';

export const updateEmployee =
  (
    employee_id: string | number,
    data: EmployeeUpdateFormikValues,
    formikHelpers?: FormikHelpers<EmployeeUpdateFormikValues>,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeUpdateListAction | EmployeeDetailsActionSuccess
  > =>
  async dispatch => {
    dispatch<EmployeeUpdateActionRequest>({
      type: ActionTypes.EMPLOYEE_UPDATE_REQUEST,
    });

    const payload = {
      department_id: data.department?.id,
      ...(data.last_name && { last_name: data.last_name }),
      ...(data.name && { name: data.name }),
      ...(data.phone_number && { phone_number: data.phone_number }),
      ...(data.tax_identification_number && {
        tax_identification_number: data.tax_identification_number,
      }),
    };

    try {
      const res = await axios.patch<{ data: Employee }>(
        `${apiConfig.url}/employees/${employee_id}`,
        payload,
      );

      const employee = res.data;

      dispatch<EmployeeUpdateActionSucess>({
        type: ActionTypes.EMPLOYEE_UPDATE_SUCCESS,
        payload: employee.data,
      });

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: employee.data,
      });

      enqueueSnackbar('Employee Successfully Updated', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (formikHelpers && error) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);
        }
      }

      dispatch<EmployeeUpdateActionFail>({
        type: ActionTypes.EMPLOYEE_UPDATE_FAIL,
        payload: 'Something Went Wrong',
      });

      enqueueSnackbar('Something Went Wrong. Cannot Update Employee', {
        variant: 'error',
      });
    }
  };

export const updateEmployeeReset = (): EmployeeUpdateActionReset => ({
  type: ActionTypes.EMPLOYEE_UPDATE_RESET,
});
