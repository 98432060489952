export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL';
export const PRODUCT_LIST_RESET = 'PRODUCT_LIST_RESET';

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST';
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS';
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL';
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET';

export const PRODUCT_LIST_ADMIN_REQUEST = 'PRODUCT_LIST_ADMIN_REQUEST';
export const PRODUCT_LIST_ADMIN_SUCCESS = 'PRODUCT_LIST_ADMIN_SUCCESS';
export const PRODUCT_LIST_ADMIN_FAIL = 'PRODUCT_LIST_ADMIN_FAIL';
export const PRODUCT_LIST_ADMIN_RESET = 'PRODUCT_LIST_ADMIN_RESET';

export const PRODUCT_LIST_ADMIN_INFINITE_REQUEST =
  'PRODUCT_LIST_ADMIN_INFINITE_REQUEST';
export const PRODUCT_LIST_ADMIN_INFINITE_SUCCESS =
  'PRODUCT_LIST_ADMIN_INFINITE_SUCCESS';
export const PRODUCT_LIST_ADMIN_INFINITE_FAIL =
  'PRODUCT_LIST_ADMIN_INFINITE_FAIL';
export const PRODUCT_LIST_ADMIN_INFINITE_RESET =
  'PRODUCT_LIST_ADMIN_INFINITE_RESET';
export const PRODUCT_LIST_ADMIN_INFINITE_CLEAR =
  'PRODUCT_LIST_ADMIN_INFINITE_CLEAR';

export const PRODUCT_SET_TO_CATALOG = 'PRODUCT_SET_TO_CATALOG';
export const PRODUCT_REMOVE_TO_CATALOG = 'PRODUCT_REMOVE_TO_CATALOG';
export const PRODUCT_RESET_TO_CATALOG = 'PRODUCT_RESET_TO_CATALOG';

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST';
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS';
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL';
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET';

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST';
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS';
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL';
export const PRODUCT_DELETE_RESET = 'PRODUCT_DELETE_RESET';

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST';
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL';
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET';
