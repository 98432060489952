import { FC } from 'react';

import { DialogTitle, Typography, Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { DialogModalHeaderProps } from './interface';
import { useAlignmentStyles, useDialogHeaderStyles } from './styles';

const DialogHeader: FC<DialogModalHeaderProps> = ({
  title,
  alignment,
  closeIcon,
  handleIconClick,
}) => {
  const styles = useDialogHeaderStyles();
  const align = useAlignmentStyles();
  let alignContent = '';

  switch (alignment) {
    case 'center':
      alignContent = align.center;
      break;
    case 'right':
      alignContent = align.right;
      break;
  }

  return (
    <DialogTitle className={`${styles.root} ${alignContent}`}>
      <Box flexGrow={1}>
        <Typography variant="h4">{title}</Typography>
      </Box>

      {closeIcon && (
        <Box className={styles.closeIcon}>
          <IconButton onClick={handleIconClick}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </DialogTitle>
  );
};

export default DialogHeader;
