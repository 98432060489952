import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  PerksCreateAction,
  PerksCreateActionFail,
  PerksCreateActionRequest,
  PerksCreateActionReset,
  PerksCreateActionSuccess,
  PerksDeleteAction,
  PerksDeleteActionFail,
  PerksDeleteActionRequest,
  PerksDeleteActionReset,
  PerksDeleteActionSuccess,
  PerksGetAction,
  PerksGetActionFail,
  PerksGetActionRequest,
  PerksGetActionSuccess,
  PerksGetSingleAction,
  PerksGetSingleActionFail,
  PerksGetSingleActionRequest,
  PerksGetSingleActionReset,
  PerksGetSingleActionSuccess,
  PerksUpdateAction,
  PerksUpdateActionFail,
  PerksUpdateActionRequest,
  PerksUpdateActionReset,
  PerksUpdateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Perks } from 'src/types';
import axios from 'src/utils/axios';

export type PerksData = Omit<Perks, 'countries'> & {
  countries: (string | number)[];
};

export const getPerks =
  (
    countryId?: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, PerksGetAction> =>
  async dispatch => {
    dispatch<PerksGetActionRequest>({
      type: ActionTypes.PERKS_GET_REQUEST,
    });

    try {
      const getPerksUrl = countryId ? `countries/${countryId}/perks` : 'perks';

      const res = await axios.get<{ data: Perks[] }>(
        `${apiConfig.url}/${getPerksUrl}`,
      );

      const { data: perks } = res.data;

      dispatch<PerksGetActionSuccess>({
        type: ActionTypes.PERKS_GET_SUCCESS,
        payload: perks,
      });
    } catch (e) {
      dispatch<PerksGetActionFail>({
        type: ActionTypes.PERKS_GET_FAIL,
        payload: 'Server Error',
      });
    }
  };

export const createPerks =
  (
    data: FormData,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    PerksCreateAction | PerksGetActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<PerksCreateActionRequest>({
      type: ActionTypes.PERKS_CREATE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: Perks }>(
        `${apiConfig.url}/perks`,
        data,
      );

      const { data: perksData } = res.data;

      dispatch<PerksCreateActionSuccess>({
        type: ActionTypes.PERKS_CREATE_SUCCESS,
        payload: perksData,
      });

      dispatch<PerksGetActionSuccess>({
        type: ActionTypes.PERKS_GET_SUCCESS,
        payload: [...getState().perksGet.perks, perksData],
      });

      enqueueSnackbar('Perks successfully created', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<PerksCreateActionFail>({
        type: ActionTypes.PERKS_CREATE_FAIL,
        payload: 'Unable to save. Please check your fields.',
      });

      enqueueSnackbar('Unable to save. Please check your fields.', {
        variant: 'error',
      });
    }
  };

export const createPerksReset = (): PerksCreateActionReset => {
  return {
    type: ActionTypes.PERKS_CREATE_RESET,
  };
};

export const getSinglePerks =
  (
    perksId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, PerksGetSingleAction> =>
  async dispatch => {
    dispatch<PerksGetSingleActionRequest>({
      type: ActionTypes.PERKS_GET_SINGLE_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Perks }>(
        `${apiConfig.url}/perks/${perksId}`,
      );

      const { data } = res.data;

      dispatch<PerksGetSingleActionSuccess>({
        type: ActionTypes.PERKS_GET_SINGLE_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<PerksGetSingleActionFail>({
        type: ActionTypes.PERKS_GET_SINGLE_FAIL,
        payload: 'Server Error',
      });
    }
  };

export const getSinglePerksReset = (): PerksGetSingleActionReset => ({
  type: ActionTypes.PERKS_GET_SINGLE_RESET,
});

export const deletePerks =
  (
    perkId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    PerksDeleteAction | PerksGetActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<PerksDeleteActionRequest>({
      type: ActionTypes.PERKS_DELETE_REQUEST,
    });

    try {
      const res = await axios.delete<{ data: Perks }>(
        `${apiConfig.url}/perks/${perkId}`,
      );

      const { data } = res.data;

      dispatch<PerksDeleteActionSuccess>({
        type: ActionTypes.PERKS_DELETE_SUCCESS,
        payload: data,
      });

      dispatch<PerksGetActionSuccess>({
        type: ActionTypes.PERKS_GET_SUCCESS,
        payload: getState().perksGet.perks.filter(perk => perk.id !== data.id),
      });

      enqueueSnackbar(`${data.title || 'Data'} successfully deleted`, {
        variant: 'success',
      });
    } catch (e) {
      dispatch<PerksDeleteActionFail>({
        type: ActionTypes.PERKS_DELETE_FAIL,
        payload: 'Unable to delete perk.',
      });

      enqueueSnackbar('Unable to delete perk. Please try again', {
        variant: 'error',
      });
    }
  };

export const deletePerksReset = (): PerksDeleteActionReset => ({
  type: ActionTypes.PERKS_DELETE_RESET,
});

export const updatePerks =
  (
    perksId: string | number,
    data: FormData,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    PerksUpdateAction | PerksGetActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<PerksUpdateActionRequest>({
      type: ActionTypes.PERKS_UPDATE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: Perks }>(
        `${apiConfig.url}/perks/${perksId}`,
        data,
      );

      const { data: perksData } = res.data;

      dispatch<PerksUpdateActionSuccess>({
        type: ActionTypes.PERKS_UPDATE_SUCCESS,
        payload: perksData,
      });
      dispatch<PerksGetActionSuccess>({
        type: ActionTypes.PERKS_GET_SUCCESS,
        payload: getState().perksGet.perks.map(perk =>
          perk.id === perksData.id ? perksData : perk,
        ),
      });

      enqueueSnackbar('Perks successfully updated', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<PerksUpdateActionFail>({
        type: ActionTypes.PERKS_UPDATE_FAIL,
        payload: 'Unable to update perks.',
      });

      enqueueSnackbar('Unable to update perks.', {
        variant: 'error',
      });
    }
  };

export const updatePerksReset = (): PerksUpdateActionReset => ({
  type: ActionTypes.PERKS_UPDATE_RESET,
});
