import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const invitationResendUnacceptedSelector = createSelector(
  [(state: RootState) => state],
  state => state.invitationResendUnaccepted,
);

export const invitationResendSingleEmployeeSelector = createSelector(
  [(state: RootState) => state],
  state => state.invitationResendSingleEmployee,
);
