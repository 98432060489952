import { createContext, Dispatch, FC, SetStateAction, useState } from 'react';

import { Product } from 'src/types';

export const AddProductsToCatalogContext = createContext<{
  selectedProductsToCatalog: (Product & { in_catalog?: boolean })[];
  setSelectedProductsToCatalog: Dispatch<
    SetStateAction<(Product & { in_catalog?: boolean })[]>
  >;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}>({
  selectedProductsToCatalog: [],
  setSelectedProductsToCatalog: () => {},
  open: false,
  setOpen: () => {},
});

export const AddProductsToCatalogProvider: FC = ({ children }) => {
  const [selectedProductsToCatalog, setSelectedProductsToCatalog] = useState<
    (Product & { in_catalog?: boolean })[]
  >([]);
  const [open, setOpen] = useState(false);

  return (
    <AddProductsToCatalogContext.Provider
      value={{
        selectedProductsToCatalog,
        setSelectedProductsToCatalog,
        open,
        setOpen,
      }}
    >
      {children}
    </AddProductsToCatalogContext.Provider>
  );
};
