import { createSelector, Selector } from 'reselect';

import { employerDetailsReducer } from 'src/reducers/employer-reducer';
import { RootState } from 'src/store';

export const employerCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerCreate,
);

export const employerUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerUpdate,
);

export const employerListSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerList,
);

export const employerSettingsUpdateSelector = createSelector(
  [(state: RootState) => state],
  state => state.employerSettingsUpdate,
);

export const employerDetailsSelector: Selector<
  RootState,
  ReturnType<typeof employerDetailsReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.employerDetails,
);
