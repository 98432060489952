// TODO: type any will be included in the ban-types soon.
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  useEffect,
  useReducer,
  useState,
  FC,
  ReactNode,
} from 'react';

import SplashScreen from 'src/components/SplashScreen';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';

import { apiConfig } from '../config';
import { themeV1 } from '../types/theme';
import axios from '../utils/axios';

interface DesignState {
  background: {
    background: string | null;
    paper: string | null;
  };
  topBar: {
    background: string | null;
    nameText: string | null;
  };
  navBar: {
    background: string | null;
    mainText: string | null;
    textSecondary: string | null;
    nameText: string | null;
    menu: string | null;
    activeMenu: string | null;
    budgetContainerFull: string | null;
    budgetContainerEmpty: string | null;
    budgetTextFull: string | null;
    budgetTextEmpty: string | null;
    helpContainerButton: string | null;
    helpTextButton: string | null;
  };
  miniCart: {
    background: string | null;
    mainText: string | null;
    secondary: string | null;
    mainButton: string | null;
    mainButtonText: string | null;
    secondaryButton: string | null;
    secondaryButtonText: string | null;
  };
  icons: {
    cart: string | null;
    delete: string | null;
    arrow: string | null;
    edit: string | null;
  };
  forms: {
    inputText: string | null;
    inputLabel: string | null;
    inputLabelFocused: string | null;
    inputBorder: string | null;
    inputBorderFocused: string | null;
    inputBorderError: string | null;
    inputErrorText: string | null;
    inputBorderDisabled: string | null;
    inputTextDisabled: string | null;
    inputLabelDisabled: string | null;
    inputBorderHover: string | null;
  };
  defaults: {
    headerText: string | null;
    breadCrumbs: string | null;
    breadCrumbHighlight: string | null;
    mainText: string | null;
    secondaryText: string | null;
    checkoutSuccessMainText: string | null;
    checkoutSuccessSecondaryText: string | null;
    greenLabelContainer: string | null;
    greenLabelText: string | null;
    orangeLabelContainer: string | null;
    orangeLabelText: string | null;
    redLabelContainer: string | null;
    redLabelText: string | null;
    blueTextButton: string | null;
    blueButton: string | null;
    redTextButton: string | null;
    redButton: string | null;
    greenButtonText: string | null;
    greenButton: string | null;
    grayButton: string | null;
    grayButtonText: string | null;
    whiteButton: string | null;
    whiteButtonText: string | null;
    textButton: string | null;
    tickCheckout: string | null;
    divider: string | null;
  };
  profile_image: any;
  logo: any;
  initial: any;
}

interface DesignContextValue extends DesignState {
  setDesign: (payload: any) => void;
  getDesign: (initial?: any) => Promise<void>;
}

interface CartProviderProps {
  children: ReactNode;
  setTheme: any;
  theme: any;
}

type OpenCartAction = {
  type: 'SET_DESIGN';
  payload: {
    background: {
      background: string;
      paper: string;
    };
    topBar: {
      background: string;
      nameText: string;
    };
    navBar: {
      background: string;
      mainText: string;
      textSecondary: string;
      nameText: string;
      menu: string;
      activeMenu: string;
      budgetContainerFull: string;
      budgetContainerEmpty: string;
      budgetTextFull: string;
      budgetTextEmpty: string;
      helpContainerButton: string;
      helpTextButton: string;
    };
    miniCart: {
      background: string;
      mainText: string;
      secondary: string;
      mainButton: string;
      mainButtonText: string;
      secondaryButton: string;
      secondaryButtonText: string;
    };
    icons: {
      cart: string;
      delete: string;
      arrow: string;
      edit: string;
    };
    forms: {
      inputText: string;
      inputLabel: string;
      inputLabelFocused: string;
      inputBorder: string;
      inputBorderFocused: string;
      inputBorderError: string;
      inputErrorText: string;
      inputBorderDisabled: string;
      inputTextDisabled: string;
      inputLabelDisabled: string;
      inputBorderHover: string;
    };
    defaults: {
      headerText: string;
      breadCrumbs: string;
      breadCrumbHighlight: string;
      mainText: string;
      secondaryText: string;
      checkoutSuccessMainText: string;
      checkoutSuccessSecondaryText: string;
      greenLabelContainer: string;
      greenLabelText: string;
      orangeLabelContainer: string;
      orangeLabelText: string;
      redLabelContainer: string;
      redLabelText: string;
      blueTextButton: string;
      blueButton: string;
      redTextButton: string;
      redButton: string;
      greenButtonText: string;
      greenButton: string;
      grayButton: string;
      grayButtonText: string;
      whiteButton: string;
      whiteButtonText: string;
      textButton: string;
      tickCheckout: string;
      divider: string;
    };
    profile_image: any;
    logo: any;
    initial: any;
  };
};

type Action = OpenCartAction;

const initialDesignState: DesignState = {
  background: {
    background: null,
    paper: null,
  },
  topBar: {
    background: null,
    nameText: null,
  },
  navBar: {
    background: null,
    mainText: null,
    textSecondary: null,
    nameText: null,
    menu: null,
    activeMenu: null,
    budgetContainerFull: null,
    budgetContainerEmpty: null,
    budgetTextFull: null,
    budgetTextEmpty: null,
    helpContainerButton: null,
    helpTextButton: null,
  },
  miniCart: {
    background: null,
    mainText: null,
    secondary: null,
    mainButton: null,
    mainButtonText: null,
    secondaryButton: null,
    secondaryButtonText: null,
  },
  icons: {
    cart: null,
    delete: null,
    arrow: null,
    edit: null,
  },
  forms: {
    inputText: null,
    inputLabel: null,
    inputLabelFocused: null,
    inputBorder: null,
    inputBorderFocused: null,
    inputBorderError: null,
    inputErrorText: null,
    inputBorderDisabled: null,
    inputTextDisabled: null,
    inputLabelDisabled: null,
    inputBorderHover: null,
  },
  defaults: {
    headerText: null,
    breadCrumbs: null,
    breadCrumbHighlight: null,
    mainText: null,
    secondaryText: null,
    checkoutSuccessMainText: null,
    checkoutSuccessSecondaryText: null,
    greenLabelContainer: null,
    greenLabelText: null,
    orangeLabelContainer: null,
    orangeLabelText: null,
    redLabelContainer: null,
    redLabelText: null,
    blueTextButton: null,
    blueButton: null,
    redTextButton: null,
    redButton: null,
    greenButtonText: null,
    greenButton: null,
    grayButton: null,
    grayButtonText: null,
    whiteButton: null,
    whiteButtonText: null,
    textButton: null,
    tickCheckout: null,
    divider: null,
  },
  profile_image: null,
  logo: null,
  initial: null,
};

const reducer = (state: DesignState, action: Action): DesignState => {
  switch (action.type) {
    case 'SET_DESIGN': {
      const payload = action.payload;

      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const DesignContext = createContext<DesignContextValue>({
  ...initialDesignState,
  setDesign: () => {},
  getDesign: () => Promise.resolve(),
});

export const DesignProvider: FC<CartProviderProps> = ({
  setTheme,
  theme,
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialDesignState);
  const { data: user } = useUserProfile();
  const [loader, setLoader] = useState(true);

  const getDesign = async (initial = false) => {
    if (user) {
      if (user.role.name === 'employee') {
        try {
          const employerId = user.employer?.id;
          const response = await axios.get(
            `${apiConfig.url}/employer/${employerId}/theme`,
          );

          const styles = response.data.data.theme;

          styles.initial = initial;
          styles.profile_image = response.data.data.theme.profile_image.url
            ? response.data.data.theme.profile_image.url
            : '';

          const newTheme = {
            ...theme,
            palette: {
              ...theme.palette,
              primary: {
                ...theme.palette.primary,
                main: styles.defaults.blueButton,
                dark: styles.defaults.blueButton,
                light: styles.defaults.blueButton,
              },
              secondary: {
                ...theme.palette.secondary,
                main: styles.defaults.grayButton,
                dark: styles.defaults.grayButton,
                light: styles.defaults.grayButton,
              },
              background: {
                ...theme.palette.background,
                dark: styles.background.background,
                default: styles.background.background,
                paper: styles.background.paper,
              },
              text: {
                ...theme.palette.text,
                primary: styles.defaults.mainText,
                secondary: styles.defaults.secondaryText,
              },
            },
            overrides: {
              MuiOutlinedInput: {
                root: {
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: styles.forms.inputBorderFocused,
                    borderWidth: '1.2px',
                  },
                  '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                    borderColor: styles.forms.inputBorderDisabled,
                    borderWidth: '1.2px',
                  },
                  '& > .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1.2px',
                  },
                },
                notchedOutline: {
                  '&:hover': {
                    borderColor: 'white',
                  },
                  borderColor: styles.forms.inputBorder,
                },
                input: {
                  '&.Mui-disabled': {
                    color: styles.forms.inputTextDisabled,
                  },
                  color: styles.forms.inputText,
                },
              },
              MuiFormLabel: {
                root: {
                  textWrap: 'nowrap',
                  width: '80%',
                  overflowX: 'clip',
                  textOverflow: 'ellipsis',
                  '&.Mui-focused': {
                    width: 'unset',
                    overflowX: 'unset',
                    textWrap: 'unset',
                    color: styles.forms.inputLabelFocused,
                  },
                  '&.Mui-disabled': {
                    color: styles.forms.inputLabelDisabled,
                  },
                  color: styles.forms.inputLabel,
                },
              },
              MuiDivider: {
                root: {
                  backgroundColor: styles.defaults.divider,
                },
              },
            },
            workwizeTheme: {
              ...theme.workwizeTheme,
              navBar: {
                ...theme.workwizeTheme.navBar,
                menu: styles.navBar.menu,
                activeMenu: styles.navBar.activeMenu,
                helpContainerButton: styles.navBar.helpContainerButton,
                helpTextButton: styles.navBar.helpTextButton,
              },
              miniCart: {
                ...theme.workwizeTheme.miniCart,
                background: styles.miniCart.background,
                mainText: styles.miniCart.mainText,
                secondary: styles.miniCart.secondary,
                mainButton: styles.miniCart.mainButton,
                mainButtonText: styles.miniCart.mainButtonText,
                secondaryButton: styles.miniCart.secondaryButton,
                secondaryButtonText: styles.miniCart.secondaryButtonText,
              },
              topBar: {
                ...theme.workwizeTheme.topBar,
                background: styles.topBar.background,
                nameText: styles.topBar.nameText,
              },
              icons: {
                ...theme.workwizeTheme.icons,
                cart: styles.icons.cart,
                delete: styles.icons.delete,
                arrow: styles.icons.arrow,
                edit: styles.icons.edit,
              },
              defaults: {
                ...theme.workwizeTheme.defaults,
                divider: styles.defaults.divider,
                greenLabelContainer: styles.defaults.greenLabelContainer,
                greenLabelText: styles.defaults.greenLabelText,
                orangeLabelContainer: styles.defaults.orangeLabelContainer,
                orangeLabelText: styles.defaults.orangeLabelText,
                redLabelContainer: styles.defaults.redLabelContainer,
                redLabelText: styles.defaults.redLabelText,
                mainText: styles.defaults.mainText,
                secondaryText: styles.defaults.secondaryText,
                checkoutSuccessMainText:
                  styles.defaults.checkoutSuccessMainText,
                checkoutSuccessSecondaryText:
                  styles.defaults.checkoutSuccessSecondaryText,
                whiteButton: styles.defaults.whiteButton,
                whiteButtonText: styles.defaults.whiteButtonText,
                blueButton: styles.defaults.blueButton,
                blueTextButton: styles.defaults.blueTextButton,
              },
            },
          };

          await setTheme(newTheme);

          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await setDesign(styles);
        } catch (err) {
          console.error(err);
        }
      } else {
        const styles: typeof themeV1 & { initial?: boolean } = themeV1;

        styles.initial = initial;
        await setTheme(theme);

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await setDesign(styles);
      }
    } else {
      const styles: typeof themeV1 & { initial?: boolean } = themeV1;

      styles.initial = initial;
      await setTheme(theme);

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      await setDesign(styles);
    }
  };

  const setDesign = (payload: any) => {
    dispatch({
      type: 'SET_DESIGN',
      payload,
    });
  };

  useEffect(() => {
    setLoader(true);
    const initialise = async () => {
      await getDesign(true);

      setLoader(false);
    };

    initialise();
  }, [user?.role.name]);

  if (loader) {
    return <SplashScreen />;
  }

  return (
    <DesignContext.Provider
      value={{
        ...state,
        setDesign,
        getDesign,
      }}
    >
      {children}
    </DesignContext.Provider>
  );
};

export default DesignContext;
