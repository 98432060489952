import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogDuplicateAction,
  CatalogDuplicateActionFail,
  CatalogDuplicateActionRequest,
  CatalogDuplicateActionReset,
  CatalogDuplicateActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import axios from 'src/utils/axios';

export const catalogDuplicate =
  (
    catalog_id: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, CatalogDuplicateAction> =>
  async dispatch => {
    dispatch<CatalogDuplicateActionRequest>({
      type: ActionTypes.CATALOG_DUPLICATE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: Catalog }>(
        `${apiConfig.url}/catalog/${catalog_id}/duplicate`,
      );

      const { data } = res.data;

      dispatch<CatalogDuplicateActionSuccess>({
        type: ActionTypes.CATALOG_DUPLICATE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Catalog successfully duplicated', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<CatalogDuplicateActionFail>({
        type: ActionTypes.CATALOG_DUPLICATE_FAIL,
        payload: 'Something Went Wrong. Cannot Duplicate Catalog',
      });

      enqueueSnackbar('Something Went Wrong. Cannot Duplicate Catalog', {
        variant: 'error',
      });
    }
  };

export const catalogDuplicateReset = (): CatalogDuplicateActionReset => ({
  type: ActionTypes.CATALOG_DUPLICATE_RESET,
});
