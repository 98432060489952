import { ThunkAction } from 'redux-thunk';

import {
  CATALOG_COUNTRY_GET_FAIL,
  CATALOG_COUNTRY_GET_REQUEST,
  CATALOG_COUNTRY_GET_SUCCESS,
} from 'src/action-types/catalog-country-types';
import {
  CatalogCountryGetAction,
  CatalogCountryGetActionFail,
  CatalogCountryGetActionRequest,
  CatalogCountryGetActionSuccess,
} from 'src/actions/catalog-country-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Country } from 'src/types';
import axiosInstance from 'src/utils/axios';

interface GetCatalogCountryParams {
  search?: string;
  limit?: number;
}

export const getCatalogCountry =
  (
    catalogId: string | number,
    params?: GetCatalogCountryParams,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogCountryGetAction
  > =>
  async dispatch => {
    dispatch<CatalogCountryGetActionRequest>({
      type: CATALOG_COUNTRY_GET_REQUEST,
    });

    try {
      const response = await axiosInstance.get<{ data: Country[] }>(
        `${apiConfig.url}/catalogs/${catalogId}/countries`,
        {
          params: {
            search: params?.search,
            limit: params?.limit || 10,
          },
        },
      );

      const { data: countries } = response.data;

      dispatch<CatalogCountryGetActionSuccess>({
        type: CATALOG_COUNTRY_GET_SUCCESS,
        payload: countries,
      });
    } catch (error) {
      dispatch<CatalogCountryGetActionFail>({
        type: CATALOG_COUNTRY_GET_FAIL,
        payload: 'Server error',
      });
    }
  };
