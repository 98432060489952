import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const offboardListsSelector = createSelector(
  [(state: RootState) => state],
  state => state.offboardsList,
);

export const offboardDetailsSelector = createSelector(
  [(state: RootState) => state],
  state => state.offboardDetails,
);

export const offboardUpdateStatusSelector = createSelector(
  [(state: RootState) => state],
  state => state.OffboardUpdateStatus,
);

export const offboardCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.offboardCreate,
);

export const offboardDeleteSelector = createSelector(
  [(state: RootState) => state],
  state => state.offboardDelete,
);
