import { type FC } from 'react';

import { Box, CircularProgress } from '@material-ui/core';

interface HeaderProps {
  isLoading?: boolean;
}

const Loader: FC<HeaderProps> = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <Box
          position="absolute"
          left="50%"
          top="50%"
          zIndex={1400}
          style={{
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default Loader;
