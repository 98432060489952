import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { UpdateDepreciationActionList } from 'src/actions';
import { AssetSetting, ErrorResponseType } from 'src/types';

interface UpdateDepreciationInitialState {
  loading?: boolean;
  success?: boolean;
  error?: ErrorResponseType;
}

interface UpdateDepreciationState extends UpdateDepreciationInitialState {
  settings: AssetSetting | null;
}

export const updateDepreciationReducer: Reducer<
  UpdateDepreciationState,
  UpdateDepreciationActionList
> = (state = { settings: null }, action): UpdateDepreciationState => {
  switch (action.type) {
    case ActionTypes.UPDATE_DEPRECIATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.UPDATE_DEPRECIATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        settings: action.payload,
      };
    case ActionTypes.UPDATE_DEPRECIATION_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.UPDATE_DEPRECIATION_RESET:
      return { settings: null };
    default:
      return state;
  }
};
