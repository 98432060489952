import { useRollbar } from '@rollbar/react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { Department } from 'src/modules/department/validators/departmentValidator';
import axios, { ErrorResponse } from 'src/utils/axios';

export interface IUseCreateEmployeeRoleForUserMutation {
  department: Department;
}

export default function useCreateEmployeeRoleForUserMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const rollbar = useRollbar();

  return useMutation({
    mutationFn: async (data: IUseCreateEmployeeRoleForUserMutation) => {
      await axios.post('/make-employee-account', {
        department_id: data.department.id,
      });

      enqueueSnackbar('Employee created successfully', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorResponse = error as ErrorResponse;

      enqueueSnackbar(
        errorResponse.response?.data.errors?.[0].errors[0] ??
          'Failed sending invite',
        {
          variant: 'error',
        },
      );

      rollbar.error(error as Error);
    },
  });
}
