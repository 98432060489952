import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardLatestRegistrantsActionFail,
  GetDashboardLatestRegistrantsActionList,
  GetDashboardLatestRegistrantsActionRequest,
  GetDashboardLatestRegistrantsActionReset,
  GetDashboardLatestRegistrantsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { DashboardAccounts } from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardLatestRegistrants =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardLatestRegistrantsActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardLatestRegistrantsActionRequest>({
      type: ActionTypes.GET_LATEST_REGISTRANT_INFO_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardAccounts }>(
        `${apiConfig.url}/accounts`,
      );

      const { data } = res.data;

      dispatch<GetDashboardLatestRegistrantsActionSuccess>({
        type: ActionTypes.GET_LATEST_REGISTRANT_INFO_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardLatestRegistrantsActionFail>({
        type: ActionTypes.GET_LATEST_REGISTRANT_INFO_FAIL,
        payload: 'Something Went Wrong While Fetching Accounts Information',
      });
    }
  };

export const getDashboardLatestRegistrantReset =
  (): GetDashboardLatestRegistrantsActionReset => ({
    type: ActionTypes.GET_LATEST_REGISTRANT_INFO_RESET,
  });
