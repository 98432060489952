import { useQuery } from '@tanstack/react-query';

import axios from 'src/utils/axios';

import * as keys from '../constants/use-countries-query-keys';
import { countryListValidator } from '../validators/country-response-schema';

export interface CatalogCountriesParams {
  search?: string;
  limit?: number;
  catalog_id?: string | number;
  enabled?: boolean;
}

const useCountriesQuery = (
  { enabled, ...params }: CatalogCountriesParams = {
    enabled: true,
    limit: 1000,
  },
) => {
  return useQuery(
    [keys.COUNTRIES, params],
    async () => {
      const response = await axios.get('/countries', {
        params,
      });

      const validated = await countryListValidator.parseAsync(response.data);

      return validated.data;
    },
    { staleTime: Infinity, enabled },
  );
};

export default useCountriesQuery;
