/* eslint-disable no-console */
class Analytic {
  private userId: string | undefined;

  private companyId: string | undefined;

  setup(userId: string, companyId: string | undefined) {
    this.userId = userId;
    this.companyId = companyId;
  }

  getUserId() {
    return this.userId;
  }

  getCompanyId() {
    return this.companyId;
  }

  identify(userId: string | number, data: Record<string, unknown>) {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Segment User Identify', userId, data);
        return;
      }
      window.analytics.identify(String(userId), data);
    } catch (e) {
      console.error('Segment User Identify Error', e);
    }
  }

  group(companyId: string, data: Record<string, unknown>) {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Segment Group Identify', companyId, data);
        return;
      }
      window.analytics.group(companyId, data);
    } catch (e) {
      console.error('Segment Group Identify Error', e);
    }
  }

  track(event: string, data: Record<string, unknown> = {}) {
    try {
      if (process.env.NODE_ENV === 'development') {
        console.log('Segment Track', event, data);
        return;
      }
      if (this.companyId) {
        window.analytics.track(event, data, {
          context: { groupId: this.companyId },
        });
      } else {
        window.analytics.track(event, data);
      }
    } catch (e) {
      console.error(`Segment ${event} tracking error`, e);
    }
  }
}

export default Analytic;

export const analytic = new Analytic();
