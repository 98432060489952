import { FormikHelpers } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeCreateAction,
  EmployeeCreateFailAction,
  EmployeeCreateRequestAction,
  EmployeeCreateResetAction,
  EmployeeCreateSuccessAction,
  EmployeeListInfiniteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee } from 'src/types';
import { EmployeeCreateFormikValues } from 'src/types/formikValues';
import axios, { ErrorResponse } from 'src/utils/axios';
import { Province } from 'src/utils/validators/province-validator';

export const createEmployee =
  (
    data: Omit<EmployeeCreateFormikValues, 'region'> & {
      region: string | Province;
    },
    formikHelpers?: FormikHelpers<EmployeeCreateFormikValues>,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeCreateAction | EmployeeListInfiniteActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<EmployeeCreateRequestAction>({
      type: ActionTypes.EMPLOYEE_CREATE_REQUEST,
    });

    data.country_id = data.country?.id;

    try {
      const res = await axios.post<{ data: Employee }>(
        `${apiConfig.url}/employees/store`,
        {
          department_id: data.department_id,
          is_notified: data.is_notified,
          employment_start_date: data.employment_start_date,
          user: {
            name: data.name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
          },
          address: {
            country_id: data.country_id,
            postal_code: data.postal_code,
            address_line_2: data.address_line_2,
            address_line_1: data.address_line_1,
            additional_address_line: data.additional_address_line,
            city: data.city,
            region:
              typeof data.region === 'object' ? data.region.name : data.region,
          },
        },
      );

      const { data: employee } = res.data;

      dispatch<EmployeeCreateSuccessAction>({
        type: ActionTypes.EMPLOYEE_CREATE_SUCCESS,
        payload: employee,
      });

      dispatch<EmployeeListInfiniteActionSuccess>({
        type: ActionTypes.EMPLOYEE_LIST_INFINITE_SUCCESS,
        payload: [...getState().employeeListInfinite.employees, employee],
      });

      enqueueSnackbar('Employee Successfully Created', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const error = e.response?.data;

        if (error && formikHelpers) {
          const formikErrors: Record<string, unknown> = {};

          if (error.errors) {
            error.errors.forEach(err => {
              formikErrors[err.field] = err.errors[0];
            });
          }

          formikHelpers.setErrors(formikErrors);
        }
      }

      dispatch<EmployeeCreateFailAction>({
        type: ActionTypes.EMPLOYEE_CREATE_FAIL,
        payload: 'Something Went Wrong',
      });

      enqueueSnackbar('Something Went Wrong. Cannot Create Employee', {
        variant: 'error',
      });
    }
  };

export const createEmployeeReset = (): EmployeeCreateResetAction => ({
  type: ActionTypes.EMPLOYEE_CREATE_RESET,
});
