import * as ActionTypes from 'src/action-types';
import {
  RequestReturnProductSubmitActionSuccess,
  RequestReturnProductSubmitActionFail,
} from 'src/actions';

export const setRequestReturnProductSubmitSuccess =
  (): RequestReturnProductSubmitActionSuccess => ({
    type: ActionTypes.REQUEST_RETURN_PRODUCT_SUBMIT_SUCCESS,
  });

export const setRequestReturnProductSubmitFail =
  (): RequestReturnProductSubmitActionFail => ({
    type: ActionTypes.REQUEST_RETURN_PRODUCT_SUBMIT_FAIL,
  });
