import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { HelpInfoAction } from 'src/actions';
import { HelpInfo, InitialState } from 'src/types';

interface HelpInfoState extends InitialState {
  helpInfo?: HelpInfo;
}

export const helpInfoReducer: Reducer<HelpInfoState, HelpInfoAction> = (
  state = {},
  action,
) => {
  switch (action.type) {
    case ActionTypes.HELP_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.HELP_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        helpInfo: action.payload,
        success: true,
      };
    case ActionTypes.HELP_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.HELP_INFO_RESET:
      return {};
    default:
      return state;
  }
};
