import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  EmployerCreateAction,
  EmployerDetailsAction,
  EmployerListAction,
  EmployerSettingsUpdateAction,
  EmployerUpdateAction,
  EmployerUpdateType,
} from 'src/actions';
import { Employer, InitialState } from 'src/types';
import { Response } from 'src/types/response';

interface EmployerInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface EmployerCreateState extends EmployerInitialState {
  message?: string;
}

export const employerCreateReducer: Reducer<
  EmployerCreateState,
  EmployerCreateAction
> = (state = {}, action): EmployerCreateState => {
  switch (action.type) {
    case ActionTypes.EMPLOYER_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYER_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.EMPLOYER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.EMPLOYER_CREATE_RESET:
      return state;
    default:
      return state;
  }
};

interface EmployerUpdateState extends EmployerInitialState {
  employer: Partial<EmployerUpdateType>;
}

export const employerUpdateReducer: Reducer<
  EmployerUpdateState,
  EmployerUpdateAction
> = (state = { employer: {} }, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYER_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        employer: action.payload,
        success: true,
      };
    case ActionTypes.EMPLOYER_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYER_UPDATE_RESET:
      return state;
    default:
      return state;
  }
};

interface EmployerListState extends EmployerInitialState {
  employers: Employer[];
  meta?: Response['meta'];
}

const employerListInitialState: EmployerListState = {
  employers: [],
};

export const employerListReducer: Reducer<
  EmployerListState,
  EmployerListAction
> = (state = employerListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employers: action.payload,
        meta: action.meta,
      };
    case ActionTypes.EMPLOYER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

interface EmployerSettingsUpdateState extends EmployerInitialState {
  employer?: Employer;
}

export const employerSettingsUpdateReducer: Reducer<
  EmployerSettingsUpdateState,
  EmployerSettingsUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYER_SETTINGS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYER_SETTINGS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employer: action.payload,
      };
    case ActionTypes.EMPLOYER_SETTINGS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYER_SETTINGS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployerDetailsState extends InitialState {
  employer?: Employer;
}

export const employerDetailsReducer: Reducer<
  EmployerDetailsState,
  EmployerDetailsAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        employer: action.payload,
        success: true,
      };
    case ActionTypes.EMPLOYER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};
