import { useState, useEffect, FC } from 'react';

import { Box, Button, Link, Portal, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';

import { apiConfig } from 'src/config';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { Theme } from 'src/theme';
import axios from 'src/utils/axios';

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      maxWidth: 600,
      position: 'fixed',
      bottom: 0,
      left: 0,
      margin: theme.spacing(3),
      padding: theme.spacing(3),
      outline: 'none',
      zIndex: 2000,
    },
    action: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  }),
);

const CookiesNotification: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: user } = useUserProfile();

  const handleClose = async () => {
    await axios.post(apiConfig.url + `/updatePolicy/${user?.id}`);
    enqueueSnackbar('Accepted Privacy Policy and Cookies', {
      variant: 'success',
    });
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography variant="body1" color="inherit">
          We use cookies to ensure that we give you the best experience on our
          website. By making use of Workwize, you accept our{' '}
          <Link
            component="a"
            color="inherit"
            underline="always"
            href="/privacy-policy"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.action}
          >
            Close
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
