import mixpanel from 'mixpanel-browser';

import { User } from 'src/types';
import { analytic } from 'src/utils/analytic';

const trackUserProfile = (user: User): void => {
  try {
    // Segment Tracker
    analytic.identify(user.id, {
      name: user.name + ' ' + user.last_name,
      email: user.email,
      role_id: user.role.id,
      role_name: user.role.name,
      available_purchase_budget: user.employee?.available_purchase_budget,
      available_rent_budget: user.employee?.available_rent_budget,
      purchase_budget: user.employee?.department?.purchase_budget,
      rent_budget: user.employee?.department?.rent_budget,
      department_id: user.employee?.department_id,
      department_name: user.employee?.department?.name,
      company_name: user.employer?.company_name,
    });

    analytic.group(String(user.employer?.id), {
      id: user.employer?.id,
      name: user.employer?.company_name,
      total_employees: user.employer?.total_employees,
      account_type: user.employer?.account_type,
      role_name: user.role.name,
    });
  } catch (error) {
    console.error('Segment Full Identify Error', error);
    try {
      // Segment Tracker
      analytic.identify(user.id, {
        name: user.name + ' ' + user.last_name,
        email: user.email,
        role_id: user.role.id,
      });

      analytic.group(String(user.employer?.id), {
        id: user.employer?.id,
        name: user.employer?.company_name,
        total_employees: user.employer?.total_employees,
        account_type: user.employer?.account_type,
        role_name: user.role.name,
      });
    } catch (e) {
      console.error('Segment Base Identify Error', e);
    }
  }
  // mixpanel tracker
  try {
    mixpanel.reset();
    mixpanel.identify(user.id);
    if (user.role.id === 3) {
      mixpanel.people.set({
        role_id: user.role.id,
        role_name: user.role.display_name,
        $email: user.email,
        available_purchase_budget: user.employee?.available_purchase_budget,
        available_rent_budget: user.employee?.available_rent_budget,
        purchase_budget: user.employee?.department?.purchase_budget,
        rent_budget: user.employee?.department?.rent_budget,
        department_id: user.employee?.department_id,
        department_name: user.employee?.department?.name,
        last_name: user.last_name,
        first_name: user.name,
        $name: user.name + ' ' + user.last_name,
      });
    }
    if (user.role.id === 2) {
      mixpanel.people.set({
        role_id: user.role.id,
        role_name: user.role.display_name,
        $email: user.email,
        catalog_id: user.employer?.catalog_id,
        city: user.employer?.city,
        company_name: user.employer?.company_name,
        currency_id: user.employer?.currency_id,
        employer_id: user.employer?.id,
        total_employees: user.employer?.total_employees,
        last_name: user.last_name,
        first_name: user.name,
        $name: user.name + ' ' + user.last_name,
      });
    }
    if (user.role.id === 1) {
      mixpanel.people.set({
        role_id: user.role.id,
        role_name: user.role.display_name,
        $email: user.email,
      });
    }
  } catch (error) {
    console.error('mixpanel error', error);
  }

  try {
    window.userGuiding.identify(user.id, {
      firstname: user.name,
      lastname: user.last_name,
      email: user.email,
      role: user.role.name,
      company_name: user.employer?.company_name,
      company_id: user.employer?.id,
    });
  } catch (error) {
    console.error('UserGuiding Error', error);
  }
};

export default trackUserProfile;
