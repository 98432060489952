import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardAvgOrderActionFail,
  GetDashboardAvgOrderActionList,
  GetDashboardAvgOrderActionRequest,
  GetDashboardAvgOrderActionSuccess,
  GetDashboardAvgOrderActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { DashboardAvgOrder } from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardAvgOrder =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardAvgOrderActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardAvgOrderActionRequest>({
      type: ActionTypes.GET_AVG_ORDER_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardAvgOrder }>(
        `${apiConfig.url}/avg-order`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardAvgOrderActionSuccess>({
        type: ActionTypes.GET_AVG_ORDER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardAvgOrderActionFail>({
        type: ActionTypes.GET_AVG_ORDER_FAIL,
        payload: 'Something Went Wrong While Fetching Average Orders',
      });
    }
  };

export const getDashboardAvgOrderResest =
  (): GetDashboardAvgOrderActionReset => ({
    type: ActionTypes.GET_AVG_ORDER_RESET,
  });
