import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeBulkUploadAction,
  EmployeeBulkUploadFailAction,
  EmployeeBulkUploadRequestAction,
  EmployeeBulkUploadResetAction,
  EmployeeBulkUploadSuccessAction,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios, { ErrorResponse } from 'src/utils/axios';

export const bulkUploadEmployee =
  (
    file: FileList,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeBulkUploadAction
  > =>
  async dispatch => {
    dispatch<EmployeeBulkUploadRequestAction>({
      type: ActionTypes.EMPLOYEE_BULK_UPLOAD_REQUEST,
    });

    try {
      const formData = new FormData();

      formData.append('file', file[0]);

      const res = await axios.post<{ data: { message: string } }>(
        apiConfig.url + `/import/invites`,
        formData,
      );

      const { data } = res.data;

      dispatch<EmployeeBulkUploadSuccessAction>({
        type: ActionTypes.EMPLOYEE_BULK_UPLOAD_SUCCESS,
        payload: data.message,
      });

      enqueueSnackbar('Employees successfully uploaded', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const err = e.response?.data;

        if (e.response?.status === 422) {
          const errorsString =
            err?.errors?.map(({ errors, field }) => {
              const rowNumber = field.split('.')[0];

              return `There was an error on row ${rowNumber}. ${errors[0]}`;
            }) ?? [];

          dispatch<EmployeeBulkUploadFailAction>({
            type: ActionTypes.EMPLOYEE_BULK_UPLOAD_FAIL,
            payload: errorsString,
          });
        }

        enqueueSnackbar(
          e.response?.status === 422
            ? 'Uploaded file have invalid rows.'
            : 'Something went wrong.',
          { variant: 'error' },
        );
      }
    }
  };

export const bulkUploadEmployeeReset = (): EmployeeBulkUploadResetAction => ({
  type: ActionTypes.EMPLOYEE_BULK_UPLOAD_RESET,
});
