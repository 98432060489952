import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import {
  EMPLOYER_ANNOUNCEMENT_CREATE_FAIL,
  EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_CREATE_RESET,
  EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_FAIL,
  EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL,
  EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_IMAGE_RESET,
  EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_REQUEST,
  EMPLOYER_ANNOUNCEMENT_RESET,
  EMPLOYER_ANNOUNCEMENT_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL,
  EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_UPDATE_RESET,
  EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS,
} from 'src/action-types';
import {
  EmployerAnnouncementAction,
  EmployerAnnouncementActionFail,
  EmployerAnnouncementActionRequest,
  EmployerAnnouncementActionReset,
  EmployerAnnouncementActionSuccess,
  EmployerAnnouncementCreateAction,
  EmployerAnnouncementCreateActionFail,
  EmployerAnnouncementCreateActionRequest,
  EmployerAnnouncementCreateActionReset,
  EmployerAnnouncementCreateActionSuccess,
  EmployerAnnouncementImageAction,
  EmployerAnnouncementImageActionFail,
  EmployerAnnouncementImageActionRequest,
  EmployerAnnouncementImageActionReset,
  EmployerAnnouncementImageActionSuccess,
  EmployerAnnouncementImageType,
  EmployerAnnouncementType,
  EmployerAnnouncementUpdateAction,
  EmployerAnnouncementUpdateActionFail,
  EmployerAnnouncementUpdateActionRequest,
  EmployerAnnouncementUpdateActionReset,
  EmployerAnnouncementUpdateActionSuccess,
  EmployerAnnouncementUploadImageAction,
  EmployerAnnouncementUploadImageActionFail,
  EmployerAnnouncementUploadImageActionRequest,
  EmployerAnnouncementUploadImageActionReset,
  EmployerAnnouncementUploadImageActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios, { ErrorResponse } from 'src/utils/axios';

export const getEmployerAnnouncement =
  (
    employerId: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerAnnouncementAction
  > =>
  async dispatch => {
    dispatch<EmployerAnnouncementActionRequest>({
      type: EMPLOYER_ANNOUNCEMENT_REQUEST,
    });

    try {
      const res = await axios.get<{
        data: EmployerAnnouncementType;
      }>(`${apiConfig.url}/employer/${employerId}/announcement`);

      const { data } = res.data;

      dispatch<EmployerAnnouncementActionSuccess>({
        type: EMPLOYER_ANNOUNCEMENT_SUCCESS,
        payload: data,
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<EmployerAnnouncementActionFail>({
          type: EMPLOYER_ANNOUNCEMENT_FAIL,
          payload: e.response?.data.data.message ?? 'Server Error',
        });

        enqueueSnackbar(
          e.response?.data.data.message ?? 'Unable to fetch Announcement',
          { variant: 'error' },
        );
      }
    }
  };

export const getEmployerAnnouncementReset =
  (): EmployerAnnouncementActionReset => ({
    type: EMPLOYER_ANNOUNCEMENT_RESET,
  });

export const createEmployerAnnouncement =
  (
    employerId: string,
    employerAnnouncementMessageData: {
      message: string | null;
      message_type: 'message_text' | 'message_html';
    },
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerAnnouncementCreateAction
  > =>
  async dispatch => {
    dispatch<EmployerAnnouncementCreateActionRequest>({
      type: EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST,
    });
    try {
      const res = await axios.post<{ data: { message: string } }>(
        apiConfig.url + `/employer/${employerId}/announcement`,
        { ...employerAnnouncementMessageData },
      );
      const { data } = res.data;

      dispatch<EmployerAnnouncementCreateActionSuccess>({
        type: EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS,
        payload: data.message,
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<EmployerAnnouncementCreateActionFail>({
          type: EMPLOYER_ANNOUNCEMENT_CREATE_FAIL,
          payload: e.response?.data.data?.message ?? 'Server Error',
        });

        enqueueSnackbar(
          e.response?.data.data?.message ?? 'Unable to saved Announcement',
          { variant: 'error' },
        );
      }
    }
  };

export const createEmployerAnnouncementReset =
  (): EmployerAnnouncementCreateActionReset => ({
    type: EMPLOYER_ANNOUNCEMENT_CREATE_RESET,
  });

export const updateEmployerAnnouncement =
  (
    employerId: string,
    employerAnnouncementMessageData: {
      message: string | null;
      message_type: 'message_html' | 'message_text';
    },
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerAnnouncementUpdateAction
  > =>
  async dispatch => {
    dispatch<EmployerAnnouncementUpdateActionRequest>({
      type: EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST,
    });
    try {
      const res = await axios.put<{ data: { message: string } }>(
        apiConfig.url + `/employer/${employerId}/announcement`,
        { ...employerAnnouncementMessageData },
      );
      const { data } = res.data;

      dispatch<EmployerAnnouncementUpdateActionSuccess>({
        type: EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS,
        payload: data.message,
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<EmployerAnnouncementUpdateActionFail>({
          type: EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL,
          payload: e.response?.data.data?.message ?? 'Server Error',
        });

        enqueueSnackbar(
          e.response?.data.data?.message ?? 'Unable to saved Announcement',
          { variant: 'error' },
        );
      }
    }
  };

export const updateEmployerAnnouncementReset =
  (): EmployerAnnouncementUpdateActionReset => ({
    type: EMPLOYER_ANNOUNCEMENT_UPDATE_RESET,
  });

export const uploadImageEmployerAnnouncement =
  (
    data: FormData,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerAnnouncementUploadImageAction
  > =>
  async dispatch => {
    dispatch<EmployerAnnouncementUploadImageActionRequest>({
      type: EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: { image_path: string } }>(
        apiConfig.url + `/announcement/upload-image`,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      const { data: resData } = res.data;

      dispatch<EmployerAnnouncementUploadImageActionSuccess>({
        type: EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS,
        payload: resData.image_path,
      });

      enqueueSnackbar('File uploaded', { variant: 'success' });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<EmployerAnnouncementUploadImageActionFail>({
          type: EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL,
          payload: e.response?.data.data?.message ?? 'Server Error',
        });

        enqueueSnackbar(
          e.response?.data.data?.message ?? 'Unable to saved Announcement',
          { variant: 'error' },
        );
      }
    }
  };

export const uploadImageEmployerAnnouncementReset =
  (): EmployerAnnouncementUploadImageActionReset => ({
    type: EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET,
  });

export const getImageEmployerAnnouncement =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployerAnnouncementImageAction
  > =>
  async dispatch => {
    dispatch<EmployerAnnouncementImageActionRequest>({
      type: EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST,
    });

    try {
      const res = await axios.get<{
        data: { images: EmployerAnnouncementImageType[]; root_dir: string };
      }>(apiConfig.url + `/announcement/images`);

      const { data } = res.data;

      dispatch<EmployerAnnouncementImageActionSuccess>({
        type: EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS,
        payload: {
          files: data.images.map(image => ({
            ...image,
            path: `${data.root_dir}/${image.path}`,
          })),
          rootDir: data.root_dir,
        },
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<EmployerAnnouncementImageActionFail>({
          type: EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL,
          payload: e.response?.data.data?.message ?? 'Server Error',
        });
      }
    }
  };

export const getImageEmployerAnnouncementReset =
  (): EmployerAnnouncementImageActionReset => ({
    type: EMPLOYER_ANNOUNCEMENT_IMAGE_RESET,
  });
