import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  UserStatusUpdateAction,
  UserStatusUpdateActionRequest,
  UserStatusUpdateActionSuccess,
  UserStatusUpdateActionFail,
  EmployeeDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee, User } from 'src/types';
import axios from 'src/utils/axios';

export const updateUserStatus =
  (
    id: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    UserStatusUpdateAction | EmployeeDetailsActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<UserStatusUpdateActionRequest>({
      type: ActionTypes.USER_STATUS_UPDATE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: User }>(
        `${apiConfig.url}/user/status/update`,
        { id },
      );

      const { data } = res.data;

      dispatch<UserStatusUpdateActionSuccess>({
        type: ActionTypes.USER_STATUS_UPDATE_SUCCESS,
        payload: data,
      });

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: {
          ...getState().employeeDetails.employee,
          user: {
            ...getState().employeeDetails.employee?.user,
            is_deactivated: data.employee?.user?.is_deactivated,
          },
        } as Employee,
      });
    } catch (err) {
      dispatch<UserStatusUpdateActionFail>({
        type: ActionTypes.USER_STATUS_UPDATE_FAIL,
        payload: 'Something Went Wrong. Failed Updating User Status',
      });
    }
  };

export const updateUserStatusReset = () => ({
  type: ActionTypes.USER_STATUS_UPDATE_RESET,
});
