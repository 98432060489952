import { createContext, useEffect, useReducer, FC, ReactNode } from 'react';

import { apiConfig } from 'src/config';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import axios from 'src/utils/axios';

import { Currency } from '../types/currency';

interface CurrencyState {
  currency: Currency | null;
  loading?: boolean;
}

interface CurrencyContextValue extends CurrencyState {
  getCurrency: () => Promise<void>;
}

interface CartProviderProps {
  children: ReactNode;
}

type OpenCartAction = {
  type: 'SET_CURRENCY';
  payload: {
    currency: Currency;
  };
};

type Action = OpenCartAction;

const initialCurrencyState: CurrencyState = {
  currency: null,
  loading: true,
};

const reducer = (state: CurrencyState, action: Action): CurrencyState => {
  switch (action.type) {
    case 'SET_CURRENCY': {
      const { currency } = action.payload;

      return {
        ...state,
        loading: false,
        currency,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const CurrencyContext = createContext<CurrencyContextValue>({
  ...initialCurrencyState,
  getCurrency: () => Promise.resolve(),
});

export const CurrencyProvider: FC<CartProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialCurrencyState);
  const { data: user } = useUserProfile();

  const getCurrency = async () => {
    if (user && user.role.name !== 'admin') {
      try {
        const response = await axios.get(apiConfig.url + `/current-currency`);

        dispatch({
          type: 'SET_CURRENCY',
          payload: {
            currency: response.data.data.currency,
          },
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    const initialise = async () => {
      await getCurrency();
    };

    initialise();
  }, [user?.role.name]);

  return (
    <CurrencyContext.Provider
      value={{
        ...state,
        getCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContext;
