import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { GetAssetSettingsActionList } from 'src/actions';
import { AssetSetting } from 'src/types';

interface GetAssetSettingsInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface GetAssetSettingsState extends GetAssetSettingsInitialState {
  settings?: AssetSetting;
}

export const assetGetSettingsReducer: Reducer<
  GetAssetSettingsState,
  GetAssetSettingsActionList
> = (state = {}, action): GetAssetSettingsState => {
  switch (action.type) {
    case ActionTypes.GET_ASSET_SETTING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ASSET_SETTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        settings: action.payload,
      };
    case ActionTypes.GET_ASSET_SETTING_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.GET_ASSET_SETTING_RESET:
      return {};
    default:
      return state;
  }
};
