import React, { FC } from 'react';

import {
  TextField as MuiTextField,
  TextFieldProps,
  styled,
} from '@mui/material';

const StyledTextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '1.2px',
    },
  },
});

export const TextField: FC<TextFieldProps> = props => {
  return <StyledTextField {...props}>{props.children}</StyledTextField>;
};
