import { ThunkAction } from 'redux-thunk';
import { v4 as uuidv4 } from 'uuid';

import * as ActionTypes from 'src/action-types';
import {
  CartProductListAction,
  CartProductListActionFail,
  CartProductListActionRequest,
  CartProductListActionReset,
  CartProductListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Cart, Response } from 'src/types';
import axios from 'src/utils/axios';

import { getCartProductListAnalytics } from './getCartProductListAnalytics';

export const getCartProductList =
  (): ThunkAction<Promise<void>, RootState, undefined, CartProductListAction> =>
  async dispatch => {
    dispatch<CartProductListActionRequest>({
      type: ActionTypes.CART_PRODUCT_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<Cart>>(
        `${apiConfig.url}/cart-products`,
      );

      const { data } = res.data;

      getCartProductListAnalytics(data);

      dispatch<CartProductListActionSuccess>({
        type: ActionTypes.CART_PRODUCT_LIST_SUCCESS,
        payload: {
          total_buy: data.total_buy,
          total_rent: data.total_rent,
          cart: data.cart.map(cartProduct => ({
            ...cartProduct,
            key: uuidv4(),
          })),
        },
      });
    } catch (e) {
      dispatch<CartProductListActionFail>({
        type: ActionTypes.CART_PRODUCT_LIST_FAIL,
        payload: 'Unable to fetch the data.',
      });
    }
  };

export const getCartProductListReset = (): CartProductListActionReset => ({
  type: ActionTypes.CART_PRODUCT_LIST_RESET,
});
