import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeOrderListAction,
  EmployeeOrderListActionFail,
  EmployeeOrderListActionRequest,
  EmployeeOrderListActionReset,
  EmployeeOrderListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Order } from 'src/types';
import axios from 'src/utils/axios';

export const getEmployeeOrderList =
  (
    employeeId: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    EmployeeOrderListAction
  > =>
  async dispatch => {
    dispatch<EmployeeOrderListActionRequest>({
      type: ActionTypes.EMPLOYEE_ORDER_LIST_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Order[] }>(
        `${apiConfig.url}/employees/${employeeId}/orders`,
      );

      const { data } = res.data;
      // let orders = data;

      // if (excludeOffboarded && orders.length > 0) {
      //   orders.forEach(function(order) {
      //     order.products.filter(product => {
      //       if (product.order.variant == null) {
      //         return product.can_be_offboarded == true;
      //       } else {
      //         return product.order.variant.can_be_offboarded == true;
      //       }
      //     });
      //   });

      //   console.log(orders);
      // }

      dispatch<EmployeeOrderListActionSuccess>({
        type: ActionTypes.EMPLOYEE_ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<EmployeeOrderListActionFail>({
        type: ActionTypes.EMPLOYEE_ORDER_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getEmployeeOrderListReset = (): EmployeeOrderListActionReset => ({
  type: ActionTypes.EMPLOYEE_ORDER_LIST_RESET,
});
