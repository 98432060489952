import { Reducer } from 'redux';

import {
  EMPLOYER_ANNOUNCEMENT_CREATE_FAIL,
  EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_CREATE_RESET,
  EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_FAIL,
  EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL,
  EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_IMAGE_RESET,
  EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_REQUEST,
  EMPLOYER_ANNOUNCEMENT_RESET,
  EMPLOYER_ANNOUNCEMENT_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL,
  EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_UPDATE_RESET,
  EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET,
  EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS,
} from 'src/action-types';
import {
  EmployerAnnouncementAction,
  EmployerAnnouncementUpdateAction,
  EmployerAnnouncementCreateAction,
  EmployerAnnouncementType,
  EmployerAnnouncementUploadImageAction,
  EmployerAnnouncementImageType,
  EmployerAnnouncementImageAction,
} from 'src/actions';

interface EmployerAnnouncementInitialState {
  loading?: boolean;
  error?: string;
  success?: boolean;
}

interface EmployerAnnouncementState extends EmployerAnnouncementInitialState {
  announcement?: EmployerAnnouncementType;
}

export const employerAnnouncementReducer: Reducer<
  EmployerAnnouncementState,
  EmployerAnnouncementAction
> = (state = {}, action): EmployerAnnouncementState => {
  switch (action.type) {
    case EMPLOYER_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        announcement: action.payload,
      };
    case EMPLOYER_ANNOUNCEMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case EMPLOYER_ANNOUNCEMENT_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployerAnnouncementCreateState
  extends EmployerAnnouncementInitialState {
  message?: string;
}

export const employerAnnouncementCreateReducer: Reducer<
  EmployerAnnouncementCreateState,
  EmployerAnnouncementCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYER_ANNOUNCEMENT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_ANNOUNCEMENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        success: true,
      };
    case EMPLOYER_ANNOUNCEMENT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case EMPLOYER_ANNOUNCEMENT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployerAnnouncementUpdateState
  extends EmployerAnnouncementInitialState {
  message?: string;
}

export const employerAnnouncementUpdateReducer: Reducer<
  EmployerAnnouncementUpdateState,
  EmployerAnnouncementUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYER_ANNOUNCEMENT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_ANNOUNCEMENT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        success: true,
      };
    case EMPLOYER_ANNOUNCEMENT_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case EMPLOYER_ANNOUNCEMENT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployerAnnouncementUploadImageState
  extends EmployerAnnouncementInitialState {
  message?: string;
}

export const employerAnnouncementUploadImageReducer: Reducer<
  EmployerAnnouncementUploadImageState,
  EmployerAnnouncementUploadImageAction
> = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        success: true,
      };
    case EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case EMPLOYER_ANNOUNCEMENT_UPLOAD_IMAGE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployerAnnouncementImageState
  extends EmployerAnnouncementInitialState {
  files: EmployerAnnouncementImageType[];
  rootDir: string;
}

export const employerAnnouncementImageReducer: Reducer<
  EmployerAnnouncementImageState,
  EmployerAnnouncementImageAction
> = (state = { files: [], rootDir: '' }, action) => {
  switch (action.type) {
    case EMPLOYER_ANNOUNCEMENT_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYER_ANNOUNCEMENT_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        files: action.payload.files,
        rootDir: action.payload.rootDir,
        success: true,
      };
    case EMPLOYER_ANNOUNCEMENT_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case EMPLOYER_ANNOUNCEMENT_IMAGE_RESET:
      return state;
    default:
      return state;
  }
};
