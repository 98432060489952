import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { InvitationResendUnacceptedAction } from 'src/actions';
import { InvitationResendSingleEmployeeAction } from 'src/actions/invitation-action';

interface InvitationInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

export const invitationResendUnacceptedReducer: Reducer<
  InvitationInitialState,
  InvitationResendUnacceptedAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.INVITATION_RESEND_UNACCEPTED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.INVITATION_RESEND_UNACCEPTED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.INVITATION_RESEND_UNACCEPTED_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.INVITATION_RESEND_UNACCEPTED_RESET:
      return {};
    default:
      return state;
  }
};

export const invitationResendSingleEmployeeReducer: Reducer<
  InvitationInitialState,
  InvitationResendSingleEmployeeAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.INVITATION_RESEND_SINGLE_EMPLOYEE_RESET:
      return {};
    default:
      return state;
  }
};
