import { FC } from 'react';

import { Dialog, DialogContent, Divider, Box } from '@material-ui/core';

import DialogFooter from './footer';
import DialogHeader from './header';
import { DialogModalProps } from './interface';
import { useDialogContentStyles, useAlignmentStyles } from './styles';

const DialogModal: FC<DialogModalProps> = ({
  open,
  size,
  onClose,
  header,
  footer,
  divider,
  topDivider,
  bottomDivider,
  alignment,
  dialogContentProps,
  children,
  enclosedInDialogContent = true,
  disableEnforceFocus = false,
}) => {
  const styles = useDialogContentStyles();
  const align = useAlignmentStyles();

  let contentAlignment = '';

  switch (alignment) {
    case 'center':
      contentAlignment = align.center;
      break;
    case 'right':
      contentAlignment = align.right;
      break;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={size}
      disableEnforceFocus={disableEnforceFocus}
    >
      {header && (
        <DialogHeader
          title={header.title}
          alignment={header.alignment}
          closeIcon={header.closeIcon}
          handleIconClick={onClose}
        />
      )}
      {(divider || topDivider) && <Divider />}

      {enclosedInDialogContent ? (
        <DialogContent
          className={`${styles.contentWrapper} ${contentAlignment}`}
          {...dialogContentProps}
        >
          {children}
        </DialogContent>
      ) : (
        <Box>{children}</Box>
      )}

      {(divider || bottomDivider) && <Divider />}
      {footer && (
        <DialogFooter
          buttons={footer.buttons}
          alignment={footer.alignment}
          children={footer.leftText}
        />
      )}
    </Dialog>
  );
};

export default DialogModal;
