import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { UserStatusUpdateAction } from 'src/actions';

interface UserStatusInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

export const userStatusUpdateReducer: Reducer<
  UserStatusInitialState,
  UserStatusUpdateAction
> = (state = {}, action): UserStatusInitialState => {
  switch (action.type) {
    case ActionTypes.USER_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.USER_STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.USER_STATUS_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.USER_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
