import { Reducer } from 'redux';

import {
  PLACE_ORDER_SUBMIT_FAIL,
  PLACE_ORDER_SUBMIT_SUCCESS,
} from 'src/action-types';
import { PlaceOrderSubmitAction } from 'src/actions';

interface PlaceOrderSubmitState {
  loading: boolean;
  success: boolean;
}

export const placeOrderSubmitReducer: Reducer<
  PlaceOrderSubmitState,
  PlaceOrderSubmitAction
> = (
  state = {
    loading: false,
    success: false,
  },
  action,
) => {
  switch (action.type) {
    case PLACE_ORDER_SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case PLACE_ORDER_SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    default:
      return state;
  }
};
