import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CartProductAddAction,
  CartProductAddActionFail,
  CartProductAddActionRequest,
  CartProductAddActionReset,
  CartProductAddActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { analytic } from 'src/utils/analytic';
import axios, { ErrorResponse } from 'src/utils/axios';
import setArrayErrorsToObject from 'src/utils/setArrayErrorsToObject';

export interface AddCartProductData {
  product_id: string | number;
  product_quantity: number;
  update: boolean;
  variant_id?: string | number | null;
  budget_id?: string | number;
}

export const addCartProduct =
  (
    addCartProductData: AddCartProductData,
    autoApprove: boolean,
    key?: string,
  ): ThunkAction<Promise<void>, RootState, undefined, CartProductAddAction> =>
  async (dispatch, getState) => {
    try {
      await axios.post(`${apiConfig.url}/product/cart/add`, addCartProductData);

      dispatch<CartProductAddActionRequest>({
        type: ActionTypes.CART_PRODUCT_ADD_REQUEST,
      });

      const cartProducts = getState().cartProductList.cartProducts?.cart.map(
        cartProduct =>
          cartProduct.key === key
            ? {
                ...cartProduct,
                product_quantity: addCartProductData.product_quantity,
              }
            : cartProduct,
      );

      const totalBuy = cartProducts
        ?.filter(cartProduct => cartProduct.product.type === 'Buy')
        ?.reduce((prev, curr) => {
          return prev + curr.product_quantity * Number(curr.product.price);
        }, 0)
        .toFixed(2);

      const totalRent = cartProducts
        ?.filter(cartProduct => cartProduct.product.type === 'Rent')
        ?.reduce((prev, curr) => {
          return prev + curr.product_quantity * Number(curr.product.price);
        }, 0)
        .toFixed(2);

      if (cartProducts) {
        dispatch<CartProductAddActionSuccess>({
          type: ActionTypes.CART_PRODUCT_ADD_SUCCESS,
          payload: {
            ...cartProducts,
            total_buy: Number(autoApprove ? totalBuy : 0),
            total_rent: Number(autoApprove ? totalRent : 0),
            cart: cartProducts,
          },
          productId: addCartProductData.product_id,
        });
      }
    } catch (e) {
      if (e instanceof ErrorResponse) {
        const errors = setArrayErrorsToObject(e.response?.data.errors);

        if (errors) {
          dispatch<CartProductAddActionFail>({
            type: ActionTypes.CART_PRODUCT_ADD_FAIL,
            payload: errors,
            productId: addCartProductData.product_id,
          });

          if ('available_stock' in errors) {
            // Segment tracker
            analytic.track(errors.available_stock[0], {
              product_id: addCartProductData.product_id,
            });
          }
        }
      }
    }
  };

export const addCartProductReset = (): CartProductAddActionReset => ({
  type: ActionTypes.CART_PRODUCT_ADD_RESET,
});
