import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  CartOpenDrawerAction,
  CartProductAddAction,
  CartProductAddActionFail,
  CartProductAddActionSuccess,
  CartProductListAction,
  CartProductListActionSuccess,
  CartProductRemoveAction,
  CartProductRemoveActionSuccess,
  OrderCheckoutActionSuccess,
} from 'src/actions';
import { InitialState } from 'src/types';

interface CartOpenDrawerState extends InitialState {
  isOpen: boolean;
}

export const cartOpenDrawerReducer: Reducer<
  CartOpenDrawerState,
  CartOpenDrawerAction
> = (state = { isOpen: false }, action) => {
  switch (action.type) {
    case ActionTypes.CART_OPEN_DRAWER:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

interface CartProductListState extends InitialState {
  cartProducts?: CartProductListActionSuccess['payload'];
}

export const cartProductListReducer: Reducer<
  CartProductListState,
  | CartProductListAction
  | CartProductRemoveActionSuccess
  | CartProductAddActionSuccess
  | OrderCheckoutActionSuccess
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CART_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CART_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        cartProducts: action.payload,
      };
    case ActionTypes.CART_PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CART_PRODUCT_REMOVE_SUCCESS:
      return {
        ...state,
        cartProducts: action.payload,
      };
    case ActionTypes.CART_PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        cartProducts: action.payload,
      };
    case ActionTypes.CART_PRODUCT_LIST_RESET:
    case ActionTypes.ORDER_CHECKOUT_SUCCESS:
      const cartProducts: CartProductListActionSuccess['payload'] = {
        total_buy: 0,
        total_rent: 0,
        cart: [],
      };

      return {
        cartProducts,
        loading: false,
      };
    default:
      return state;
  }
};

interface CartProductRemoveState extends InitialState {
  productId?: CartProductRemoveActionSuccess['productId'];
}

export const cartProductRemoveReducer: Reducer<
  CartProductRemoveState,
  CartProductRemoveAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CART_PRODUCT_REMOVE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CART_PRODUCT_REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        productId: action.productId,
      };
    case ActionTypes.CART_PRODUCT_REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        productId: action.productId,
      };
    case ActionTypes.CART_PRODUCT_REMOVE_RESET:
      return {};
    default:
      return state;
  }
};

interface CartProductAddState
  extends InitialState<CartProductAddActionFail['payload']> {
  productId?: CartProductAddActionSuccess['productId'];
}

export const cartProductAddReducer: Reducer<
  CartProductAddState,
  CartProductAddAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CART_PRODUCT_ADD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CART_PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        productId: action.productId,
      };
    case ActionTypes.CART_PRODUCT_ADD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        productId: action.productId,
      };
    case ActionTypes.CART_PRODUCT_ADD_RESET:
      return {};
    default:
      return state;
  }
};
