export const OFFBOARD_LIST_REQUEST = 'OFFBOARD_LIST_REQUEST';
export const OFFBOARD_LIST_SUCCESS = 'OFFBOARD_LIST_SUCCESS';
export const OFFBOARD_LIST_FAIL = 'OFFBOARD_LIST_FAIL';
export const OFFBOARD_LIST_RESET = 'OFFBOARD_LIST_RESET';

export const OFFBOARD_DETAILS_REQUEST = 'OFFBOARD_DETAILS_REQUEST';
export const OFFBOARD_DETAILS_SUCCESS = 'OFFBOARD_DETAILS_SUCCESS';
export const OFFBOARD_DETAILS_FAIL = 'OFFBOARD_DETAILS_FAIL';
export const OFFBOARD_DETAILS_RESET = 'OFFBOARD_DETAILS_RESET';

export const OFFBOARD_UPDATE_STATUS_REQUEST = 'OFFBOARD_UPDATE_STATUS_REQUEST';
export const OFFBOARD_UPDATE_STATUS_SUCCESS = 'OFFBOARD_UPDATE_STATUS_SUCCESS';
export const OFFBOARD_UPDATE_STATUS_FAIL = 'OFFBOARD_UPDATE_STATUS_FAIL';
export const OFFBOARD_UPDATE_STATUS_RESET = 'OFFBOARD_UPDATE_STATUS_RESET';

export const OFFBOARD_CREATE_REQUEST = 'OFFBOARD_CREATE_REQUEST';
export const OFFBOARD_CREATE_SUCCESS = 'OFFBOARD_CREATE_SUCCESS';
export const OFFBOARD_CREATE_FAIL = 'OFFBOARD_CREATE_FAIL';
export const OFFBOARD_CREATE_RESET = 'OFFBOARD_CREATE_RESET';

export const OFFBOARD_DELETE_REQUEST = 'OFFBOARD_DELETE_REQUEST';
export const OFFBOARD_DELETE_SUCCESS = 'OFFBOARD_DELETE_SUCCESS';
export const OFFBOARD_DELETE_FAIL = 'OFFBOARD_DELETE_FAIL';
export const OFFBOARD_DELETE_RESET = 'OFFBOARD_DELETE_RESET';
