import { createSelector, Selector } from 'reselect';

import { colorListInfiniteReducer } from 'src/reducers/color-reducer';
import { RootState } from 'src/store';

export const colorListInfiniteSelector: Selector<
  RootState,
  ReturnType<typeof colorListInfiniteReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.colorListInfinite,
);
