import React, { forwardRef, useImperativeHandle } from 'react';

import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';

interface InfoPopupProps {
  text: string;
  // TODO: type any will be included in the ban-types soon.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterClose: any;
}

// TODO: type any will be included in the ban-types soon.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InfoPopup = forwardRef<any, InfoPopupProps>(
  ({ text, afterClose }, ref) => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
      setOpen(false);
      afterClose();
    };

    useImperativeHandle(ref, () => ({
      // TODO: type any will be included in the ban-types soon.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setOpen(state: any) {
        setOpen(state);
      },
    }));

    text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

    return (
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent style={{ minWidth: 300 }}>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{ __html: text }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  },
);

export default InfoPopup;
