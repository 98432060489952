import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetOrderListActionFail,
  GetOrderListActionRequest,
  GetOrderListActionSuccess,
  GetOrderListActionList,
  GetOrderListActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Order } from 'src/types';
import axios from 'src/utils/axios';

export const getOrderLists =
  (
    isAdmin = false,
    expand: string,
    filter?: string,
    limit?: string,
    page?: string,
    sort?: string,
  ): ThunkAction<Promise<void>, RootState, undefined, GetOrderListActionList> =>
  async dispatch => {
    dispatch<GetOrderListActionRequest>({
      type: ActionTypes.GET_ORDER_LIST_REQUEST,
    });

    try {
      const adminURL = isAdmin ? '/admin' : '';
      const filterParam = filter ? `&filter=${filter}` : '';
      const limitParam = limit ? `&limit=${limit}` : `&limit=10`;
      const pageParam = page ? `&page=${page}` : `&page=1`;
      const sortParam = sort ? `&sort=${sort}` : '';

      const res = await axios.get<{ data: Order[] }>(
        `${apiConfig.url}${adminURL}/orders?include=${expand}${filterParam}${limitParam}${pageParam}${sortParam}`,
      );

      const { data } = res.data;

      dispatch<GetOrderListActionSuccess>({
        type: ActionTypes.GET_ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<GetOrderListActionFail>({
        type: ActionTypes.GET_ORDER_LIST_FAIL,
        payload: 'Something Went Wrong while fetching orders',
      });
    }
  };

export const getOrderListReset = (): GetOrderListActionReset => ({
  type: ActionTypes.GET_ORDER_LIST_RESET,
});
