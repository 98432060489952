import { z } from 'zod';

import { entityVatNumberValidator } from 'src/utils/validators/entity-vat-number-validator';

export const entityValidator = z.object({
  id: z.number(),
  name: z.string(),
  employer_id: z.number(),
  entity_vat_numbers: entityVatNumberValidator.array().optional(),
});
