import React, { FC, Suspense } from 'react';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider, StylesProvider, jssPreset } from '@material-ui/styles';
import { ThemeProvider as V5ThemeProvider } from '@mui/material/styles';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import mixpanel from 'mixpanel-browser';
import { Router } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import { MuiV5ThemeResponsiveFont } from 'src/theme/MuiV5/colorPalette';

import LoginHOC from './components/LoginHOC';
import Main from './components/Main';

try {
  if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN)
    mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN);
} catch (error) {}

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const history = createBrowserHistory();

const App: FC = () => {
  const { settings } = useSettings();

  const defaultTheme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const [theme, setTheme] = React.useState(defaultTheme);

  return (
    <Suspense fallback={'...loading'}>
      <V5ThemeProvider theme={MuiV5ThemeResponsiveFont}>
        <ThemeProvider theme={theme}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Router history={history}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                  <LoginHOC>
                    <Main theme={theme} setTheme={setTheme} />
                  </LoginHOC>
                </QueryParamProvider>
              </Router>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </V5ThemeProvider>
    </Suspense>
  );
};

export default App;
