import { FC } from 'react';

import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import useUserProfile from 'src/modules/user/hooks/useUserProfile';

const AdminGuard: FC = ({ children }) => {
  const { data: user, isLoading: loading } = useUserProfile();

  if (user?.role.name !== 'admin' && !loading) {
    return <Redirect to="/403" />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;
