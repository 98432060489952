/* eslint-disable react-hooks/rules-of-hooks */
import {
  useDispatch as reduxUseDispatch,
  useSelector as reduxUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from 'src/action-creators';
import { RootState } from 'src/store';

export const useSelector: TypedUseSelectorHook<RootState> = reduxUseSelector;

export const useDispatch = () => {
  const dispatch = reduxUseDispatch();

  return bindActionCreators(actionCreators, dispatch);
};
