import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogDetailsAction,
  CatalogDetailsActionFail,
  CatalogDetailsActionRequest,
  CatalogDetailsActionReset,
  CatalogDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Catalog } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const getCatalogDetails =
  (
    catalogId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, CatalogDetailsAction> =>
  async dispatch => {
    dispatch<CatalogDetailsActionRequest>({
      type: ActionTypes.CATALOG_DETAILS_REQUEST,
    });

    try {
      const res = await axios.get<Response<Catalog>>(
        `${apiConfig.url}/catalogs/${catalogId}`,
      );

      const { data } = res.data;

      dispatch<CatalogDetailsActionSuccess>({
        type: ActionTypes.CATALOG_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<CatalogDetailsActionFail>({
        type: ActionTypes.CATALOG_DETAILS_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCatalogDetailsReset = (): CatalogDetailsActionReset => ({
  type: ActionTypes.CATALOG_DETAILS_RESET,
});
