import { FC, useEffect } from 'react';

import { getCartProductList } from 'src/action-creators';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import SplashScreen from 'src/components/SplashScreen';
import { AddProductsToCatalogProvider } from 'src/contexts/AddProductToCatalogProvider';
import { AdminProductListFilterProvider } from 'src/contexts/AdminProductListFilterProvider';
import { CartProvider } from 'src/contexts/CartContext';
import { CurrencyProvider } from 'src/contexts/CurrencyContext';
import { DepartmentProvider } from 'src/contexts/DepartmentContext';
import { DesignProvider } from 'src/contexts/DesignContext';
import { FeatureToggleProvider } from 'src/contexts/FeatureToggleProvider';
import useUserProfile from 'src/modules/user/hooks/useUserProfile';
import { RenderRoutes } from 'src/routes';
import { useDispatch } from 'src/store';
import { Theme } from 'src/theme';

const Main: FC<{ theme: Theme; setTheme: (theme: Theme) => void }> = ({
  theme,
  setTheme,
}) => {
  const { isLoading: loading, data: user } = useUserProfile();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.role.name === 'employee') {
      dispatch(getCartProductList());
    }
  }, [user?.role.id]);

  return (
    <>
      {loading && <SplashScreen />}
      <FeatureToggleProvider>
        <CartProvider>
          <AddProductsToCatalogProvider>
            <DesignProvider setTheme={setTheme} theme={theme}>
              <CurrencyProvider>
                <DepartmentProvider>
                  <AdminProductListFilterProvider>
                    <GlobalStyles />
                    <ScrollReset />
                    <RenderRoutes />
                  </AdminProductListFilterProvider>
                </DepartmentProvider>
              </CurrencyProvider>
            </DesignProvider>
          </AddProductsToCatalogProvider>
        </CartProvider>
      </FeatureToggleProvider>
    </>
  );
};

export default Main;
