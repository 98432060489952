import * as ActionTypes from 'src/action-types';
import { Product } from 'src/types';

export const setProductToCatalog = (
  product: Product,
  catalogId: string | number,
) => ({
  type: ActionTypes.PRODUCT_SET_TO_CATALOG,
  payload: product,
  catalogId,
});

export const removeProductToCatalog = (id: string | number) => ({
  type: ActionTypes.PRODUCT_REMOVE_TO_CATALOG,
  payload: id,
});

export const resetProductToCatalog = () => ({
  type: ActionTypes.PRODUCT_RESET_TO_CATALOG,
});
