import { Reducer } from 'redux';

import {
  DASHBOARD_STATE_FAIL,
  DASHBOARD_STATE_REQUEST,
  DASHBOARD_STATE_RESET,
  DASHBOARD_STATE_SUCCESS,
  GET_AVG_ORDER_FAIL,
  GET_AVG_ORDER_SUCCESS,
  GET_AVG_ORDER_REQUEST,
  GET_AVG_ORDER_RESET,
  GET_EMPLOYEES_COUNT_FAIL,
  GET_EMPLOYEES_COUNT_REQUEST,
  GET_EMPLOYEES_COUNT_RESET,
  GET_EMPLOYEES_COUNT_SUCCESS,
  GET_ORDERS_BY_DEPARTMENT_FAIL,
  GET_ORDERS_BY_DEPARTMENT_REQUEST,
  GET_ORDERS_BY_DEPARTMENT_RESET,
  GET_ORDERS_BY_DEPARTMENT_SUCCESS,
  GET_ORDERS_BY_MONTH_FAIL,
  GET_ORDERS_BY_MONTH_REQUEST,
  GET_ORDERS_BY_MONTH_RESET,
  GET_ORDERS_BY_MONTH_SUCCESS,
  GET_TOTAL_PRODUCTS_ORDERED_FAIL,
  GET_TOTAL_PRODUCTS_ORDERED_REQUEST,
  GET_TOTAL_PRODUCTS_ORDERED_RESET,
  GET_TOTAL_PRODUCTS_ORDERED_SUCCESS,
  GET_TOTAL_SPENT_FAIL,
  GET_TOTAL_SPENT_REQUEST,
  GET_TOTAL_SPENT_RESET,
  GET_TOTAL_SPENT_SUCCESS,
  GET_LATEST_REGISTRANT_INFO_FAIL,
  GET_LATEST_REGISTRANT_INFO_REQUEST,
  GET_LATEST_REGISTRANT_INFO_RESET,
  GET_LATEST_REGISTRANT_INFO_SUCCESS,
} from 'src/action-types';
import {
  GetDashboardStateActionList,
  GetDashboardAvgOrderActionList,
  GetDashboardEmployeeCountActionList,
  GetDashboardOrderByDepartmentActionList,
  GetDashboardOrderByMonthActionList,
  GetDashboardTotalProductsOrderedActionList,
  GetDashboardTotalSpentActionList,
  GetDashboardLatestRegistrantsActionList,
} from 'src/actions';
import {
  DashboardStateType,
  DashboardAvgOrder,
  DashboardOrderByDepartment,
  DashboardOrderByMonth,
  DashboardTotalSpent,
  DashboardAccounts,
} from 'src/types';

interface DashboardInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface DashboardGetState extends DashboardInitialState {
  dashboard: DashboardStateType;
}

export const dashboardStateReducer: Reducer<
  DashboardGetState,
  GetDashboardStateActionList
> = (state = { dashboard: { employees: 0, roles: 0 } }, action) => {
  switch (action.type) {
    case DASHBOARD_STATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DASHBOARD_STATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        dashboard: action.payload,
      };
    case DASHBOARD_STATE_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case DASHBOARD_STATE_RESET:
      return state;
    default:
      return state;
  }
};

//TOTAL SPENT

interface DashboardGetTotalSpentState extends DashboardInitialState {
  dashboard?: DashboardTotalSpent;
}

export const dashboardTotalSpentStateReducer: Reducer<
  DashboardGetTotalSpentState,
  GetDashboardTotalSpentActionList
> = (state = {}, action) => {
  switch (action.type) {
    case GET_TOTAL_SPENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TOTAL_SPENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        dashboard: action.payload,
      };
    case GET_TOTAL_SPENT_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_TOTAL_SPENT_RESET:
      return state;
    default:
      return state;
  }
};

//NEW EMPLOYEE COUNT

interface DashboardGetEmployeeCountState extends DashboardInitialState {
  count: number;
}

export const dashboardEmployeeCountReducer: Reducer<
  DashboardGetEmployeeCountState,
  GetDashboardEmployeeCountActionList
> = (state = { count: 0 }, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_COUNT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEES_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        count: action.payload,
      };
    case GET_EMPLOYEES_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_EMPLOYEES_COUNT_RESET:
      return state;
    default:
      return state;
  }
};

//AVERAGE ORDER

interface DashboardGetAvgOrderState extends DashboardInitialState {
  average: DashboardAvgOrder;
}

const avgOrderInitilaState: DashboardGetAvgOrderState = {
  average: {
    average: 0,
    bought: 0,
    count: 0,
    rented: 0,
  },
};

export const dashboardAvgOrderReducer: Reducer<
  DashboardGetAvgOrderState,
  GetDashboardAvgOrderActionList
> = (state = avgOrderInitilaState, action) => {
  switch (action.type) {
    case GET_AVG_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AVG_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        average: action.payload,
      };
    case GET_AVG_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_AVG_ORDER_RESET:
      return state;
    default:
      return state;
  }
};

//TOTAL PRODUCTS ORDERED COUNT

interface DashboardTotalProductsOrderedState extends DashboardInitialState {
  total: number;
}

export const dashboardTotalProductsOrderedReducer: Reducer<
  DashboardTotalProductsOrderedState,
  GetDashboardTotalProductsOrderedActionList
> = (state = { total: 0 }, action) => {
  switch (action.type) {
    case GET_TOTAL_PRODUCTS_ORDERED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TOTAL_PRODUCTS_ORDERED_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        total: action.payload,
      };
    case GET_TOTAL_PRODUCTS_ORDERED_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_TOTAL_PRODUCTS_ORDERED_RESET:
      return state;
    default:
      return state;
  }
};

// ORDER BY MONTH

interface DashboardOrderByMonthState extends DashboardInitialState {
  monthly: DashboardOrderByMonth;
}

const orderByMonthInitialState: DashboardOrderByMonthState = {
  monthly: {
    grouped_orders: {},
    month_start: '',
    month_end: '',
  },
};

export const dashboardOrderByMonthReducer: Reducer<
  DashboardOrderByMonthState,
  GetDashboardOrderByMonthActionList
> = (state = orderByMonthInitialState, action) => {
  switch (action.type) {
    case GET_ORDERS_BY_MONTH_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_BY_MONTH_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        monthly: action.payload,
      };
    case GET_ORDERS_BY_MONTH_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_ORDERS_BY_MONTH_RESET:
      return state;
    default:
      return state;
  }
};

//ORDER BY DEPARTMENT

interface DashboardOrderByDepartmentState extends DashboardInitialState {
  departments: DashboardOrderByDepartment;
}

export const dashboardOrderByDepartmentReducer: Reducer<
  DashboardOrderByDepartmentState,
  GetDashboardOrderByDepartmentActionList
> = (state = { departments: { departments: [] } }, action) => {
  switch (action.type) {
    case GET_ORDERS_BY_DEPARTMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_BY_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        departments: action.payload,
      };
    case GET_ORDERS_BY_DEPARTMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false,
      };
    case GET_ORDERS_BY_DEPARTMENT_RESET:
      return state;
    default:
      return state;
  }
};

interface DashboardLatestRegistrantState extends DashboardInitialState {
  accounts: DashboardAccounts;
}

const latestRegistrantInitialState: DashboardLatestRegistrantState = {
  accounts: {
    accepted: 0,
    end: '',
    latest_registrants: [],
    pending: 0,
    start: '',
  },
};

export const dashboardLatestRegistrantsReducer: Reducer<
  DashboardLatestRegistrantState,
  GetDashboardLatestRegistrantsActionList
> = (state = latestRegistrantInitialState, action) => {
  switch (action.type) {
    case GET_LATEST_REGISTRANT_INFO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LATEST_REGISTRANT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        accounts: action.payload,
      };
    case GET_LATEST_REGISTRANT_INFO_FAIL:
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    case GET_LATEST_REGISTRANT_INFO_RESET:
      return state;
    default:
      return state;
  }
};
