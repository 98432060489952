import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  CatalogCreateAction,
  CatalogDetailsAction,
  CatalogListAction,
  CatalogListInfiniteAction,
  CatalogUpdateAction,
  CatalogDuplicateAction,
} from 'src/actions';
import { Catalog, InitialState } from 'src/types';
import { Meta, Response } from 'src/types/response';

interface CatalogListState extends InitialState {
  catalogs: Catalog[];
  meta?: Meta;
}

const catalogListIntialState: CatalogListState = {
  catalogs: [],
};

export const catalogListReducer: Reducer<
  CatalogListState,
  CatalogListAction
> = (state = catalogListIntialState, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        catalogs: action.payload,
        success: true,
        meta: action.meta,
      };
    case ActionTypes.CATALOG_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_LIST_RESET:
      return catalogListIntialState;
    default:
      return state;
  }
};

interface CatalogDetailsState extends InitialState {
  catalog?: Catalog;
}

export const catalogDetailsReducer: Reducer<
  CatalogDetailsState,
  CatalogDetailsAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        catalog: action.payload,
      };
    case ActionTypes.CATALOG_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

interface CatalogUpdateState extends InitialState {
  catalog?: Catalog;
}

export const catalogUpdateReducer: Reducer<
  CatalogUpdateState,
  CatalogUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        catalog: action.payload,
      };
    case ActionTypes.CATALOG_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface CatalogCreateState extends Omit<InitialState, 'errors'> {
  catalog?: Catalog;
  errors?: Response['errors'];
}

export const catalogCreateReducer: Reducer<
  CatalogCreateState,
  CatalogCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        catalog: action.payload,
      };
    case ActionTypes.CATALOG_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case ActionTypes.CATALOG_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

interface CatalogListInfiniteState extends InitialState {
  catalogs: Catalog[];
  meta?: Meta;
  hasMore?: boolean;
}

const catalogListInfiniteInitialState: CatalogListInfiniteState = {
  catalogs: [],
  loading: true,
};

export const catalogListInfiniteReducer: Reducer<
  CatalogListInfiniteState,
  CatalogListInfiniteAction
> = (state = catalogListInfiniteInitialState, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_LIST_INFINITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_LIST_INFINITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        catalogs: action.payload,
        meta: action.meta,
        hasMore: action.hasMore,
      };
    case ActionTypes.CATALOG_LIST_INFINITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_LIST_INFINITE_RESET:
      return catalogListInfiniteInitialState;
    default:
      return state;
  }
};

interface CatalogDuplicateState extends InitialState {
  catalogs?: Catalog;
}

export const catalogDuplicateReducer: Reducer<
  CatalogDuplicateState,
  CatalogDuplicateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CATALOG_DUPLICATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CATALOG_DUPLICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        catalogs: action.payload,
      };
    case ActionTypes.CATALOG_DUPLICATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.CATALOG_DUPLICATE_RESET:
      return {};
    default:
      return state;
  }
};
