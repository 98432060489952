import { createSelector, Selector } from 'reselect';

import {
  productVariantDetailsReducer,
  productVariantRemoveReducer,
  productVariantSelectReducer,
  productVariantUpdateReducer,
} from 'src/reducers/product-variant-reducer';
import { RootState } from 'src/store';

export const productVariantSelectSelector: Selector<
  RootState,
  ReturnType<typeof productVariantSelectReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.productVariantSelect,
);

export const productVariantRemoveSelector: Selector<
  RootState,
  ReturnType<typeof productVariantRemoveReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.productVariantRemove,
);

export const productVariantDetailsSelector: Selector<
  RootState,
  ReturnType<typeof productVariantDetailsReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.productVariantDetails,
);

export const productVariantUpdateSelector: Selector<
  RootState,
  ReturnType<typeof productVariantUpdateReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.productVariantUpdate,
);
