import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { PimProductsToCatalogAction } from 'src/actions';
import { Product } from 'src/types';

interface PimProductsToCatalogState {
  products: (Product & { in_catalog?: boolean })[];
}

export const pimProductsToCatalogReducer: Reducer<
  PimProductsToCatalogState,
  PimProductsToCatalogAction
> = (state = { products: [] }, action) => {
  switch (action.type) {
    case ActionTypes.PIM_PRODUCT_SET_TO_CATALOG:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case ActionTypes.PIM_PRODUCT_REMOVE_TO_CATALOG:
      return {
        ...state,
        products: state.products.filter(
          product => product.id !== action.payload,
        ),
      };
    case ActionTypes.PIM_PRODUCT_RESET_TO_CATALOG:
      return {
        products: [],
      };
    default:
      return state;
  }
};
