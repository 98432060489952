import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  AuthUserUploadPhotoAction,
  AuthUserUploadPhotoActionFail,
} from 'src/actions';
import { InitialState } from 'src/types';

export const authUserUploadPhotoReducer: Reducer<
  InitialState<AuthUserUploadPhotoActionFail['payload']>,
  AuthUserUploadPhotoAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_USER_UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.AUTH_USER_UPLOAD_PHOTO_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.AUTH_USER_UPLOAD_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.AUTH_USER_UPLOAD_PHOTO_RESET:
      return {};
    default:
      return state;
  }
};
