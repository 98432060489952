import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { ColorListInfiniteAction } from 'src/actions';
import { Color, InitialState } from 'src/types';
import { Meta } from 'src/types/response';

interface ColorListInfiniteState extends InitialState {
  colors: Color[];
  meta?: Meta;
  hasMore?: boolean;
}

const colorListInitialState = {
  colors: [],
};

export const colorListInfiniteReducer: Reducer<
  ColorListInfiniteState,
  ColorListInfiniteAction
> = (state = colorListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.COLOR_LIST_INFINITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.COLOR_LIST_INFINITE_SUCCESS:
      return {
        ...state,
        loading: false,
        colors: action.payload,
        meta: action.meta,
        hasMore: action.hasMore,
        success: true,
      };
    case ActionTypes.COLOR_LIST_INFINITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.COLOR_LIST_INFINITE_RESET:
      return colorListInitialState;
    default:
      return state;
  }
};
