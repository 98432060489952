import { SVGProps } from 'react';

const HomeIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4.1742V11C12 11.5607 11.496 12 10.875 12H9.375C8.754 12 8.25 11.5607 8.25 11V8.0001H3.75V10.9847C3.75 11.5453 3.246 12 2.625 12H1.125C0.504 12 0 11.5453 0 10.9847V4.1742C0.000130318 4.01698 0.0419643 3.86201 0.122106 3.72185C0.202248 3.5817 0.31844 3.46032 0.46125 3.36756L5.40375 0.154306C5.58195 0.0535277 5.78879 0 6 0C6.21121 0 6.41805 0.0535277 6.59625 0.154306L11.5388 3.36756C11.6815 3.46025 11.7976 3.58153 11.8777 3.72156C11.9579 3.86159 11.9998 4.01643 12 4.17354V4.1742Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon;
