import { ThunkAction } from 'redux-thunk';

import * as ActionType from 'src/action-types';
import {
  OffboardableCountriesGetAction,
  OffboardableCountriesGetActionFail,
  OffboardableCountriesGetActionRequest,
  OffboardableCountriesGetActionReset,
  OffboardableCountriesGetActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Country as CountryType } from 'src/types';
import axios from 'src/utils/axios';

export const getOffboardableCountries =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    OffboardableCountriesGetAction
  > =>
  async dispatch => {
    dispatch<OffboardableCountriesGetActionRequest>({
      type: ActionType.OFFBOARDABLE_COUNTRIES_GET_REQUEST,
    });

    try {
      const res = await axios.get<CountryType[]>(
        `${apiConfig.url}/offboards/countries`,
      );

      dispatch<OffboardableCountriesGetActionSuccess>({
        type: ActionType.OFFBOARDABLE_COUNTRIES_GET_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      dispatch<OffboardableCountriesGetActionFail>({
        type: ActionType.OFFBOARDABLE_COUNTRIES_GET_FAIL,
        payload: 'Server Error',
      });
    }
  };

export const getOffboardableCountriesReset =
  (): OffboardableCountriesGetActionReset => ({
    type: ActionType.OFFBOARDABLE_COUNTRIES_GET_RESET,
  });
