import { SVGProps } from 'react';

const LogoutIcon = ({
  height = '15',
  width = '15',
  style = {},
  color = 'currentColor',
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.50005 11.35C6.87917 11.35 8.20182 10.8022 9.177 9.82697C10.1522 8.85178 10.7 7.52914 10.7 6.15001C10.7 4.77089 10.1522 3.44825 9.177 2.47306C8.20182 1.49787 6.87917 0.950012 5.50005 0.950012C4.12092 0.950012 2.79828 1.49787 1.82309 2.47306C0.847904 3.44825 0.300049 4.77089 0.300049 6.15001C0.300049 7.52914 0.847904 8.85178 1.82309 9.82697C2.79828 10.8022 4.12092 11.35 5.50005 11.35ZM5.0405 4.65956C4.9221 4.53697 4.85658 4.37278 4.85806 4.20235C4.85954 4.03192 4.9279 3.8689 5.04842 3.74838C5.16893 3.62786 5.33196 3.5595 5.50239 3.55802C5.67282 3.55654 5.83701 3.62206 5.9596 3.74046L7.9096 5.69046C8.03145 5.81236 8.09991 5.97766 8.09991 6.15001C8.09991 6.32237 8.03145 6.48767 7.9096 6.60956L5.9596 8.55956C5.83701 8.67796 5.67282 8.74348 5.50239 8.742C5.33196 8.74052 5.16893 8.67216 5.04842 8.55164C4.9279 8.43113 4.85954 8.2681 4.85806 8.09767C4.85658 7.92724 4.9221 7.76305 5.0405 7.64046L5.88095 6.80001H3.55005C3.37766 6.80001 3.21233 6.73153 3.09043 6.60963C2.96853 6.48773 2.90005 6.3224 2.90005 6.15001C2.90005 5.97762 2.96853 5.81229 3.09043 5.69039C3.21233 5.56849 3.37766 5.50001 3.55005 5.50001H5.88095L5.0405 4.65956Z"
      fill={color}
    />
  </svg>
);

export default LogoutIcon;
