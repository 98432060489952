import { FC } from 'react';

import { DialogActions, CircularProgress, Typography } from '@material-ui/core';

import NoTransformButton from 'src/components-for-storybook/atoms/NoTransformButton/NoTransformButton';

import { DialogModalFooterProps } from './interface';
import { useDialogFooterStyles, useAlignmentStyles } from './styles';

const DialogFooter: FC<DialogModalFooterProps> = ({
  buttons,
  alignment,
  leftText,
}) => {
  const styles = useDialogFooterStyles();
  const align = useAlignmentStyles();

  let contentAlignment = '';

  switch (alignment) {
    case 'center':
      contentAlignment = align.center;
      break;
    case 'left':
      contentAlignment = align.left;
      break;
  }

  return (
    <DialogActions className={`${styles.root} ${contentAlignment}`}>
      {leftText && (
        <Typography variant="h6" color="textPrimary">
          {leftText}
        </Typography>
      )}
      {buttons.map((button, index) => (
        <NoTransformButton
          data-testid={button.dataTestId}
          key={index}
          type={button.type}
          color={button.color}
          variant={button.variant}
          disabled={button.isLoading || button.disabled}
          onClick={button.handleClick}
          style={button.style}
          {...button}
        >
          {button.text}
          {button.isLoading && (
            <CircularProgress size={20} className={styles.loaderButton} />
          )}
        </NoTransformButton>
      ))}
    </DialogActions>
  );
};

export default DialogFooter;
