import { createSelector, Selector } from 'reselect';

import { accountTypeListReducer } from 'src/reducers/account-type-reducer';
import { RootState } from 'src/store';

export const accountTypeListSelector: Selector<
  RootState,
  ReturnType<typeof accountTypeListReducer>
> = createSelector(
  [(state: RootState) => state],
  state => state.accountTypeList,
);
