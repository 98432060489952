import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductListAdminAction,
  ProductListAdminActionRequest,
  ProductListAdminActionReset,
  ProductListAdminActionSuccess,
  ProductListMeta,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import axios from 'src/utils/axios';

interface GetProductListAdminParams {
  page?: number;
  limit?: number;
}

export const getProductListAdmin =
  (
    params?: GetProductListAdminParams,
  ): ThunkAction<Promise<void>, RootState, undefined, ProductListAdminAction> =>
  async dispatch => {
    dispatch<ProductListAdminActionRequest>({
      type: ActionTypes.PRODUCT_LIST_ADMIN_REQUEST,
    });

    const res = await axios.get<{ data: Product[]; meta: ProductListMeta }>(
      `${apiConfig.url}/products`,
      {
        params,
      },
    );

    const { data, meta } = res.data;

    dispatch<ProductListAdminActionSuccess>({
      type: ActionTypes.PRODUCT_LIST_ADMIN_SUCCESS,
      payload: data,
      meta,
    });
  };

export const getProductListAdminReset = (): ProductListAdminActionReset => ({
  type: ActionTypes.PRODUCT_LIST_ADMIN_RESET,
});
