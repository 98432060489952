import { RoleName } from 'src/types/role-name';

export const roles: RoleName[] = [
  'admin',
  'employer',
  'employee',
  'employer-admin',
  'employer-manager',
];

export const roleIdToNameMap: Record<number, RoleName> = {
  1: 'admin',
  2: 'employer',
  3: 'employee',
  4: 'employer-admin',
  5: 'employer-manager',
};

export const roleIdFromNameMap: Record<RoleName, number> = {
  admin: 1,
  employer: 2,
  employee: 3,
  'employer-admin': 4,
  'employer-manager': 5,
};

export const redirectTable: { [key in RoleName]: string } = {
  employee: '/app/management/products',
  employer: '/app/management/dashboard',
  'employer-admin': '/app/management/dashboard',
  'employer-manager': '/app/management/dashboard',
  admin: '/app/management/dashboard',
};

export const roleIdFromName = (roleName: RoleName) => {
  return roleIdFromNameMap[roleName];
};

export const roleNameFromId = (id = 3): RoleName => {
  return roleIdToNameMap[id];
};

export const inRoles = (role: RoleName, rolesToCheck: RoleName[]) => {
  return rolesToCheck.includes(role);
};

export const isEmployerTenant = (role?: RoleName) => {
  if (!role) return false;
  return ['employer-manager', 'employer-admin'].includes(role);
};
export const isEmployerManager = (role?: RoleName) => {
  if (!role) return false;
  return ['employer-manager'].includes(role);
};

export const isEmployerAdmin = (role?: RoleName) => {
  if (!role) return false;
  return ['employer-admin', 'employer'].includes(role);
};

export const isEmployer = (role?: RoleName) => {
  if (!role) return false;
  return (
    isEmployerAdmin(role) || isEmployerManager(role) || isEmployerTenant(role)
  );
};

export const isAdmin = (role?: RoleName) => {
  if (!role) return false;
  return ['admin'].includes(role);
};

export const isEmployee = (role?: RoleName) => {
  if (!role) return false;
  return ['employee'].includes(role);
};

export const whichEmployer = (role: RoleName) => {
  switch (role) {
    case 'employer':
      return 'Administrator';

    case 'employer-admin':
      return 'Administrator';

    case 'employer-manager':
      return 'Manager';

    default:
      return 'Something went wrong (TFE)';
  }
};
