import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductListAction,
  ProductListActionFail,
  ProductListActionRequest,
  ProductListActionReset,
  ProductListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

type GetProductList = {
  params?: {
    page?: number;
    limit?: number;
    search?: string;
    supplier_id?: string | number;
    is_parent?: number;
    in_catalog?: number | undefined;
    catalog_id?: string | number;
    category_id?: string | number;
    type?: string[];
    country_id?: string | number;
    expand?: string;
  };
};

export const getProductList =
  (
    params?: GetProductList['params'],
  ): ThunkAction<Promise<void>, RootState, undefined, ProductListAction> =>
  async dispatch => {
    dispatch<ProductListActionRequest>({
      type: ActionTypes.PRODUCT_LIST_REQUEST,
    });

    const type = params?.type;

    try {
      const res = await axios.get<Response<Product[]>>(
        `${apiConfig.url}/products`,
        {
          params: {
            include: params?.expand ?? 'supplier,otherprices',
            page: params?.page,
            limit: params?.limit,
            is_parent: params?.is_parent,
            search: {
              query: params?.search,
              fields: 'name',
            },
            in_catalog: params?.in_catalog,
            catalog_id: params?.catalog_id,
            country_id: params?.country_id,
            filter: {
              supplier_id: {
                operation: 'in',
                value: params?.supplier_id ? [params.supplier_id] : '',
              },
              category_id: {
                operation: 'in',
                value: params?.category_id ? [params.category_id] : '',
              },
              type:
                Array.isArray(type) && type.length > 0
                  ? {
                      operation: 'in',
                      value: type,
                    }
                  : undefined,
            },
          },
        },
      );

      const { data, meta } = res.data;

      dispatch<ProductListActionSuccess>({
        type: ActionTypes.PRODUCT_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (e) {
      dispatch<ProductListActionFail>({
        type: ActionTypes.PRODUCT_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getProductListReset = (): ProductListActionReset => ({
  type: ActionTypes.PRODUCT_LIST_RESET,
});
