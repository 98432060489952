import { FC } from 'react';

import { Redirect } from 'react-router-dom';

import useUserProfile from 'src/modules/user/hooks/useUserProfile';

const AuthGuard: FC = ({ children }) => {
  const { data: user, isLoading } = useUserProfile();

  if (!user && !isLoading) {
    return <Redirect to="/access-denied" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
