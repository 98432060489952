export const CATALOG_LIST_REQUEST = 'CATALOG_LIST_REQUEST';
export const CATALOG_LIST_SUCCESS = 'CATALOG_LIST_SUCCESS';
export const CATALOG_LIST_FAIL = 'CATALOG_LIST_FAIL';
export const CATALOG_LIST_RESET = 'CATALOG_LIST_RESET';

export const CATALOG_DETAILS_REQUEST = 'CATALOG_DETAILS_REQUEST';
export const CATALOG_DETAILS_SUCCESS = 'CATALOG_DETAILS_SUCCESS';
export const CATALOG_DETAILS_FAIL = 'CATALOG_DETAILS_FAIL';
export const CATALOG_DETAILS_RESET = 'CATALOG_DETAILS_RESET';

export const CATALOG_UPDATE_REQUEST = 'CATALOG_UPDATE_REQUEST';
export const CATALOG_UPDATE_SUCCESS = 'CATALOG_UPDATE_SUCCESS';
export const CATALOG_UPDATE_FAIL = 'CATALOG_UPDATE_FAIL';
export const CATALOG_UPDATE_RESET = 'CATALOG_UPDATE_RESET';

export const CATALOG_CREATE_REQUEST = 'CATALOG_CREATE_REQUEST';
export const CATALOG_CREATE_SUCCESS = 'CATALOG_CREATE_SUCCESS';
export const CATALOG_CREATE_FAIL = 'CATALOG_CREATE_FAIL';
export const CATALOG_CREATE_RESET = 'CATALOG_CREATE_RESET';

export const CATALOG_LIST_INFINITE_REQUEST = 'CATALOG_LIST_INFINITE_REQUEST';
export const CATALOG_LIST_INFINITE_SUCCESS = 'CATALOG_LIST_INFINITE_SUCCESS';
export const CATALOG_LIST_INFINITE_FAIL = 'CATALOG_LIST_INFINITE_FAIL';
export const CATALOG_LIST_INFINITE_RESET = 'CATALOG_LIST_INFINITE_RESET';

export const CATALOG_DUPLICATE_REQUEST = 'CATALOG_DUPLICATE_REQUEST';
export const CATALOG_DUPLICATE_SUCCESS = 'CATALOG_DUPLICATE_SUCCESS';
export const CATALOG_DUPLICATE_FAIL = 'CATALOG_DUPLICATE_FAIL';
export const CATALOG_DUPLICATE_RESET = 'CATALOG_DUPLICATE_RESET';
