import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  OFFBOARD_DELETE_FAIL,
  OFFBOARD_DELETE_SUCCESS,
} from 'src/action-types';
import {
  OffboardListAction,
  OffboardListActionRequest,
  OffboardListActionSuccess,
  OffboardListActionFail,
  OffboardListActionReset,
  OffboardDetailsActionRequest,
  OffboardDetailsActionSuccess,
  OffboardDetailsActionFail,
  OffboardDetailsAction,
  OffboardUpdateStatusAction,
  OffboardUpdateStatusRequest,
  OffboardUpdateStatusSuccess,
  OffboardUpdateStatusFail,
  OffboardUpdateStatusReset,
  OffboardCreateAction,
  OffboardCreateActionRequest,
  OffboardCreateActionSuccess,
  OffboardCreateActionFail,
  OffboardCreateActionReset,
  EmployeeDetailsActionSuccess,
  OffboardDeleteAction,
  OffboardDeleteActionRequest,
  OffboardDeleteActionSuccess,
  OffboardDeleteActionFail,
  OffboardDeleteActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee, Offboard } from 'src/types';
import axios, { ErrorResponse } from 'src/utils/axios';

export const getOffboardRequests =
  (options?: {
    limit: number;
    page: number;
    search?: string;
  }): ThunkAction<Promise<void>, RootState, undefined, OffboardListAction> =>
  async dispatch => {
    dispatch<OffboardListActionRequest>({
      type: ActionTypes.OFFBOARD_LIST_REQUEST,
    });

    try {
      const res = await axios.get(`${apiConfig.url}/requests/offboards`, {
        params: {
          include: 'employee,employee.user',
          limit: options?.limit,
          page: options?.page,
          'filter[search]': options?.search,
          search_in:
            'id,employee.user.name,employee.user.last_name,employee.employer.company_name',
        },
      });

      const { data, meta } = res.data;

      dispatch<OffboardListActionSuccess>({
        type: ActionTypes.OFFBOARD_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (err) {
      dispatch<OffboardListActionFail>({
        type: ActionTypes.OFFBOARD_LIST_FAIL,
        payload: 'Something Went Wrong.',
      });
    }
  };

export const resetOffboardLists = () => ({
  type: ActionTypes.OFFBOARD_LIST_RESET,
});

export const getOffboardRequestByID =
  (
    offboardID: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, OffboardDetailsAction> =>
  async dispatch => {
    dispatch<OffboardDetailsActionRequest>({
      type: ActionTypes.OFFBOARD_DETAILS_REQUEST,
    });

    try {
      const res = await axios.get<{ data: Offboard }>(
        `${apiConfig.url}/requests/offboards/${offboardID}`,
        {
          params: {
            include:
              'employee,employee.user,country,assets,products,variants,variants.product,variants.color',
          },
        },
      );

      const { data } = res.data;

      dispatch<OffboardDetailsActionSuccess>({
        type: ActionTypes.OFFBOARD_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<OffboardDetailsActionFail>({
        type: ActionTypes.OFFBOARD_DETAILS_FAIL,
        payload: 'Something Went Wrong',
      });
    }
  };

export const resetOffboardDetails = () => ({
  type: ActionTypes.OFFBOARD_DETAILS_RESET,
});

export const getOffboardRequestsReset = (): OffboardListActionReset => ({
  type: ActionTypes.OFFBOARD_LIST_RESET,
});

interface EmployeeOffboardAssetsParams {
  id?: string | number;
  order_id?: string | number;
}

type EmployeeOffboardProductParams = EmployeeOffboardAssetsParams & {
  quantity: string | number;
};

interface EmployeeOffboardData {
  employee_id?: string | number;
  address_line_2?: string;
  extra_info?: string;
  email?: string;
  phone_number?: string | null | undefined;
  region: string;
  name?: string;
  city: string;
  address_line_1: string;
  postal_code: string;
  country_id?: string | number;
  deactivate?: boolean | null;
  assets?: EmployeeOffboardAssetsParams[];
  products?: EmployeeOffboardProductParams[];
  variants?: EmployeeOffboardProductParams[];
}

export const createEmployeeOffboard =
  (
    employeeOffboardData: EmployeeOffboardData,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    OffboardCreateAction | EmployeeDetailsActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<OffboardCreateActionRequest>({
      type: ActionTypes.OFFBOARD_CREATE_REQUEST,
    });

    try {
      const res = await axios.post<{ data: Offboard }>(
        `${apiConfig.url}/requests/offboards`,
        employeeOffboardData,
      );

      const { data } = res.data;

      dispatch<OffboardCreateActionSuccess>({
        type: ActionTypes.OFFBOARD_CREATE_SUCCESS,
        payload: data,
      });

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: {
          ...getState().employeeDetails.employee,
          offboards: [
            ...(getState().employeeDetails.employee?.offboards as Offboard[]),
            data,
          ],
        } as Employee,
      });

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: {
          ...getState().employeeDetails.employee,
          user: {
            ...getState().employeeDetails.employee?.user,
            is_deactivated:
              employeeOffboardData.deactivate === null ? false : true,
          },
        } as Employee,
      });

      enqueueSnackbar('Items successfully offboarded.', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        dispatch<OffboardCreateActionFail>({
          type: ActionTypes.OFFBOARD_CREATE_FAIL,
          payload:
            'Unable to offboard items. Please check your fields and items.',
        });

        enqueueSnackbar(
          e.response?.data.errors?.[0]?.field === 'employee_id'
            ? e.response.data.errors[0]?.errors[0]
            : 'Unable to offboard items. Please check your fields and items.',
          {
            variant: 'error',
          },
        );
      }
    }
  };

export const createEmployeeOffboardReset = (): OffboardCreateActionReset => ({
  type: ActionTypes.OFFBOARD_CREATE_RESET,
});

export const updateOffboardStatus =
  (
    offboardID: string | number,
    requestPayload?: { status?: string; confirm?: boolean },
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    OffboardUpdateStatusAction
  > =>
  async dispatch => {
    dispatch<OffboardUpdateStatusRequest>({
      type: ActionTypes.OFFBOARD_UPDATE_STATUS_REQUEST,
    });

    try {
      const res = await axios.post<{ data: Offboard }>(
        `${apiConfig.url}/offboards/${offboardID}`,
        requestPayload,
      );
      const { data } = res.data;

      dispatch<OffboardUpdateStatusSuccess>({
        type: ActionTypes.OFFBOARD_UPDATE_STATUS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<OffboardUpdateStatusFail>({
        type: ActionTypes.OFFBOARD_UPDATE_STATUS_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const updateOffboardStatusReset = (): OffboardUpdateStatusReset => ({
  type: ActionTypes.OFFBOARD_UPDATE_STATUS_RESET,
});

export const deleteOffboardRequest =
  (
    offboardID: string | number,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    OffboardDeleteAction | EmployeeDetailsActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<OffboardDeleteActionRequest>({
      type: ActionTypes.OFFBOARD_DELETE_REQUEST,
    });

    try {
      await axios.delete(`${apiConfig.url}/requests/offboards/${offboardID}`);

      dispatch<OffboardDeleteActionSuccess>({
        type: OFFBOARD_DELETE_SUCCESS,
      });

      dispatch<EmployeeDetailsActionSuccess>({
        type: ActionTypes.EMPLOYEE_DETAILS_SUCCESS,
        payload: {
          ...getState().employeeDetails.employee,
          offboards: getState().employeeDetails.employee?.offboards?.filter(
            offboard => offboard.id !== offboardID,
          ),
        } as Employee,
      });

      enqueueSnackbar('Offboard Successfully Canceled', {
        variant: 'success',
      });
    } catch (err) {
      dispatch<OffboardDeleteActionFail>({
        type: OFFBOARD_DELETE_FAIL,
        payload: 'Something Went Wrong',
      });
    }
  };

export const deleteOffboardReset = (): OffboardDeleteActionReset => ({
  type: ActionTypes.OFFBOARD_DELETE_RESET,
});
