import { Reducer } from 'redux';

import * as ActionType from 'src/action-types';
import { OffboardableCountriesGetAction } from 'src/actions';
import { Country as CountryType } from 'src/types';

interface OffboardableCountriesInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface OffboardableCountriesGetState
  extends OffboardableCountriesInitialState {
  offboardableCountries: CountryType[];
}

export const offboardableCountriesGetReducer: Reducer<
  OffboardableCountriesGetState,
  OffboardableCountriesGetAction
> = (
  state = { offboardableCountries: [] },
  action,
): OffboardableCountriesGetState => {
  switch (action.type) {
    case ActionType.OFFBOARDABLE_COUNTRIES_GET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionType.OFFBOARDABLE_COUNTRIES_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        offboardableCountries: action.payload,
      };
    case ActionType.OFFBOARDABLE_COUNTRIES_GET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionType.OFFBOARDABLE_COUNTRIES_GET_RESET:
      return { offboardableCountries: [] };
    default:
      return state;
  }
};
