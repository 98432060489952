import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  ProductCreateAction,
  ProductDeleteAction,
  ProductDetailsAction,
  ProductListAction,
  ProductListAdminAction,
  ProductListMeta,
  ProductsToCatalogAction,
  ProductUpdateAction,
  ProductUpdateActionSuccess,
  ProductVariantRemoveActionSuccess,
  ProductVariantSelectActionSuccess,
  ProductVariantUpdateActionSuccess,
} from 'src/actions';
import { InitialState } from 'src/types';
import { Product } from 'src/types/product';

interface ProductInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface ProductListState extends ProductInitialState {
  products: Product[];
  meta?: Partial<ProductListMeta>;
}

const productListInitialState: ProductListState = {
  products: [],
};

export const productListReducer: Reducer<
  ProductListState,
  ProductListAction
> = (state = productListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
        meta: action.meta,
        success: true,
      };
    case ActionTypes.PRODUCT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.PRODUCT_LIST_RESET:
      return productListInitialState;
    default:
      return state;
  }
};

interface ProductDetailsState extends ProductInitialState {
  product?: Product;
}

export const productDetailsReducer: Reducer<
  ProductDetailsState,
  | ProductDetailsAction
  | ProductUpdateActionSuccess
  | ProductVariantSelectActionSuccess
  | ProductVariantRemoveActionSuccess
  | ProductVariantUpdateActionSuccess
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        product: action.payload,
      };
    case ActionTypes.PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          ...action.payload,
        },
      };
    case ActionTypes.PRODUCT_VARIANT_SELECT_SUCCESS:
      if (state.product) {
        return {
          ...state,
          product: {
            ...state.product,
            variants: [...(action.payload.variants ?? [])],
          },
        };
      }
      return {
        ...state,
      };
    case ActionTypes.PRODUCT_VARIANT_REMOVE_SUCCESS:
      if (state.product) {
        return {
          ...state,
          product: {
            ...state.product,
            variants: [...(action.payload.variants ?? [])],
          },
        };
      }
      return {
        ...state,
      };
    case ActionTypes.PRODUCT_VARIANT_UPDATE_SUCCESS:
      if (state.product) {
        return {
          ...state,
          product: {
            ...state.product,
            variants: state.product.variants?.map(variant =>
              variant.id === action.payload.id ? action.payload : variant,
            ),
          },
        };
      }
      return {
        ...state,
      };
    case ActionTypes.PRODUCT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

interface ProductListAdminState extends ProductInitialState {
  products: Product[];
  meta: Partial<ProductListMeta>;
}

const productListAdminInitialState: ProductListAdminState = {
  products: [],
  meta: {},
};

export const productListAdminReducer: Reducer<
  ProductListAdminState,
  ProductListAdminAction
> = (state = productListAdminInitialState, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_LIST_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        products: action.payload,
        meta: action.meta,
      };
    case ActionTypes.PRODUCT_LIST_ADMIN_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.PRODUCT_LIST_ADMIN_RESET:
      return productListAdminInitialState;
    default:
      return state;
  }
};

interface ProductsToCatalogState {
  products: Product[];
  catalogId?: string | number;
}

export const productsToCatalogReducer: Reducer<
  ProductsToCatalogState,
  ProductsToCatalogAction
> = (state = { products: [] }, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_SET_TO_CATALOG:
      return {
        ...state,
        products: [...state.products, action.payload],
        catalogId: action.catalogId,
      };
    case ActionTypes.PRODUCT_REMOVE_TO_CATALOG:
      return {
        ...state,
        products: state.products.filter(
          product => product.id !== action.payload,
        ),
      };
    case ActionTypes.PRODUCT_RESET_TO_CATALOG:
      return {
        products: [],
      };
    default:
      return state;
  }
};

interface ProductCreateState extends Omit<InitialState, 'error'> {
  product?: Product;
  error?: Record<string, unknown>;
}

export const productCreateReducer: Reducer<
  ProductCreateState,
  ProductCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
        success: true,
      };
    case ActionTypes.PRODUCT_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

type ProductDeleteState = InitialState;

export const productDeleteReducer: Reducer<
  ProductDeleteState,
  ProductDeleteAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.PRODUCT_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.PRODUCT_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

interface ProductUpdateState extends Omit<InitialState, 'error'> {
  product?: Product;
  error?: Record<string, unknown>;
}

export const productUpdateReducer: Reducer<
  ProductUpdateState,
  ProductUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload,
        success: true,
      };
    case ActionTypes.PRODUCT_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
