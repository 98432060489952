import { useQuery } from '@tanstack/react-query';
import Axios from 'axios';

import axios from 'src/utils/axios';
import { addressValidator } from 'src/utils/validators/address-validator';

import * as keys from '../constants/keys';

/**
 *
 * @param employeeId - Employee ID
 * @returns Address of the employee, `null` if not found
 */
export const useGetEmployeeAddress = (
  employeeId: string | number | undefined | null,
) =>
  useQuery([keys.GET_EMPLOYEE_ADDRESS, employeeId], async () => {
    if (!employeeId) {
      return null;
    }

    try {
      const response = await axios.get(`/employees/${employeeId}/addresses`);

      return addressValidator.parse(response.data.data);
    } catch (error) {
      if (Axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }

      throw error;
    }
  });
