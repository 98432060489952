import { FC, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';

import { Department } from 'src/modules/department/validators/departmentValidator';

import { useStyles } from './SwitchRoleModal.styles';

interface SwitchRoleModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleCreateRole: (department: Department | null) => void;
  departments: Department[];
}

const SwitchRoleModal: FC<SwitchRoleModalProps> = ({
  isOpen,
  handleClose,
  handleCreateRole,
  departments,
}) => {
  const classes = useStyles();

  const [selectedDepartment, setSelectedDepartment] =
    useState<Department | null>(departments[0] ?? null);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h4">Create employee account</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider variant="fullWidth" />
      <Box padding={3} marginBottom={2} width={450}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Select a team where you want to place yourself as an employee
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              options={departments.map(d => {
                return {
                  name: d.name,
                  id: d.id,
                };
              })}
              value={selectedDepartment}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select team"
                  variant="outlined"
                  size="small"
                />
              )}
              renderOption={departmentItem => departmentItem.name}
              getOptionLabel={departmentItem => departmentItem.name}
              onChange={(_, department) => {
                if (department) {
                  setSelectedDepartment(
                    departments.find(d => d.id === department.id) ?? null,
                  );
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        padding={3}
        className={classes.footerWrapper}
      >
        <Box>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleCreateRole(selectedDepartment)}
            className={classes.connectButton}
            disabled={!selectedDepartment}
          >
            Create
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SwitchRoleModal;
