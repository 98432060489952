import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeAssetListAction,
  EmployeeBulkUploadAction,
  EmployeeDeleteAction,
  EmployeeDetailsAction,
  EmployeeListInfiniteAction,
  EmployeeOffboardAssetListAction,
  EmployeeOrderListAction,
  TopUpUpdateAction,
  EmployeeCreateAction,
  EmployeeUpdateListAction,
  EmployeeListAction,
} from 'src/actions';
import { Asset, Employee, InitialState, Order } from 'src/types';
import { Meta } from 'src/types/response';

interface EmployeeInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface EmployeeDetailsState extends EmployeeInitialState {
  employee?: Employee;
}

export const employeeDetailsReducer: Reducer<
  EmployeeDetailsState,
  EmployeeDetailsAction | TopUpUpdateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employee: action.payload,
      };
    case ActionTypes.EMPLOYEE_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.TOPUP_UPDATE_SUCCESS:
      return state.employee
        ? {
            ...state,
            employee: {
              ...state.employee,
              available_purchase_budget:
                action.payload.available_purchase_budget,
              available_rent_budget: action.payload.available_rent_budget,
            },
          }
        : { ...state };
    case ActionTypes.EMPLOYEE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployeeOrderListState extends EmployeeInitialState {
  orders: Order[];
}

const employeeOrderListInitialState = {
  orders: [],
};

export const employeeOrderListReducer: Reducer<
  EmployeeOrderListState,
  EmployeeOrderListAction
> = (state = employeeOrderListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_ORDER_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        orders: action.payload,
      };
    case ActionTypes.EMPLOYEE_ORDER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYEE_ORDER_LIST_RESET:
      return employeeOrderListInitialState;
    default:
      return state;
  }
};

interface EmployeeAssetListState extends EmployeeInitialState {
  assets: Asset[];
}

const initialEmployeeAssetListState = {
  assets: [],
};

export const employeeAssetListReducer: Reducer<
  EmployeeAssetListState,
  EmployeeAssetListAction
> = (state = initialEmployeeAssetListState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_ASSET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_ASSET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        assets: action.payload,
      };
    case ActionTypes.EMPLOYEE_ASSET_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYEE_ASSET_LIST_RESET:
      return initialEmployeeAssetListState;
    default:
      return state;
  }
};

export const employeeDeleteReducer: Reducer<
  EmployeeInitialState,
  EmployeeDeleteAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.EMPLOYEE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case ActionTypes.EMPLOYEE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployeeOffboardAssetListState extends EmployeeInitialState {
  assets: Asset[];
}

const initialEmployeeOffboardAssetListState = {
  assets: [],
};

export const employeeOffboardAssetListReducer: Reducer<
  EmployeeOffboardAssetListState,
  EmployeeOffboardAssetListAction
> = (state = initialEmployeeOffboardAssetListState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_OFFBOARD_ASSET_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_OFFBOARD_ASSET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        assets: action.payload,
      };
    case ActionTypes.EMPLOYEE_OFFBOARD_ASSET_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYEE_OFFBOARD_ASSET_LIST_RESET:
      return initialEmployeeAssetListState;
    default:
      return state;
  }
};

interface EmployeeBulkUploadState extends EmployeeInitialState {
  message?: string;
  errors: string[];
}

const employeeBulkUploadInitialState = {
  errors: [],
};

export const employeeBulkUploadReducer: Reducer<
  EmployeeBulkUploadState,
  EmployeeBulkUploadAction
> = (state = employeeBulkUploadInitialState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_BULK_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_BULK_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case ActionTypes.EMPLOYEE_BULK_UPLOAD_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        errors: action.payload,
      };
    case ActionTypes.EMPLOYEE_BULK_UPLOAD_RESET:
      return employeeBulkUploadInitialState;
    default:
      return state;
  }
};

interface EmployeeCreatesState extends EmployeeInitialState {
  employee?: Employee;
}
export const employeeCreateReducer: Reducer<
  EmployeeCreatesState,
  EmployeeCreateAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employee: action.payload,
      };
    case ActionTypes.EMPLOYEE_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.EMPLOYEE_CREATE_RESET:
      return state;
    default:
      return state;
  }
};

interface EmployeeListState extends InitialState {
  employees: Employee[];
  meta?: Meta;
  hasMore?: boolean;
}

export const employeeListInfiniteReducer: Reducer<
  EmployeeListState,
  EmployeeListInfiniteAction
> = (state = { employees: [] }, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_LIST_INFINITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_LIST_INFINITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employees: action.payload,
        hasMore: action.hasMore,
        meta: action.meta,
      };
    case ActionTypes.EMPLOYEE_LIST_INFINITE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        payload: action.payload,
      };
    case ActionTypes.EMPLOYEE_LIST_INFINITE_RESET:
      return { employees: [] };
    default:
      return state;
  }
};

interface EmployeeUpdateState extends InitialState {
  employee?: Employee[];
}

export const EmployeeUpdateReducer: Reducer<
  EmployeeUpdateState,
  EmployeeUpdateListAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        employees: action.payload,
      };
    case ActionTypes.EMPLOYEE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        payload: action.payload,
      };
    case ActionTypes.EMPLOYEE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

interface EmployeeListState extends InitialState {
  employees: Employee[];
  meta?: Meta;
}

const initialEmployeeListState: EmployeeListState = {
  employees: [],
};

export const employeeListReducer: Reducer<
  EmployeeListState,
  EmployeeListAction
> = (state = initialEmployeeListState, action) => {
  switch (action.type) {
    case ActionTypes.EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
        meta: action.meta,
        success: true,
      };
    case ActionTypes.EMPLOYEE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.EMPLOYEE_LIST_RESET:
      return initialEmployeeListState;
    default:
      return state;
  }
};
