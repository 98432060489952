import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const generateTokenListSelector = createSelector(
  [(state: RootState) => state],
  state => state.generateTokenList,
);

export const generateTokenCreateSelector = createSelector(
  [(state: RootState) => state],
  state => state.generateTokenCreate,
);

export const generateTokenDeleteSelector = createSelector(
  [(state: RootState) => state],
  state => state.generateTokenDelete,
);
