import { z } from 'zod';

export const provinceValidator = z.object({
  id: z.number(),
  country_code: z.string(),
  name: z.string(),
  iso2: z.string(),
});

export const provinceListValidator = z.object({
  data: z.array(provinceValidator),
});

export type Province = z.infer<typeof provinceValidator>;
