import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

import { User } from 'src/types';
import axios from 'src/utils/axios';
import trackUserProfile from 'src/utils/trackers/track-user-profile';
import zendesk from 'src/utils/zendeskSetup';

import { userProfile } from '../constants/user-query-keys';
import userProfileResponseSchemaValidator from '../validators/user-profile-response-schema';

export default function useUserProfile() {
  const result = useQuery({
    queryKey: [userProfile],
    queryFn: async () => {
      const response = await axios.get('/sso/register');

      if (!response.data) {
        return null;
      }

      const validated = (await userProfileResponseSchemaValidator.parseAsync(
        response.data,
      )) as unknown as { data: User };

      trackUserProfile(validated.data);

      const profilePictureUrl = validated.data.files?.find(
        f => f.collection_name === 'profile_image',
      )?.url;

      zendesk.setup(validated.data);

      return { ...validated.data, profilePictureUrl };
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    retry: false,
    keepPreviousData: true,
  });

  if (result.data) {
    const { id, email, name, role, employer } = result.data;

    Sentry.setUser({
      id,
      email,
      username:
        name + 'Role: ' + role.name + 'Company ' + employer?.company_name,
    });
  }

  return {
    ...result,
    data: result.data ?? undefined,
  };
}
