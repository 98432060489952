import { createSelector } from 'reselect';

import { RootState } from 'src/store';

export const AssetUpdateDepreciationSelector = createSelector(
  [(state: RootState) => state],
  state => state.assetDepreciationUpdate,
);

export const AssetGetSettingsSelector = createSelector(
  [(state: RootState) => state],
  state => state.assetGetSettings,
);
