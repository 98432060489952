import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { SupplierListInfiniteAction } from 'src/actions/supplier-action';
import { InitialState, Supplier } from 'src/types';
import { Meta } from 'src/types/response';

interface SupplierListInfiniteState extends InitialState {
  suppliers: Supplier[];
  meta?: Meta;
  hasMore?: boolean;
}

const supplierListInfiniteInitialState: SupplierListInfiniteState = {
  suppliers: [],
};

export const supplierListInfiniteReducer: Reducer<
  SupplierListInfiniteState,
  SupplierListInfiniteAction
> = (state = supplierListInfiniteInitialState, action) => {
  switch (action.type) {
    case ActionTypes.SUPPLIER_LIST_INFINITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SUPPLIER_LIST_INFINITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        suppliers: action.payload,
        hasMore: action.hasMore,
        meta: action.meta,
      };
    case ActionTypes.SUPPLIER_LIST_INFINITE_CLEAR:
      return {
        ...state,
        suppliers: [],
      };
    case ActionTypes.SUPPLIER_LIST_INFINITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    case ActionTypes.SUPPLIER_LIST_INFINITE_RESET:
      return supplierListInfiniteInitialState;
    default:
      return state;
  }
};
