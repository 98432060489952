import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  divider: {
    background: '#F6F6F7',
    height: 2,
  },
  collapse: {
    marginBottom: 15,
  },
  tableContainer: {
    maxHeight: 250,
  },
  warningIcon: {
    color: '#C07E41',
  },
  willBeAvailableText: {
    marginTop: 10,
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  footerWrapper: {
    boxShadow: '0px 0px 25px #B4B4B4AA',
  },
  stepWrapper: {
    background: '#F3F3F3',
    padding: 5,
    borderRadius: 5,
  },
  connectButton: {
    marginLeft: 10,
  },
}));
