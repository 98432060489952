import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  TopUpListAction,
  TopUpListFailAction,
  TopUpListRequestAction,
  TopUpListResetAction,
  TopUpListSuccessAction,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { TopUp } from 'src/types';
import axios from 'src/utils/axios';

interface AxiosResponseData {
  data: TopUp[];
}

export const getTopUps =
  (
    employeeId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, TopUpListAction> =>
  async dispatch => {
    dispatch<TopUpListRequestAction>({
      type: ActionTypes.TOPUP_LIST_REQUEST,
    });

    try {
      const res = await axios.get<AxiosResponseData>(
        `${apiConfig.url}/employees/${employeeId}/topups`,
      );

      const { data } = res.data;

      dispatch<TopUpListSuccessAction>({
        type: ActionTypes.TOPUP_LIST_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<TopUpListFailAction>({
        type: ActionTypes.TOPUP_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getTopUpsReset = (): TopUpListResetAction => ({
  type: ActionTypes.TOPUP_LIST_RESET,
});
