import { useRollbar } from '@rollbar/react';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import axios, { ErrorResponse } from 'src/utils/axios';

export default function useSwitchUserRoleMutation() {
  const { enqueueSnackbar } = useSnackbar();
  const rollbar = useRollbar();

  return useMutation({
    mutationFn: async () => {
      await axios.post('/switch-role');

      enqueueSnackbar('Role has been switched successfully', {
        variant: 'success',
      });
    },
    onError: error => {
      const errorResponse = error as ErrorResponse;

      enqueueSnackbar(
        errorResponse.response?.data.errors?.[0].errors[0] ??
          'Failed during switching role',
        {
          variant: 'error',
        },
      );

      rollbar.error(error as Error);
    },
  });
}
