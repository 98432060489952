import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogProductInfiniteActionSuccess,
  CatalogProductRemoveAction,
  CatalogProductRemoveActionFail,
  CatalogProductRemoveActionRequest,
  CatalogProductRemoveActionReset,
  CatalogProductRemoveActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import axios from 'src/utils/axios';

export const deleteCatalogProduct =
  (
    catalogId: string | number,
    productIds: Product['id'][],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogProductRemoveAction | CatalogProductInfiniteActionSuccess
  > =>
  async (dispatch, getState) => {
    dispatch<CatalogProductRemoveActionRequest>({
      type: ActionTypes.CATALOG_PRODUCT_REMOVE_REQUEST,
    });

    try {
      await axios.delete(`${apiConfig.url}/catalogs/${catalogId}/products`, {
        data: {
          products: productIds,
        },
      });

      dispatch<CatalogProductRemoveActionSuccess>({
        type: ActionTypes.CATALOG_PRODUCT_REMOVE_SUCCESS,
      });

      dispatch<CatalogProductInfiniteActionSuccess>({
        type: ActionTypes.CATALOG_PRODUCT_INFINITE_SUCCESS,
        payload: getState().catalogProductInfinite.products.filter(product => {
          return !productIds.includes(product.id);
        }),
      });

      enqueueSnackbar('Successfully removed from the catalog', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<CatalogProductRemoveActionFail>({
        type: ActionTypes.CATALOG_PRODUCT_REMOVE_FAIL,
        payload: 'Unable to remove products',
      });

      enqueueSnackbar('Unable to remove products', {
        variant: 'error',
      });
    }
  };

export const deleteCatalogProductReset =
  (): CatalogProductRemoveActionReset => ({
    type: ActionTypes.CATALOG_PRODUCT_REMOVE_RESET,
  });
