import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  CatalogProductListAction,
  CatalogProductListActionFail,
  CatalogProductListActionRequest,
  CatalogProductListActionReset,
  CatalogProductListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

interface GetCatalogProductListParams {
  catalogId: string | number;
  params: {
    limit?: number;
    page?: number;
    search?: string;
    variants?: number;
    expand?: string;
    exclude?: string | number;
    without_variants?: boolean;
    available_to_become_variants?: boolean;
  };
}

export const getCatalogProductList =
  (
    catalogId: GetCatalogProductListParams['catalogId'],
    params?: GetCatalogProductListParams['params'],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    CatalogProductListAction
  > =>
  async dispatch => {
    dispatch<CatalogProductListActionRequest>({
      type: ActionTypes.CATALOG_PRODUCT_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<Product[]>>(
        `${apiConfig.url}/catalogs/${catalogId}/products`,
        {
          params: {
            limit: params?.limit,
            page: params?.page,
            variants: params?.variants,
            search: {
              query: params?.search,
              fields: 'name',
            },
            expand: params?.expand,
            exclude: params?.exclude,
            without_variants: params?.without_variants,
            available_to_become_variants: params?.available_to_become_variants,
          },
        },
      );

      const { data, meta } = res.data;

      dispatch<CatalogProductListActionSuccess>({
        type: ActionTypes.CATALOG_PRODUCT_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (e) {
      dispatch<CatalogProductListActionFail>({
        type: ActionTypes.CATALOG_PRODUCT_LIST_FAIL,
        payload: 'Something went wrong',
      });
    }
  };

export const getCatalogProductListReset =
  (): CatalogProductListActionReset => ({
    type: ActionTypes.CATALOG_PRODUCT_LIST_RESET,
  });
