export const GENERATE_TOKEN_LIST_REQUEST = 'GENERATE_TOKEN_LIST_REQUEST';
export const GENERATE_TOKEN_LIST_SUCCESS = 'GENERATE_TOKEN_LIST_SUCCESS';
export const GENERATE_TOKEN_LIST_FAIL = 'GENERATE_TOKEN_LIST_FAIL';
export const GENERATE_TOKEN_LIST_RESET = 'GENERATE_TOKEN_LIST_RESET';

export const GENERATE_TOKEN_CREATE_REQUEST = 'GENERATE_TOKEN_CREATE_REQUEST';
export const GENERATE_TOKEN_CREATE_SUCCESS = 'GENERATE_TOKEN_CREATE_SUCCESS';
export const GENERATE_TOKEN_CREATE_FAIL = 'GENERATE_TOKEN_CREATE_FAIL';
export const GENERATE_TOKEN_CREATE_RESET = 'GENERATE_TOKEN_CREATE_RESET';

export const GENERATE_TOKEN_DELETE_REQUEST = 'GENERATE_TOKEN_DELETE_REQUEST';
export const GENERATE_TOKEN_DELETE_SUCCESS = 'GENERATE_TOKEN_DELETE_SUCCESS';
export const GENERATE_TOKEN_DELETE_FAIL = 'GENERATE_TOKEN_DELETE_FAIL';
export const GENERATE_TOKEN_DELETE_RESET = 'GENERATE_TOKEN_DELETE_RESET';
