import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ACCEPT_WELCOME_MESSAGE_FAIL,
  ACCEPT_WELCOME_MESSAGE_REQUEST,
  ACCEPT_WELCOME_MESSAGE_SUCCESS,
} from 'src/action-types/accept-welcome-message-types';
import {
  AcceptWelcomeMessageAction,
  AcceptWelcomeMessageActionFail,
  AcceptWelcomeMessageActionRequest,
  AcceptWelcomeMessageActionReset,
  AcceptWelcomeMessageActionSuccess,
} from 'src/actions/accept-welcome-message-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axiosInstance from 'src/utils/axios';

export const acceptWelcomeMessage =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    AcceptWelcomeMessageAction
  > =>
  async dispatch => {
    dispatch<AcceptWelcomeMessageActionRequest>({
      type: ACCEPT_WELCOME_MESSAGE_REQUEST,
    });

    try {
      await axiosInstance.post(`${apiConfig.url}/welcome/accept`);

      dispatch<AcceptWelcomeMessageActionSuccess>({
        type: ACCEPT_WELCOME_MESSAGE_SUCCESS,
      });
    } catch (error) {
      dispatch<AcceptWelcomeMessageActionFail>({
        type: ACCEPT_WELCOME_MESSAGE_FAIL,
        payload: 'Something Went Wrong',
      });
    }
  };

export const acceptWelcomeMessageReset =
  (): AcceptWelcomeMessageActionReset => ({
    type: ActionTypes.ACCEPT_WELCOME_MESSAGE_RESET,
  });
