import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductVariantRemoveAction,
  ProductVariantRemoveActionFail,
  ProductVariantRemoveActionRequest,
  ProductVariantRemoveActionReset,
  ProductVariantRemoveActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const removeVariants =
  (
    productId: string | number,
    variants: (string | number)[],
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    ProductVariantRemoveAction
  > =>
  async dispatch => {
    dispatch<ProductVariantRemoveActionRequest>({
      type: ActionTypes.PRODUCT_VARIANT_REMOVE_REQUEST,
    });

    try {
      const res = await axios.delete<Response<Product>>(
        `${apiConfig.url}/products/${productId}/variants`,
        {
          data: {
            variants,
          },
        },
      );

      const { data } = res.data;

      dispatch<ProductVariantRemoveActionSuccess>({
        type: ActionTypes.PRODUCT_VARIANT_REMOVE_SUCCESS,
        payload: data,
      });

      enqueueSnackbar('Variants successfully removed', {
        variant: 'success',
      });
    } catch (e) {
      dispatch<ProductVariantRemoveActionFail>({
        type: ActionTypes.PRODUCT_VARIANT_REMOVE_FAIL,
        payload: 'Failed to remove variants',
      });

      enqueueSnackbar('Failed to remove variants', {
        variant: 'error',
      });
    }
  };

export const removeVariantsReset = (): ProductVariantRemoveActionReset => ({
  type: ActionTypes.PRODUCT_VARIANT_REMOVE_RESET,
});
