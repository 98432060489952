import Posthog from 'posthog-js';

class PosthogAnalytic {
  init() {
    const token = process.env.REACT_APP_POSTHOG_KEY;
    const apiHost = process.env.REACT_APP_POTHOG_API_HOST;

    try {
      if (!token || !apiHost) {
        throw new Error('Posthog token or api host is not defined');
      }

      Posthog.init(token, {
        api_host: apiHost,
        segment: window.analytics,
      });
    } catch (e) {
      console.error('Posthog initialization error', e);
    }
  }

  capture(event: string, properties: Record<string, unknown>) {
    try {
      Posthog.capture(event, properties);
    } catch (e) {
      console.error(`Pothog ${event} error`, e);
    }
  }
}

export default PosthogAnalytic;

export const pAnalytic = new PosthogAnalytic();
