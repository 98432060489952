import mixpanel from 'mixpanel-browser';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  ProductDetailsAction,
  ProductDetailsActionFail,
  ProductDetailsActionRequest,
  ProductDetailsActionReset,
  ProductDetailsActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Product } from 'src/types';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const getProductDetails =
  (
    id: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, ProductDetailsAction> =>
  async dispatch => {
    dispatch<ProductDetailsActionRequest>({
      type: ActionTypes.PRODUCT_DETAILS_REQUEST,
    });

    try {
      const res = await axios.get<Response<Product>>(
        `${apiConfig.url}/products/${id}`,
        {
          params: {
            include:
              'catalog,oldVariants,color,prices,otherprices,oldVariants.otherprices,oldVariants.color,supplier,category,children,children.otherprices,children.color,countries,children.catalog,children.catalog.employers,variants,variants.color,variants.countries',
          },
        },
      );

      const { data } = res.data;

      // mixpanel tracker
      try {
        mixpanel.track('view product', {
          product_id: id,
          product_price_eur: data.price, // TODO: we want to rename to something better
          product_name: data.name,
          product_type: data.type,
          product: data,
          event_type: 'page view',
        });
      } catch (error) {
        console.error('mixpanel error', error);
      }

      dispatch<ProductDetailsActionSuccess>({
        type: ActionTypes.PRODUCT_DETAILS_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<ProductDetailsActionFail>({
        type: ActionTypes.PRODUCT_DETAILS_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getProductDetailsReset = (): ProductDetailsActionReset => ({
  type: ActionTypes.PRODUCT_DETAILS_RESET,
});
