import { z } from 'zod';

import { employeeValidator } from './employee-validator';

export const userValidator = z
  .object({
    created_at: z.string(),
    display_name: z.string(),
    email: z.string().email(),
    personal_email: z.string().email().nullish(),
    hasAsset: z.boolean().nullable(),
    id: z.number(),
    last_name: z.string(),
    name: z.string(),
    phone_number: z.string().nullable(),
    personal_phone_number: z.string().nullish(),
    read_privacy: z.number().nullable(),
    role: z.object({
      id: z.number(),
      name: z.string(),
      display_name: z.string(),
    }),
    original_role: z.object({
      id: z.number(),
      name: z.string(),
      display_name: z.string(),
    }),
    status: z.string().nullable(),
    updated_at: z.string(),
    employee: employeeValidator.nullish(),
  })
  .passthrough();
