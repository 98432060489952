import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeDeleteAction,
  EmployeeDeleteActionFail,
  EmployeeDeleteActionRequest,
  EmployeeDeleteActionReset,
  EmployeeDeleteActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios, { ErrorResponse } from 'src/utils/axios';

export const deleteEmployee =
  (
    employeeId: string | number,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployeeDeleteAction> =>
  async dispatch => {
    dispatch<EmployeeDeleteActionRequest>({
      type: ActionTypes.EMPLOYEE_DELETE_REQUEST,
    });

    try {
      await axios.delete(`${apiConfig.url}/employees/${employeeId}`);

      dispatch<EmployeeDeleteActionSuccess>({
        type: ActionTypes.EMPLOYEE_DELETE_SUCCESS,
      });

      enqueueSnackbar('Employee Successfully deleted', {
        variant: 'success',
      });
    } catch (e) {
      if (e instanceof ErrorResponse) {
        enqueueSnackbar(e.response?.data.errors?.[0]?.errors[0] ?? '', {
          variant: 'error',
        });
      }

      dispatch<EmployeeDeleteActionFail>({
        type: ActionTypes.EMPLOYEE_DELETE_FAIL,
      });
    }
  };

export const deleteEmployeeReset = (): EmployeeDeleteActionReset => ({
  type: ActionTypes.EMPLOYEE_DELETE_RESET,
});
