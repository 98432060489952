import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  AccountTypeAction,
  AccountTypeActionFail,
  AccountTypeActionRequest,
  AccountTypeActionReset,
  AccountTypeActionSuccess,
} from 'src/actions/account-types-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { AccountTypes } from 'src/types/accountTypes';
import { Response } from 'src/types/response';
import axios from 'src/utils/axios';

export const getAccountTypes =
  (): ThunkAction<Promise<void>, RootState, undefined, AccountTypeAction> =>
  async dispatch => {
    dispatch<AccountTypeActionRequest>({
      type: ActionTypes.ACCOUNT_TYPE_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<typeof AccountTypes>>(
        `${apiConfig.url}/account-types`,
      );

      const { data } = res.data;

      dispatch<AccountTypeActionSuccess>({
        type: ActionTypes.ACCOUNT_TYPE_LIST_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<AccountTypeActionFail>({
        type: ActionTypes.ACCOUNT_TYPE_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getAccountTypesRest = (): AccountTypeActionReset => ({
  type: ActionTypes.ACCOUNT_TYPE_LIST_RESET,
});
