import { ThunkAction } from 'redux-thunk';

import {
  CURRENCY_FAIL,
  CURRENCY_REQUEST,
  CURRENCY_RESET,
  CURRENCY_SUCCESS,
} from 'src/action-types';
import {
  CurrencyAction,
  CurrencyActionFail,
  CurrencyActionRequest,
  CurrencyActionReset,
  CurrencyActionSuccess,
  CurrencyType,
} from 'src/actions/currency-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import axios from 'src/utils/axios';

export const getCurrencies =
  (): ThunkAction<Promise<void>, RootState, undefined, CurrencyAction> =>
  async dispatch => {
    dispatch<CurrencyActionRequest>({
      type: CURRENCY_REQUEST,
    });

    try {
      const res = await axios.get<{ data: CurrencyType[] }>(
        `${apiConfig.url}/currencies`,
      );

      const { data } = res.data;

      dispatch<CurrencyActionSuccess>({
        type: CURRENCY_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<CurrencyActionFail>({
        type: CURRENCY_FAIL,
        payload: 'Server Error',
      });
    }
  };

export const getCurrenciesReset = (): CurrencyActionReset => ({
  type: CURRENCY_RESET,
});
