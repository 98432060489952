import { SVGProps } from 'react';

const UserIcon = ({
  height = '15',
  width = '15',
  color = 'currentColor',
  style = {},
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill="none"
    style={style}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.05403 3.65389C9.06006 4.04559 8.98812 4.43459 8.84239 4.79822C8.69667 5.16186 8.48006 5.49288 8.20519 5.77202C7.93032 6.05115 7.60268 6.27282 7.24133 6.42413C6.87997 6.57544 6.49214 6.65336 6.10038 6.65336C5.70863 6.65336 5.32079 6.57544 4.95944 6.42413C4.59809 6.27282 4.27045 6.05115 3.99558 5.77202C3.72071 5.49288 3.5041 5.16186 3.35838 4.79822C3.21265 4.43459 3.1407 4.04559 3.14673 3.65389C3.15867 2.87841 3.47511 2.13873 4.02773 1.59455C4.58035 1.05038 5.32481 0.745361 6.10038 0.745361C6.87596 0.745361 7.62042 1.05038 8.17304 1.59455C8.72566 2.13873 9.0421 2.87841 9.05403 3.65389ZM0.868935 10.1282C1.83843 9.36519 3.76763 8.13109 6.10073 8.13109C8.43313 8.13109 10.363 9.36589 11.3325 10.1282C11.7651 10.4684 11.8995 11.0438 11.7294 11.5674L11.4963 12.2814C11.3996 12.5776 11.2117 12.8356 10.9595 13.0185C10.7073 13.2014 10.4037 13.2999 10.0921 13.2999H2.10863C1.79719 13.2998 1.49375 13.2012 1.24168 13.0183C0.989611 12.8354 0.801816 12.5775 0.705135 12.2814L0.472035 11.5674C0.300535 11.0438 0.435635 10.4684 0.868935 10.1282V10.1282Z"
      fill={color}
    />
  </svg>
);

export default UserIcon;
