export const EMPLOYEE_DETAILS_REQUEST = 'EMPLOYEE_DETAILS_REQUEST';
export const EMPLOYEE_DETAILS_SUCCESS = 'EMPLOYEE_DETAILS_SUCCESS';
export const EMPLOYEE_DETAILS_FAIL = 'EMPLOYEE_DETAILS_FAIL';
export const EMPLOYEE_DETAILS_RESET = 'EMPLOYEE_DETAILS_RESET';

export const EMPLOYEE_ORDER_LIST_REQUEST = 'EMPLOYEE_ORDER_LIST_REQUEST';
export const EMPLOYEE_ORDER_LIST_SUCCESS = 'EMPLOYEE_ORDER_LIST_SUCCESS';
export const EMPLOYEE_ORDER_LIST_FAIL = 'EMPLOYEE_ORDER_LIST_FAIL';
export const EMPLOYEE_ORDER_LIST_RESET = 'EMPLOYEE_ORDER_LIST_RESET';

export const EMPLOYEE_ASSET_LIST_REQUEST = 'EMPLOYEE_ASSET_LIST_REQUEST';
export const EMPLOYEE_ASSET_LIST_SUCCESS = 'EMPLOYEE_ASSET_LIST_SUCCESS';
export const EMPLOYEE_ASSET_LIST_FAIL = 'EMPLOYEE_ASSET_LIST_FAIL';
export const EMPLOYEE_ASSET_LIST_RESET = 'EMPLOYEE_ASSET_LIST_RESET';

export const EMPLOYEE_DELETE_REQUEST = 'EMPLOYEE_DELETE_REQUEST';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_FAIL = 'EMPLOYEE_DELETE_FAIL';
export const EMPLOYEE_DELETE_RESET = 'EMPLOYEE_DELETE_RESET';

export const EMPLOYEE_OFFBOARD_ASSET_LIST_REQUEST =
  'EMPLOYEE_OFFBOARD_ASSET_LIST_REQUEST';
export const EMPLOYEE_OFFBOARD_ASSET_LIST_SUCCESS =
  'EMPLOYEE_OFFBOARD_ASSET_LIST_SUCCESS';
export const EMPLOYEE_OFFBOARD_ASSET_LIST_FAIL =
  'EMPLOYEE_OFFBOARD_ASSET_LIST_FAIL';
export const EMPLOYEE_OFFBOARD_ASSET_LIST_RESET =
  'EMPLOYEE_OFFBOARD_ASSET_LIST_RESET';

export const EMPLOYEE_BULK_UPLOAD_REQUEST = 'EMPLOYEE_BULK_UPLOAD_REQUEST';
export const EMPLOYEE_BULK_UPLOAD_SUCCESS = 'EMPLOYEE_BULK_UPLOAD_SUCCESS';
export const EMPLOYEE_BULK_UPLOAD_FAIL = 'EMPLOYEE_BULK_UPLOAD_FAIL';
export const EMPLOYEE_BULK_UPLOAD_RESET = 'EMPLOYEE_BULK_UPLOAD_RESET';

export const EMPLOYEE_CREATE_REQUEST = 'EMPLOYEE_CREATE_REQUEST';
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS';
export const EMPLOYEE_CREATE_FAIL = 'EMPLOYEE_CREATE_FAIL';
export const EMPLOYEE_CREATE_RESET = 'EMPLOYEE_CREATE_RESET';

export const EMPLOYEE_UPDATE_REQUEST = 'EMPLOYEE_UPDATE_REQUEST';
export const EMPLOYEE_UPDATE_SUCCESS = 'EMPLOYEE_UPDATE_SUCCESS';
export const EMPLOYEE_UPDATE_FAIL = 'EMPLOYEE_UPDATE_FAIL';
export const EMPLOYEE_UPDATE_RESET = 'EMPLOYEE_UPDATE_RESET';

export const EMPLOYEE_LIST_INFINITE_REQUEST = 'EMPLOYEE_LIST_INFINITE_REQUEST';
export const EMPLOYEE_LIST_INFINITE_SUCCESS = 'EMPLOYEE_LIST_INFINITE_SUCCESS';
export const EMPLOYEE_LIST_INFINITE_FAIL = 'EMPLOYEE_LIST_INFINITE_FAIL';
export const EMPLOYEE_LIST_INFINITE_RESET = 'EMPLOYEE_LIST_INFINITE_RESET';

export const EMPLOYEE_LIST_REQUEST = 'EMPLOYEE_LIST_REQUEST';
export const EMPLOYEE_LIST_SUCCESS = 'EMPLOYEE_LIST_SUCCESS';
export const EMPLOYEE_LIST_FAIL = 'EMPLOYEE_LIST_FAIL';
export const EMPLOYEE_LIST_RESET = 'EMPLOYEE_LIST_RESET';
