import { FC, useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { enqueueSnackbar } from 'notistack';

import axios from 'src/utils/axios';

const ErrorBoundary: FC = () => {
  const { logout } = useAuth0();

  const errorMessage = localStorage.getItem('user_fail') ?? '';

  useEffect(() => {
    if (errorMessage) enqueueSnackbar(errorMessage, { variant: 'error' });
  }, [errorMessage]);

  return (
    <div
      style={{
        width: '100%',
        height: '300px',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        padding: 0,
        margin: 0,
      }}
    >
      <div
        style={{
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontWeight: 500,
          lineHeight: '1.235',
          letterSpacing: '-0.06px',
        }}
      >
        <h4 style={{ color: '#227AF7' }}>
          It looks like Something Went Wrong.
        </h4>
        <p
          style={{
            fontSize: '0.875rem',
            letterSpacing: '0.00714em',
            color: '#546e7a',
          }}
        >
          {errorMessage
            ? errorMessage
            : 'Kindly clear your cache and reload your browser or you can redirect back to home page'}
        </p>
        <div style={{ marginTop: '50px' }}>
          <a
            href="/"
            style={{
              color: '#7057ff',
              border: '1px solid rgba(112, 87, 255, 0.5)',
              padding: '10px 15px',
              textTransform: 'uppercase',
              borderRadius: 4,
              lineHeight: '1.75',
              fontSize: '0.875rem',
              textDecoration: 'none',
              transition:
                'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            }}
            onClick={() => {
              if (errorMessage) {
                localStorage.removeItem('user_fail');
                localStorage.removeItem('accessToken');
                delete axios.defaults.headers.common.Authorization;
                logout({ logoutParams: { returnTo: window.location.origin } });
              } else {
                window.location.replace('/');
              }
            }}
          >
            {errorMessage ? 'Logout' : 'Back To Home'}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
