import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { DepartmentListAction } from 'src/actions';
import { Department, InitialState } from 'src/types';

interface DepartmentListState extends InitialState {
  departments: Department[];
}

const departmentListInitialState: DepartmentListState = {
  departments: [],
};

export const departmentListReducer: Reducer<
  DepartmentListState,
  DepartmentListAction
> = (state = departmentListInitialState, action) => {
  switch (action.type) {
    case ActionTypes.DEPARTMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DEPARTMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action.payload,
        success: true,
      };
    case ActionTypes.DEPARTMENT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.DEPARTMENT_LIST_RESET:
      return departmentListInitialState;
    default:
      return state;
  }
};
