import React, { FC } from 'react';

import { Typography } from '@material-ui/core';

import badgeStyles from 'src/components-for-storybook/molecules/Badge/badge.styles';

interface IBadge {
  pendingRequests: number;
}

const Badge: FC<IBadge> = ({ pendingRequests }) => {
  const classes = badgeStyles();

  return (
    <span className={classes.badge}>
      <Typography>{Number(pendingRequests)}</Typography>
    </span>
  );
};

export default Badge;
