import axiosInstance, { Canceler } from 'axios';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  EmployeeListAction,
  EmployeeListActionFail,
  EmployeeListActionRequest,
  EmployeeListActionReset,
  EmployeeListActionSuccess,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { Employee, Response } from 'src/types';
import axios from 'src/utils/axios';

import getEmployeeListAnalytics from './getEmployeeListAnalytics';

interface GetEmployeeListParams {
  expand?: string;
  limit?: number;
  page?: number;
  sort?: string;
  search?: string;
}

export const getEmployeeList =
  (
    params: GetEmployeeListParams,
    callback?: (cancel: Canceler) => void,
  ): ThunkAction<Promise<void>, RootState, undefined, EmployeeListAction> =>
  async dispatch => {
    dispatch<EmployeeListActionRequest>({
      type: ActionTypes.EMPLOYEE_LIST_REQUEST,
    });

    try {
      const res = await axios.get<Response<Employee[]>>(
        `${apiConfig.url}/employees`,
        {
          params: {
            include: params.expand,
            limit: params.limit ?? 10,
            page: params.page ?? 1,
            sort: params.sort ?? '-created_at',
            search: {
              query: params.search ?? '',
              fields: 'user.name|user.last_name|user.email',
            },
          },
          cancelToken: new axiosInstance.CancelToken(c => {
            callback?.(c);
          }),
        },
      );

      getEmployeeListAnalytics(params.search);

      const { data, meta } = res.data;

      dispatch<EmployeeListActionSuccess>({
        type: ActionTypes.EMPLOYEE_LIST_SUCCESS,
        payload: data,
        meta,
      });
    } catch (e) {
      if (axiosInstance.isCancel(e)) return;

      dispatch<EmployeeListActionFail>({
        type: ActionTypes.EMPLOYEE_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getEmployeeListReset = (): EmployeeListActionReset => ({
  type: ActionTypes.EMPLOYEE_LIST_RESET,
});
