import { enqueueSnackbar } from 'notistack';
import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GenerateTokenCreateAction,
  GenerateTokenCreateActionFail,
  GenerateTokenCreateActionRequest,
  GenerateTokenCreateActionReset,
  GenerateTokenCreateActionSuccess,
  GenerateTokenDeleteAction,
  GenerateTokenDeleteActionRequest,
  GenerateTokenDeleteActionReset,
  GenerateTokenDeleteActionSuccess,
  GenerateTokenListAction,
  GenerateTokenListActionFail,
  GenerateTokenListActionRequest,
  GenerateTokenListActionReset,
  GenerateTokenListActionSuccess,
} from 'src/actions/generate-token-action';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { GenerateToken, GenerateTokenSuccessResponse } from 'src/types';
import axios from 'src/utils/axios';

export const getGenerateTokens =
  (): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GenerateTokenListAction
  > =>
  async dispatch => {
    dispatch<GenerateTokenListActionRequest>({
      type: ActionTypes.GENERATE_TOKEN_LIST_REQUEST,
    });

    try {
      const res = await axios.get<GenerateToken[]>(
        `${apiConfig.url}/oauth/personal-access-tokens`,
      );

      const { data } = res;

      dispatch<GenerateTokenListActionSuccess>({
        type: ActionTypes.GENERATE_TOKEN_LIST_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<GenerateTokenListActionFail>({
        type: ActionTypes.GENERATE_TOKEN_LIST_FAIL,
        payload: 'Unable to fetch the data',
      });
    }
  };

export const getGenerateTokensReset = (): GenerateTokenListActionReset => ({
  type: ActionTypes.GENERATE_TOKEN_LIST_RESET,
});

export const createGenerateToken =
  ({
    name,
  }: {
    name: string;
  }): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GenerateTokenCreateAction
  > =>
  async dispatch => {
    dispatch<GenerateTokenCreateActionRequest>({
      type: ActionTypes.GENERATE_TOKEN_CREATE_REQUEST,
    });

    try {
      const res = await axios.post<GenerateTokenSuccessResponse>(
        `${apiConfig.url}/oauth/personal-access-tokens`,
        { name },
      );

      const { data } = res;

      dispatch<GenerateTokenCreateActionSuccess>({
        type: ActionTypes.GENERATE_TOKEN_CREATE_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch<GenerateTokenCreateActionFail>({
        type: ActionTypes.GENERATE_TOKEN_CREATE_FAIL,
        payload: 'Unable to create token',
      });

      enqueueSnackbar('Unable to create token', {
        variant: 'error',
      });
    }
  };

export const createGenerateTokenReset = (): GenerateTokenCreateActionReset => ({
  type: ActionTypes.GENERATE_TOKEN_CREATE_RESET,
});

export const deleteGenerateToken =
  (
    id: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GenerateTokenDeleteAction
  > =>
  async dispatch => {
    dispatch<GenerateTokenDeleteActionRequest>({
      type: ActionTypes.GENERATE_TOKEN_DELETE_REQUEST,
    });

    try {
      await axios.delete(`${apiConfig.url}/oauth/personal-access-tokens/${id}`);

      dispatch<GenerateTokenDeleteActionSuccess>({
        type: ActionTypes.GENERATE_TOKEN_DELETE_SUCCESS,
        payload: id,
      });

      enqueueSnackbar('Token successfully deleted', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Unable to delete the token', {
        variant: 'error',
      });
    }
  };

export const deleteGenerateTokenReset = (): GenerateTokenDeleteActionReset => ({
  type: ActionTypes.GENERATE_TOKEN_DELETE_RESET,
});
