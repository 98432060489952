import { ThunkAction } from 'redux-thunk';

import * as ActionTypes from 'src/action-types';
import {
  GetDashboardTotalSpentActionList,
  GetDashboardTotalSpentActionFail,
  GetDashboardTotalSpentActionRequest,
  GetDashboardTotalSpentActionSuccess,
  GetDashboardTotalSpentActionReset,
} from 'src/actions';
import { apiConfig } from 'src/config';
import { RootState } from 'src/store';
import { DashboardTotalSpent } from 'src/types';
import axios from 'src/utils/axios';

export const getDashboardTotalSpent =
  (
    start: string,
    end: string,
  ): ThunkAction<
    Promise<void>,
    RootState,
    undefined,
    GetDashboardTotalSpentActionList
  > =>
  async dispatch => {
    dispatch<GetDashboardTotalSpentActionRequest>({
      type: ActionTypes.GET_TOTAL_SPENT_REQUEST,
    });

    try {
      const res = await axios.get<{ data: DashboardTotalSpent }>(
        `${apiConfig.url}/total-spent`,
        { params: { start, end } },
      );

      const { data } = res.data;

      dispatch<GetDashboardTotalSpentActionSuccess>({
        type: ActionTypes.GET_TOTAL_SPENT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch<GetDashboardTotalSpentActionFail>({
        type: ActionTypes.GET_TOTAL_SPENT_FAIL,
        payload: 'Something Went Wrong while Fetching Total Spent',
      });
    }
  };

export const getDashboardTotalSpentReset =
  (): GetDashboardTotalSpentActionReset => ({
    type: ActionTypes.GET_TOTAL_SPENT_RESET,
  });
