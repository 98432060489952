import { SVGProps } from 'react';

const CrossIcon = ({
  height = '16',
  width = '16',
  color = '#227AF7',
  style = {},
}: SVGProps<SVGSVGElement>) => {
  return (
    // width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;
    <svg
      className="svg-icon"
      style={Object.assign({}, style, {
        width,
        height,
        verticalAlign: 'middle',
        fill: color,
        overflow: 'hidden',
      })}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M960 170.56L869.44 80 512 437.44 154.56 80 64 170.56 421.44 528 64 885.44l90.56 90.56L512 618.56 869.44 976 960 885.44 602.56 528 960 170.56z" />
    </svg>
  );
};

export default CrossIcon;
