import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { ProductImageDeleteAction } from 'src/actions/product-image-action';
import { InitialState } from 'src/types';

type ProdcutImageDeleteState = InitialState;

export const productImageDeleteReducer: Reducer<
  ProdcutImageDeleteState,
  ProductImageDeleteAction
> = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PRODUCT_IMAGE_DELETE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.PRODUCT_IMAGE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case ActionTypes.PRODUCT_IMAGE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.PRODUCT_IMAGE_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
