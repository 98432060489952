import { Reducer } from 'redux';

import * as ActionTypes from 'src/action-types';
import { SettingsListAction } from 'src/actions';
import { Settings } from 'src/types';

interface SettingsInitialState {
  loading?: boolean;
  success?: boolean;
  error?: string;
}

interface SettingsListState extends SettingsInitialState {
  settings: Settings[];
}

const settingsListInitialState: SettingsListState = {
  settings: [],
};

export const settingsListReducer: Reducer<
  SettingsListState,
  SettingsListAction
> = (state = settingsListInitialState, action): SettingsListState => {
  switch (action.type) {
    case ActionTypes.SETTINGS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.SETTINGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        settings: action.payload,
      };
    case ActionTypes.SETTINGS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case ActionTypes.SETTINGS_LIST_RESET:
      return settingsListInitialState;
    default:
      return state;
  }
};
