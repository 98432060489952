import { z } from 'zod';

import { departmentValidator } from 'src/modules/department/validators/departmentValidator';
import { addressValidator } from 'src/utils/validators/address-validator';
import { currencyValidator } from 'src/utils/validators/currency-validator';
import { employerValidator } from 'src/utils/validators/employer-validator';

export const employeeRegistrationStatusEnum = z.enum([
  'Sent',
  'Accepted',
  'Expired',
  'Uninvited',
]);
export const employeeStatus = z.enum([
  'added',
  'first_log_in',
  'accepted_welcome_message',
]);

export const employeeUpsertValidator = z.object({
  id: z.number().optional(),
  department_id: z.union([z.string(), z.number()]),
  employment_start_date: z.string().nullable(),
  employment_end_date: z.string().nullable(),
  is_notied: z.boolean().nullish(),
  address: addressValidator.nullish(),
  user: z
    .object({
      email: z.string().email(),
      personal_email: z.string().email().nullish(),
      last_name: z.string(),
      name: z.string(),
      phone_number: z.string().nullish(),
      personal_phone_number: z.string().nullish(),
    })
    .nullish(),
});

export const employeeValidator = z.object({
  id: z.number(),
  available_rent_budget: z.union([z.number(), z.string()]),
  available_rent_budget_formatted: z.string(),
  total_rent_budget: z.number(),
  total_rent_budget_formatted: z.string(),
  negative_available_rent_budget: z.union([z.number(), z.string()]),
  available_purchase_budget: z.union([z.number(), z.string()]),
  available_purchase_budget_formatted: z.string(),
  total_purchase_budget: z.number(),
  total_purchase_budget_formatted: z.string(),
  negative_available_purchase_budget: z.union([z.number(), z.string()]),
  department_id: z.union([z.string(), z.number()]),
  currency: currencyValidator,
  invitation_accepted: z.boolean(),
  status: employeeStatus,
  employment_start_date: z.string().nullable(),
  employment_end_date: z.string().nullable(),
  // offboards: offboardValidator.array().nullable(), // TODO offboards validator
  // cannot use user validator here because then it would be circular dependency
  user: z
    .object({
      created_at: z.string(),
      display_name: z.string(),
      email: z.string().email(),
      personal_email: z.string().email().nullish(),
      hasAsset: z.boolean().nullable(),
      id: z.number(),
      last_name: z.string(),
      name: z.string(),
      phone_number: z.string().nullable(),
      personal_phone_number: z.string().nullish(),
      read_privacy: z.number(),
      is_deactivated: z.boolean(),
      invite: z.any(), // TODO: fix this
      role: z
        .object({
          id: z.number(),
          name: z.string(),
          display_name: z.string(),
        })
        .nullish(),
      status: z.string().nullable(),
      updated_at: z.string(),
    })
    .optional(),
  address: addressValidator.nullable(),
  department: departmentValidator.nullable(),
  manual_sync: z.boolean().nullish(),
  tax_identification_number: z.string().nullable(),
  employer: employerValidator.optional(),
});

export type Employee = z.infer<typeof employeeValidator>;
